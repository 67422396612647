<template>
    <div class='contact-page'>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 ">
                        <div class="content-section">

                        <div class="logo">
                            <router-link to="/">
                                <img src="/images/logo.png" alt="" />
                            </router-link>
                        </div>
                        <p>Kabir Collections</p>
                        <p>
                            B-1/8, Krishna Nagar, Delhi-51 (India)
                        </p>
                        <a href="#">support@kabircollections.com</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
export default {

}
</script>
   
   
   
   