<template>
  <div class="home-exclusive">
   
    <div class="container">
      <div class="head">
        <h2>EXCLUSIVE DEALS & DISCOUNTS</h2>
      </div>
<!-- {{this.$store.state.getwishlist}} -->
      <div class="exclusive-sec" v-if="dealsproduct.length > 0">
        <carousel
          v-bind:items="5"
          v-bind:autoplay="true"
          v-bind:nav="true"
          v-bind:loop="false"
          v-autoplayHoverPause="true"
          v-bind:navText="true"
          v-bind:responsive="{
            0: { items: 2, nav: false, dots:false, margin: 10 },
            600: { items: 3, nav: true, dots:false, margin: 10 },
            1080: { items: 5, nav: true, dots:false, margin: 10 , autoplayHoverPause:true },
          }"
        >
         <!-- v-bind:navText="true" -->
       
      
          <div
            class="ex-block"
            v-for="(product, key) in dealsproduct"
            :key="key" 
          >
           <div class="product-thumbnil">
            
              <div class="pro-type">
                <span class="tag" v-if="product.image_tost_id == 1">New Arrival</span>
                <span class="tag" v-if="product.image_tost_id == 2">Sale</span>
              </div>
              <div class="product-items">
                <a href="javascript:void(0)"
                      @click="add_wishlist(product, user_info.id)"
                      class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w,index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == product.id + user_info.id"
                      ></i
                      >
                    </span>
                     <span
                        v-if="isLoading == product.id + user_info.id"
                        v-show="isLoading"
                        ><i class="fa fa-refresh fa-spin"></i
                      ></span>
                      <span class="nasa-tip-content">Wishlist</span>
                      <i
                        class="nasa-icon icon-nasa-like bg-warning"
                        v-if="product.is_wishlist == '1'"
                      ></i
                      ><i class="nasa-icon icon-nasa-like" v-else></i>
                    </a>

                 

                 
                <!-- <a href="#" class="quick-btn nasa-tip nasa-tip-left">
                  <span class="nasa-tip-content">Quick View</span>
                  <i class="nasa-icon pe-7s-look"></i
                ></a>
                <a href="#" class="compare-btn nasa-tip nasa-tip-left">
                  <span class="nasa-tip-content">Compare</span>
                  <i class="pe-7s-repeat"></i
                ></a> -->
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#cart-mpdel"
                  class="crt-btn"
                >
                  <span @click="addToCartlog(product)" v-if="user_info"
                    >Add to Cart</span
                  >
                  <span @click="addToCart(product)" v-else>Add to Cart</span>
                  <i class="mdi mdi-plus"></i
                ></a>
              </div>
               
              <div class="img-section" @click="productdetail(product.slug)">
                <img
                  class="fst-img"
                  :src="
                    $baseURL +
                    '/public/images/product/original/' +
                    product.product_image[0].product_image
                  "
                  alt=""
                />
                <img
                  v-if="product.product_image[1]"
                  class="sec-img"
                  :src="
                    $baseURL +
                    '/public/images/product/original/' +
                    product.product_image[1].product_image
                  "
                  alt=""
                />
              </div>
              <div class="product-info">
                <a href="javascript:void(0)" class="pro-name" @click="productdetail(product.slug)">{{ product.name }}</a>
                <div class="star-rating">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star-o" aria-hidden="true"></i>
                </div>

                <div class="price-wrap">
                  ₹ <span :id="'actual_price' + product.id " class="cut-price">{{ product.actual_price }}</span
                  > ₹ <span :id="'offer_price'+ product.id " class="price">{{ product.offer_price }}</span>
                  <span hidden="hidden" :id="'offer_hidden_price' + product.id">{{ product.offer_price }}</span>
                </div>
                <!-- <div class="size">
                   <span>Size </span>
                   
                  <span
                    v-for="sizes in product.product_size"
                    :key="sizes.size_id"
                  >
                    <a
                      :id="'sizename' + sizes.size_id"
                      :class="{ active: active_el == sizes.size_id }"
                      @click="selectproductsize(sizes.size_id)"
                      href="javascript::void(0)"
                      >{{ sizes.size_name }}</a>
                  </span>
                </div> -->
                  <div class="size">
                      <!-- <span>Size</span> -->
                      <span
                        v-for="(sizes,index) in product.variable_list"
                        :key="index" >
                        <a :class="['mysized'+product.id,{ 'active' : index === 0 }]"
                        :id="'sizename'+product.id+','+sizes.size_id"
                        @click="selectproductsize(product.id,sizes.size_id,sizes.actual_price, sizes.offer_price)"
                        href="javascript:void(0)">{{ sizes.size_name }}</a>
                     </span>
                  </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import wishlistMixin from "../mixins/wishlistMixin.js";

Vue.use(VueAxios, axios);
export default {
  mixins: [wishlistMixin],
  components: { carousel },
  data() {
    return {
      
      active_el: 2,
      sizeName: "M",
      dealsproduct: "",
      user_info: "",
    };
  },
  methods: {
    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
    },
    addToCart(product) {
        var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
        var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
        var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;
        let item =product.variable_list.find(i =>i.size_id == size_id)

          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }

      
      if (product == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
      } else {
        const data = {
          sizeName: size_name,
          size_id:size_id,
          product_id: product.id,
          product_name: product.name,
          slug: product.slug,
          offer_price: product.offer_price,
          product_sizes: product.variable_list,
          product_image: product.product_image[0].product_image,
        };
         
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() {},
        });
      }
    },
    addToCartlog(product) {
      var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
      var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
      let item =product.variable_list.find(i =>i.size_id == size_id)

          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }
      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        //  size_id: product.product_size[0].size_id,
         size_id: size_id,
         qty: "1",
      };
     
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    // selectproductsize(sizeID) {
     
    //   this.sizeName = document.getElementById("sizename" + sizeID).innerHTML;
    //   this.active_el = sizeID;
      
    // },
  selectproductsize(product_id,sizeID,actual, offer) {
    var selectedSize = document.getElementsByClassName('mysized'+product_id+' active');//mysized"+product_id
  if(selectedSize !=null){
   [].forEach.call(selectedSize, function(elems) { 
        elems.classList.remove("active");
       
    });
  }
  var element = document.getElementById("sizename"+product_id+','+sizeID);
  if(element!==null){
  element.classList.add("active");
  document.getElementById('actual_price'+product_id).innerHTML = actual;
  document.getElementById("offer_price"+product_id).innerHTML = offer;
  document.getElementById("offer_hidden_price"+product_id).innerHTML = offer;
  }
 
    },
  },

  created() {
    var token = localStorage.getItem("token");
    axios
    .get("/api/exclusive-products-and-deals", {
      // .get("/api/category-products/" + "women", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        console.log('response', res.data);
        this.dealsproduct = res.data.data;
        this.length = 5;
         
      })
      .catch((err) => {
        console.log(err.response);
      });

    
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  computed: {

    // getwishlist() {
    //   let id = 0;
    //   for (let item of this.$store.state.getwishlist) {
    //     id = item;
    //     return id;
    //   }
    // },
    //  getitemssubtotalp() {
    //   let total =[];
    //   for (let item of this.$store.state.getwishlist) {
    //     total += JSON.stringify(item);
        
       
    //   }
    //   return total; 
    // },
  

  },
  };
</script>

<style >
.home-exclusive .exclusive-sec .owl-carousel .owl-nav {position: absolute; width: 100%;
    top: -71px;}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-prev::after {
  content: "\F0141";
  font-family: "Material Design Icons";
}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-next::after {
  content: "\F0142";
  font-family: "Material Design Icons";
}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-prev {
    float: left;
    background: none;
    border: #e4e4e4 1px solid;
    border-radius: 50px;
    color: #c0c0c0;
    font-size: 23px;
    width: 40px;
    height: 40px;
    line-height: 33px;
}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-next {
right: 0;
    position: absolute;
    left: inherit;
       background: none;
    border: #e4e4e4 1px solid;
    border-radius: 50px;
    color: #c0c0c0;
    font-size: 23px;
    width: 40px;
    height: 40px;
    line-height: 33px;
}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-next:hover, .home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-prev:hover {
    right: 0;
    border:#333 1px solid; 
    color: #333;
    
}
</style>