<template>
            <div class="home-service-section">
            <div class="container">
                <div class="row g-0">
                    <div class="col-lg col-6">
                    <div class="service-box">
                   
                    <div class="box-icon">
                        <i class="hvr-buzz-out pe-7s-plane"></i>
                  
                    </div>
                    <div class="box-text">
                        <h3>Free Shipping</h3>
                        <p>Free Shipping for all US order</p>
                    </div>
                    </div>
                    </div>
                    <div class="col-lg col-6">
                    <div class="service-box">
                   
                    <div class="box-icon">
                        <i class="hvr-buzz-out pe-7s-headphones"></i>
                  
                    </div>
                    <div class="box-text">
                        <h3>Support 24/7</h3>
                        <p>Always Reachable</p>
                    </div>
                    </div>
                    </div>
                    <div class="col-lg col-6">
                    <div class="service-box">
                   
                    <div class="box-icon">
                        <i class="hvr-buzz-out pe-7s-refresh-2"></i>
                  
                    </div>
                    <div class="box-text">
                        <h3>Money Back</h3>
                        <p>7 Days Return Window</p>
                    </div>
                    </div>
                    </div>
                    <div class="col-lg col-6">
                    <div class="service-box">
                   
                    <div class="box-icon">
                        <i class="hvr-buzz-out pe-7s-gift"></i>
                  
                    </div>
                    <div class="box-text">
                        <h3>Payment Secure</h3>
                        <p>We ensure secure payment</p>
                    </div>
                    </div>
                    </div>
                    <div class="col-lg">
                    <div class="service-box">
                   
                    <div class="box-icon">
                        <i class="hvr-buzz-out pe-7s-piggy"></i>
                  
                    </div>
                    <div class="box-text">
                        <h3>Discount</h3>
                        <p>More Shop More You Save</p>
                    </div>
                    </div>
                    </div>
                
                </div>
            </div>
        </div>
</template>

<script>
export default {
    
}
</script>