<template>
  <div class="myaccount-set">
    <div class="container">
      <div class="myaccount-set-in">
        <form @submit.prevent="handlePasswordVerify" v-if="isPassVerify" id="isPassVerify">
          <div class="row justify-content-center">
            <div class="col-lg-6 form-cover">
              <h3>OTP Verify</h3>
              <flash-message class="myCustomClass"></flash-message>
              <div class="col-md-12">
                <div class="form-group">
                  <span class="ico mdi mdi-key-variant"></span>
                  <input
                    required
                    type="text"
                    class="form-control"
                    placeholder="Email OTP"
                    v-model="otp"
                    minlength="6"
                  />
                </div>
              </div>

              <!-- <div class="col-md-12">
              <div class="form-group">
                <span class="ico mdi mdi-key-variant"></span>
                <input
                    required
                  type="text"
                  class="form-control"
                  placeholder="New Password"
                  v-model="newpassword"
                  minlength="6"
                />
              </div>
            </div> -->

              <div class="col-md-12">
                <div class="submit">
                  <button type="submit">Verify</button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <form @submit.prevent="handleForgetReset" v-if="isResetPass" id="isResetPass">
          <div class="row justify-content-center">
             
            <div class="col-lg-6 form-cover">
              <h3>Enter new password</h3>
              <flash-message class="myCustomClass"></flash-message>
              <div class="col-md-12">
                <div class="form-group">
                  <span class="ico mdi mdi-key-variant"></span>
                  <input
                    required
                    type="password"
                    class="form-control"
                    placeholder="New Password"
                    v-model="newpassword"
                    minlength="6"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="submit">
                  <button type="submit">Save</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      isPassVerify:true,
      isResetPass:false,
      email: "",
      otp: "",
      newpassword: "",
      message: "",
      error: "",
      user_id:"",
    };
  },
  methods: {
    async handleForgetReset() {
  

      if (!this.newpassword) {
        this.flash("Please Enter New Password", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }

      const userData = {     
        password: this.newpassword,
        user_id:this.user_id,
      };

      await axios
        .post("/api/updatepassword", userData)
        .then((res) => {
            
         console.log(res.data);
         if(res.data.status == 1){
          
           this.message = res.data.message;
             this.$fire({
              title: "Password updated",
              text: this.message,
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
            this.$router.push("/login");
         }else{
           this.message = res.data.message;
           
          this.flash(this.message, "error", {
            timeout: 5000,
            beforeDestroy() {},
          });
              
         }
        
        })
        .catch((err) => {
          this.isPassVerify= true;
           this.isResetPass= false;
          console.log(err.response);
        });
    },

    async handlePasswordVerify() {
      
      if (!this.otp) {
        this.flash("Please Enter otp", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }

      const userData = {
        otp: this.otp,
      };

      await axios
        .post("/api/forgetcodevalidate", userData)
        .then((res) => {
            
         console.log(res.data);
         if(res.data.status == 1){
           this.isPassVerify= false;
           this.isResetPass= true;
           this.user_id = res.data.user_id;
           this.message = res.data.message;
           console.log(this.message);
         }else{
           this.message = res.data.message;
           console.log(this.message);
           
          this.flash(this.message, "error", {
            timeout: 5000,
            beforeDestroy() {},
          });
              
         }
        
        })
        .catch((err) => {
          this.isPassVerify= true;
           this.isResetPass= false;
          console.log(err.response);
        });
    },
  },
};
</script>