<template>
  <div class="product-list-page">
    <div class="product-filter" v-if="products.length > 0">
      <div class="container">
        <div class="row g-0">
          <div class="col-lg-8 col-6">
            <div class="left-filter">
              <h3>Result</h3>
            </div>
          </div>

          <div class="col-lg-4 col-6">
            <div class="right-filter">
              <div class="row g-0 justify-content-end">
                <div class="col-lg-6">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    @change="onChange($event)"
                  >
                    <option selected>Default sorting</option>
                    <option value="l_to_h">Sort by : low to high</option>
                    <option value="h_to_l">Sort by : high to low</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-view">
      <div class="container" v-if="products.length > 0">
        <div class="product-view-in">
          <div class="filter-section">
            <!-- <div class="filter-tab">
              <div class="head">
                <h3>Brand</h3>
              </div>
            
              <label
                class="common-check"
                v-for="(brand, index) in filterdata.brands" :key="index ">{{ brand.name }}
                <small> ({{ brand.product_count }}) </small>
                <input type="checkbox" v-model="checked" :value="brand.name" />
                <span class="checkmark black"></span>
              </label>
              
              <span class="load-more" @click="loadMorebrandsdata()"  v-if="loadMorebrands==true"> More</span>
              <span class="load-more" @click="loadLessbrandsdata()"  v-if="loadLessbrands==true"> Less</span>
              
            </div> -->
            <div class="filter-tab">
              <div class="head">
                <h3>Colors</h3>
                <!-- {{colors}} -->
              </div>

              <label
                class="common-check"
                v-for="(color, index) in filterdata.colors"
                :key="index"
              >
                <span :class="'color-round' + ' ' +color.hex_code" :style="'background-color:'+color.hex_code"></span>
                {{ color.color_name }}
                <input
                  type="checkbox"
                  v-model="checked"
                  :value="color.color_name"
                />
                <span class="checkmark black"></span>
              </label>
              <span class="load-more" @click="loadMorecolorsdata()"  v-if="loadMoreColor==true">More</span>
              <span class="load-more" @click="loadLescolorsdata()"  v-if="loadLessColor==true">Less</span>
            </div>
            <div class="filter-tab">
              <div class="head">
                <h3>Size</h3>
                <!-- {{sizes}} -->
              </div>
              <label
                class="common-check"
                v-for="(size, index) in filterdata.sizes"
                :key="index"
                >{{ size.size_name }}
                <input
                  type="checkbox"
                  v-model="size_name_t"
                  :value="size.size_name"
                />
                <span class="checkmark blue"></span>
              </label>
              <span class="load-more" @click="loadMoresizesdata()"  v-if="loadMoreSizes==true">More</span>
              <span class="load-more" @click="loadLessizessdata()"  v-if="loadLessSizes==true">Less</span>
            </div>
            
          </div>

          <div class="product-grid">
            <ul class="grid-4">
              <!-- <li product in products :key="product.id"  > -->

              <li v-for="(product, index) in computedProducts" :key="index">
                <div class="product-thumbnil">
                  <div class="pro-type">
                    <span class="tag" v-if="product.image_tost_id == 1">New Arrival</span>
                    <span class="tag" v-if="product.image_tost_id == 2">Sale</span>
                  </div>

                  <div class="product-items">
                  <a
                      href="javascript:void(0)"
                      @click="add_wishlist(product, user_info.id)"
                      class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w,index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == product.id + user_info.id"
                      ></i
                      >
                    </span>
                     <span
                        v-if="isLoading == product.id + user_info.id"
                        v-show="isLoading"
                        ><i class="fa fa-refresh fa-spin"></i
                      ></span>
                      <span class="nasa-tip-content">Wishlist</span>
                      <i
                        class="nasa-icon icon-nasa-like bg-warning"
                        v-if="product.is_wishlist == '1'"
                      ></i
                      ><i class="nasa-icon icon-nasa-like" v-else></i>
                    </a>

                     

                    <a href="#" class="quick-btn nasa-tip nasa-tip-left">
                      <span class="nasa-tip-content">Quick View</span>
                      <i class="nasa-icon pe-7s-look"></i
                    ></a>
                    <a href="#" class="compare-btn nasa-tip nasa-tip-left">
                      <span class="nasa-tip-content">Compare</span>
                      <i class="pe-7s-repeat"></i
                    ></a>
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#cart-mpdel"
                      class="crt-btn"
                    >
                      <span @click="addToCartlog(product)" v-if="user_info"
                        >Add to Cart</span
                      >
                      <span @click="addToCart(product)" v-else
                        >Add to Cart</span
                      >
                      <i class="mdi mdi-plus"></i
                    ></a>
                  </div>
                  <div class="img-section" @click="productdetail(product.slug)">
                    <img
                      class="fst-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[0].product_image
                      "
                      alt=""
                    />
                    <img
                      v-if="product.product_image[1]"
                      class="sec-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[1].product_image
                      "
                      alt=""
                    />

                    <img
                      v-else
                      class="sec-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[0].product_image
                      "
                      alt=""
                    />
                  </div>

                  <div class="product-info">
                    <a href="#" class="pro-name">{{ product.name }}</a>
                    <div class="price-wrap">
                      <span class="cut-price">₹ {{ product.actual_price }}</span
                      ><span class="price">₹ {{ product.offer_price }}</span>
                    </div>
                     <div class="size">
                      <!-- <span>Size</span> -->
                      <span
                        v-for="(sizes,index) in product.product_size"
                        :key="index" class="size">
                        <a :class="['mysized'+product.id,{ 'active' : index === 0 }]"
                        :id="'sizename'+product.id+','+sizes.size_id"
                        @click="selectproductsize(product.id,sizes.size_id)"
                        href="javascript:void(0)">{{ sizes.size_name }}</a>
                        </span>
                        </div>
                     
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-5">
              <img src="/images/coming.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

<div class="mobile-filter">
      <ul>
        <li>
          <a href="#">
            <i class="nasa-icon pe-7s-home"></i>
            Shop
          </a>
        </li>
        <li>
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#model-left-filter"
          >
            <i class="nasa-icon pe-7s-filter"></i>
            Filters
          </a>
        </li>
        <li>
          <a
            href="#"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#model-left"
          >
            <i class="nasa-icon pe-7s-keypad"></i>
            Category
          </a>
        </li>
        <li>
          <!-- <a href="#">
            <i class="nasa-icon wishlist-icon icon-nasa-like"></i>
           
          </a> -->
            <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#wish-mpdel"
                    >
                      <i class="nasa-icon wishlist-icon icon-nasa-like"></i>

                      <span class="count">{{
                        $store.state.getwishlist.length
                      }}</span>
                       Wishlist
                    </a>
        </li>
      </ul>
    </div>

    <div class="mob-navigation">
      <div
        class="modal left fade"
        id="model-left-filter"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>

            <div class="modal-body">
              <h5 class="modal-title" id="exampleModalLabel">
                <i class="fa fa-bars"></i> Filter
              </h5>
               <div class="filter-tab">
              <div class="head">
                <h3>Brand</h3>
              </div>
           <!-- filterdata.brands -->
              <label
                class="common-check"
                v-for="(brand, index) in filterdata.brands" :key="index ">{{ brand.name }}
                <small> ({{ brand.product_count }}) </small>
                <input type="checkbox" v-model="checked" :value="brand.name" />
                <span class="checkmark black"></span>
              </label>
              
              <span class="load-more" @click="loadMorebrandsdata()"  v-if="loadMorebrands==true"> More</span>
              <span class="load-more" @click="loadLessbrandsdata()"  v-if="loadLessbrands==true"> Less</span>
              
            </div>
              <div class="filter-tab">
              <div class="head">
                <h3>Colors</h3>
                 
              </div>
 
              <label
                class="common-check"
                v-for="(color, index) in filterdata.colors"
                :key="index"
              >
                <span :class="'color-round' + ' ' +color.hex_code" :style="'background-color:'+color.hex_code"></span>
                {{ color.color_name }}
                <input
                  type="checkbox"
                  v-model="checked"
                  :value="color.color_name"
                />
                <span class="checkmark black"></span>
              </label>
              <span class="load-more" @click="loadMorecolorsdata()"  v-if="loadMoreColor==true">More</span>
              <span class="load-more" @click="loadLescolorsdata()"  v-if="loadLessColor==true">Less</span>
            </div>
            <div class="filter-tab">
              <div class="head">
                <h3>Size</h3>
                <!-- {{sizes}} -->
              </div>
              <label
                class="common-check"
                v-for="(size, index) in filterdata.sizes"
                :key="index"
                >{{ size.size_name }}
                <input
                  type="checkbox"
                  v-model="checked"
                  :value="size.size_name"
                />
                <span class="checkmark blue"></span>
              </label>
              <span class="load-more" @click="loadMoresizesdata()"  v-if="loadMoreSizes==true">More</span>
              <span class="load-more" @click="loadLessizessdata()"  v-if="loadLessSizes==true">Less</span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import wishlistMixin from "../mixins/wishlistMixin.js";

Vue.use(VueAxios, axios);
export default {
   mixins: [wishlistMixin],
  props: ["products","filterdata"],
  // props: {
  //   products: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      active_el: 2,
      sizeName: "M",
      brands: "",
      colors: "",
      sizes: "",
      range: "5000",
      selectedproduct: "All",
      checked: [],
      size_name_t:[],
      allProd: this.products,
      user_info: "",
      limitbrands:"3",
      limitcolor:"3",
      limitsizes:"3",
      loadMorebrands:true,
      loadMoreColor:true,
      loadMoreSizes:true,
      loadLessbrands:false,
      loadLessColor:false,
      loadLessSizes:false,
    };
  },

  methods: {
     
  loadMorebrandsdata() {
    this.loadMorebrands=false;
     this.loadLessbrands=true;
      if (this.limitbrands > this.brands.length) return;
          this.limitbrands =  this.brands.length;
    },
    loadLessbrandsdata(){
      this.loadMorebrands=true;
      this.loadLessbrands=false;
      this.limitbrands = "3";
      },

   loadMorecolorsdata() {
    this.loadMoreColor=false;
     this.loadLessColor=true;
      if (this.limitcolor > this.colors.length) return;
          this.limitcolor =  this.colors.length;
    },
    loadLescolorsdata(){
      this.loadMoreColor=true;
      this.loadLessColor=false;
      this.limitcolor = "3";
      },

   loadMoresizesdata() {
    this.loadMoreSizes=false;
     this.loadLessSizes=true;
      if (this.limitcolor > this.sizes.length) return;
          this.limitsizes =  this.sizes.length;
    },
  loadLessizessdata(){
      this.loadMoreSizes=true;
      this.loadLessSizes=false;
      this.limitsizes = "3";
    },


    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
    },
   addToCart(product) {
        var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
        var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
        var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;
        

        let item =product.product_size.find(i =>i.size_id == size_id)

 if(item.stock >= 1){
 //return true;
 }
 else {
    this.$fire({
              title:'Available Qty:'+item.stock,
              text: 'OUT OF STOCK',
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
           return false;
 }

        

      
      if (product == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
      } else {
        const data = {
          sizeName: size_name,
          size_id:size_id,
          product_id: product.id,
          product_name: product.name,
          slug: product.slug,
          offer_price: product.offer_price,
          product_sizes: product.product_size,
          product_image: product.product_image[0].product_image,
        };
         
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() {},
        });
      }
    },
      addToCartlog(product) {
      var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
      var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");

      let item =product.product_size.find(i =>i.size_id == size_id)

        if(item.stock >= 1){
        //return true;
        }
        else {
            this.$fire({
                      title:'Available Qty:'+item.stock,
                      text: 'OUT OF STOCK',
                      type: "error",
                      timer: 5000,
                    }).then((r) => {
                      console.log(r.value);
                    });
                  return false;
        }

      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        //  size_id: product.product_size[0].size_id,
         size_id: size_id,
         qty: "1",
      };
     
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    selectproductsize(product_id,sizeID) {
         var selectedSize = document.getElementsByClassName('mysized'+product_id+' active');//mysized"+product_id
  if(selectedSize !=null){
   [].forEach.call(selectedSize, function(elems) { 
        elems.classList.remove("active");
    });
  }
  var element = document.getElementById("sizename"+product_id+','+sizeID);
  if(element!==null){
  element.classList.add("active");
  }
 
    },
    // getAddr(user_id) {
    //   this.$store.commit("getusercartitem", user_id);
    // },

    onChange(event) {
      let p = event.target.value;
      let uri = window.location.href.split("/");
       console.log(uri["4"]);
        var token = localStorage.getItem("token");
       const data_url = {
          url: uri["4"],
          filter_value: p,
          
         };
         console.log(data_url);
         axios
        .post("/api/search-product-filter",data_url, { headers: { Authorization: "Bearer " + token },})
        .then((res) => {
          this.allProd = res.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
      },
  },

  computed: {
    computedProducts() {
      console.log(this.checked);

      // console.log(this.allProd[0].product_size[0].size_name);

      // console.log(this.allProd);
         if(this.size_name_t.length > 0){

         
       var list=[];
        this.allProd.forEach((products) => {
           products.product_size.filter((productsiz) => { 

                
                // console.log(this.size_name_t.find(d => d === productsiz.size_name));
                 if (this.size_name_t.find(d => d === productsiz.size_name)){

                   console.log(productsiz.size_name);
             console.log(this.size_name_t);

                     list.push(products);

                     }
             });
          }); 
     // return  this.allProd=list;
       
           return [...new Set(list)];
          //  return list;

           //return this.allProd;

     }

     else if (this.checked.length === 0) {
        return this.allProd;
      } else {
        return this.allProd.filter(
          (product) =>
            this.checked.indexOf(product.product_brand.name) !== -1 ||
            this.checked.indexOf(product.product_color.color_name) !== -1 ||
            this.checked.indexOf(product.product_size[0].size_name) !== -1 
            // ||
            // this.checked.indexOf(product.product_size["1"].size_name) !== -1
          // this.checked.indexOf(product.offer_price > 0 && product.offer_price < this.checked ? product:""   ) !== -1 !== -1

          // || this.checked.indexOf(product.product_size["2"].size_name) !== -1
          //  this.range.indexOf(product.offer_price > 0 && product.offer_price > this.range ? product:""   ) !== -1
          //  this.range.indexOf(product.offer_price > 0 && product.offer_price < this.range ? product:""  ) !== -1

          // this.range.indexOf(product.offer_price >  this.range ? product:""  ) !== -1

          // (product) => this.checked.indexOf(product.product_color.color_name) !== -1
        );
      }
    },
      brandsdata() {
      return this.brands.slice(0, this.limitbrands);
    },
     colorsdata() {
      return this.colors.slice(0, this.limitcolor);
    },
     sizesdata() {
      return this.sizes.slice(0, this.limitsizes);
    },
  },
  created() {
    axios
      .get("/api/brands/", {})
      .then((res) => {
        this.brands = res.data.data;
        console.log(this.brands);
      })
      .catch((err) => {
        console.log(err.response);
      });
    axios
      .get("api/sizes/", {})
      .then((res) => {
        this.sizes = res.data.data;
        //  console.log(this.sizes);
      })
      .catch((err) => {
        console.log(err.response);
      });
    axios
      .get("api/colors/", {})
      .then((res) => {
        this.colors = res.data.data;
        //  console.log(this.sizes);
      })
      .catch((err) => {
        console.log(err.response);
      });
    axios
      .get("api/sizes/", {})
      .then((res) => {
        this.sizes = res.data.data;
        //  console.log(this.sizes);
      })
      .catch((err) => {
        console.log(err.response);
      });

    var token = localStorage.getItem("token");
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
        // this.getAddr(this.user_info.id);
      })
      .catch((err) => {
        //  this.$router.push("/login");
        console.log(err.response);
      });
  },
};
</script>

<style>
.mobile-filter {
  position: fixed;
  z-index: 399;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  margin: 0;

  list-style: none;
  width: 100%;
  text-align: center;
}
.mobile-filter ul {
  background: #fff;
  margin: 0;
  padding: 16px 40px 10px 40px;
  list-style: none;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mobile-filter ul li {
  list-style: none;
  text-align: center;
}
.mobile-filter ul li a {
  color: #000;
  text-align: center;
  display: inline-block;
  color: #333;
  font-weight: bold;
  font-size: 13px;
  margin: 0;
}
.mobile-filter ul li a i {
  font-size: 150%;
  display: block;
  color: inherit;
  font-weight: inherit;
}
</style>