
<template>
  <div>
    <section class="taxi-booking">
      <div class="container">
        <div class="heading-res">
          <h2>
            <a href="javascript:history.go(-1)">Back</a> / Payment Details
          </h2>
        </div>
 <form method="Post" @submit="bookingForm">
        <div class="row align-items-start">
          <div class="col-md-9">
            <div class="car-booking-details">
              <section>
               
                  <div class="container">
                    <div class="personal-info">
                      <h2>Your Personal Information</h2>

                      <div class="row justify-content-center">
                        <div class="error" v-if="error.length">
                          <p v-for="e in error" v-bind:key="e.id">{{ e }}</p>
                        </div>

                        <div class="col-md-12">
                          <div class="row no-gutters">
                            <div class="col-md-4">
                              <div class="form-group">
                                  <label for="">Name</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Name"
                                  v-model="postData.cus_name"
                                  @keyup.delete="console.log(1)"
                                  @keypress="isLetter($event)"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Email</label>
                                <input
                                  required
                                  type="email"
                                  class="form-control"
                                  placeholder="Enter Email"
                                  v-model="postData.cus_email"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Contact No.</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Contact"
                                  v-model="postData.cus_mobile"
                                  @keyup.delete="console.log(1)"
                                  @keypress="isNumbers($event)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                          <button class="make-payment">Make Payment</button>
                        </div>
                      </div> -->
                    </div>
                    <div class="personal-info">
                      <h2>Pickup Address</h2>
                      <div class="row justify-content-center">
                        <div class="error" v-if="error.length">
                          <p v-for="e in error" v-bind:key="e.id">{{ e }}</p>
                        </div>

                        <div class="col-md-12">
                          <div class="row no-gutters">
                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">House No. & Floor</label>
                                <input                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="House No. & Floor"
                                  v-model="postData.pickup_locality"
                                  
                                />
                              </div>
                            </div>

                          <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Street/Block No.</label>
                                <input                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Street/Block No."
                                  v-model="postData.pickup_street"
                                 
                                />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Location </label>
                                <input                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Location "
                                  v-model="postData.pickup_location"
                                 
                                />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Landmark</label>
                                <input                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Landmark  "
                                  v-model="postData.pickup_landmark"
                                 
                                />
                              </div>
                            </div>
                            

                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">City</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter City"
                                  v-model="postData.pickup_city"
                                />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Pincode</label>
                                <input
                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Pincode"
                                  v-model="postData.pickup_pincode"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">State</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter State"
                                  v-model="postData.pickup_state"
                                 
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <!-- <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                          <button class="make-payment">Make Payment</button>
                        </div>
                      </div> -->
                    </div>
                    <div class="personal-info">
                      <h2>Destination Address</h2>

                      <div class="row justify-content-center">
                        <div class="error" v-if="error.length">
                          <p v-for="e in error" v-bind:key="e.id">{{ e }}</p>
                        </div>

                        <div class="col-md-12">
                          <div class="row no-gutters">                           

                            <!-- <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">House No. & Floor</label>
                                <input                                 
                                  type="text"
                                  class="form-control"
                                  placeholder="House No. & Floor"
                                  v-model="postData.drop_locality"
                                 
                                />
                              </div>
                            </div> -->

                          <!-- <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Street/Block No.</label>
                                <input
                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Street/Block No."
                                  v-model="postData.drop_street"
                                 
                                />
                              </div>
                            </div> -->
                             <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Location </label>
                                <input
                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Location "
                                  v-model="postData.drop_location"
                                 
                                />
                              </div>
                            </div>
                            <!-- <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Landmark</label>
                                <input
                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Landmark  "
                                  v-model="postData.drop_landmark"
                                 
                                />
                              </div>
                            </div> -->
                            
                            <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">City</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter City"
                                  v-model="postData.drop_city"
                                />
                              </div>
                            </div>
                            <!-- <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">Pincode</label>
                                <input
                                  
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Pincode"
                                  v-model="postData.drop_pincode"
                                />
                              </div>
                            </div> -->

                           <div class="col-md-4">
                              <div class="form-group">
                                   <label for="">State</label>
                                <input
                                  required
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter State"
                                  v-model="postData.drop_state"
                                
                                />
                              </div>
                            </div>

                            
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                          <button class="make-payment">Make Payment</button>
                        </div>
                      </div> -->
                    </div>
                   
                  </div>
              
              </section>
            </div>
          </div>

          <div class="col-md-3 sticky-set">
            <div class="payment-info" id="bar-fixed">
              <h2>Fare Details</h2>

              <ul>
                <li>
                  Car Category:
                  <span>{{ pck[0].group_name }} / {{ pck[0].car }}</span>
                </li>

                <li v-if="pck[0].sub_id != 18 && pck[0].sub_id != 19">
                  Trip Type:

                  <span> {{ trip }} Trip</span>
                </li>

                <li v-if="pck[0].sub_id != 19 && pck[0].per_day_charge != 0">
                  Daily Rental :

                  <span>
                    Rs {{ pck[0].per_day_charge }} upto
                    {{ pck[0].daily_kmlimit }} k.m</span
                  >
                </li>
                <li>
                  Booking for :

                  <span v-if="pck[0].sub_id == 17"
                    >{{ difference }} days or
                    {{
                      basefareKM(
                        difference,
                        pck[0].daily_kmlimit,
                        pck[0].kmlimit
                      )
                    }}
                    k.m</span
                  >
                  <span v-if="pck[0].sub_id == 18"
                    >{{ pck[0].hrslimit }} Hrs and
                    {{ pck[0].kmlimit }} Kms</span
                  >

                  <span v-if="pck[0].sub_id == 19"
                    >{{ person }} person X {{ pck[0].pkg_price }}
                  </span>
                </li>

                <li v-if="pck[0].sub_id == 17">
                  <!-- <p> Base Fare ({{basefareKM(difference,pck[0].daily_kmlimit,pck[0].kmlimit) }} x {{ pck[0].price_per_km }} /km) :</p> -->
                  Base Fare :
                  <span v-if="trip != 'Round'">
                    <i class="mdi mdi-currency-inr"></i>
                    {{ pck[0].act_price }} .00
                  </span>
                  <span v-else>
                    <i class="mdi mdi-currency-inr"></i>
                    {{
                      basefareTotal(
                        difference,
                        pck[0].daily_kmlimit,
                        pck[0].kmlimit,
                        pck[0].price_per_km
                      )
                    }}
                    .00
                  </span>
                </li>

                <li v-else>
                  Base Fare :

                  <span v-if="pck[0].sub_id == 18">
                    <i class="mdi mdi-currency-inr"></i>
                    {{ pck[0].act_price }}.00
                  </span>
                  <span v-if="pck[0].sub_id == 19">
                    <i class="mdi mdi-currency-inr"></i>
                    {{ pck[0].pkg_price }}.00
                  </span>
                </li>

                <li v-if="pck[0].sub_id == 18">
                  No. of Car :

                  <span> {{ postData.chooseCar }}</span>
                </li>
                <li v-if="pck[0].sub_id == 18">
                  No. of Days :

                  <span> {{ postData.chooseNoofDays }}</span>
                </li>

                <li v-if="pck[0].sub_id != 18 && pck[0].sub_id != 19">
                  Driver Charge({{ difference }}X {{ postData.chooseCar }} X
                  {{ pck[0].driver_charge }}) :

                  <span>
                    <i class="mdi mdi-currency-inr"></i>
                    {{
                      pck[0].driver_charge * difference * postData.chooseCar
                    }}</span
                  >
                </li>

                <!-- <li>
  
  
  
                GST(%):
  
  
  
                <span><i class="mdi mdi-currency-inr"></i> {{pck[0].gst}} %</span>
  
  
  
              </li> -->

                <li class="total-show">
                  <strong>Total Price:</strong>

                  <span v-if="pck[0].sub_id != 18 && pck[0].sub_id != 19"
                    ><i class="mdi mdi-currency-inr"></i>
                    {{
                      trip == "Round"
                        ? totalRoundPrice(
                            pck[0].daily_kmlimit,
                            pck[0].kmlimit,
                            difference,
                            pck[0].price_per_km,
                            pck[0].driver_charge,
                            postData.chooseCar
                          )
                        : totalOnewayPrice(
                            pck[0].act_price,
                            pck[0].driver_charge,
                            postData.chooseCar,
                            difference
                          )
                    }}.00 <br />

                    (inclusive GST)</span
                  >

                  <span v-else
                    ><i class="mdi mdi-currency-inr"></i>
                    {{
                      totalActualPrice(
                        pck[0].act_price,
                        pck[0].sub_id,
                        pck[0].pkg_price,
                        person,
                        postData.chooseCar
                      )
                    }}.00 <br />

                    (inclusive GST)</span
                  >
                </li>
              </ul>

              <div class="amount-section">
                <div class="row">
                  <div class="col-md-12">
                    <div class="promo">
                      <div class="form-group">
                        <label for="">Apply Code</label>

                        <div class="row no-gutters">
                          <input
                            type="text"
                            v-model="postData.offer_code"
                            class="form-control"
                          />

                          <button>Apply</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 on-pay">
                    <h3>Select Payment Mode</h3>

                    <div class="row no-gutters">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          checked
                          @click="postData.payMode = 'online'"
                          name="payMode"
                          id="radio1"
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Online
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          @click="postData.payMode = 'wallet'"
                          name="payMode"
                          id="radio1"
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Wallet
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="col-md-12 how-pay">
                    <h3>Would you like to pay</h3>

                    <div class="row no-gutters">
                      <div class="pay-type">
                        <input
                          @click="payFull()"
                          id="radio-1"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                          checked
                        />

                        <label for="radio-1" class="radio-custom-label"
                          >Full</label
                        >
                      </div>

                      <div class="pay-type">
                        <input
                          @click="payHalf()"
                          id="radio-2"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                        />

                        <label for="radio-2" class="radio-custom-label"
                          >Pay 20% Advance
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="col-md-12">
                    <div class="amount">
                      <span>Payable Amount</span>

                      <h4>
                        <i class="mdi mdi-currency-inr"></i>
                        {{ payabled_price }} .00
                      </h4>
                    </div>
                    <div class="proccess-button">
                      <button >Make Payment</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="car-booking-details">
              <!-- <div class="amount-section">
                <div class="row">
                  <div class="col-md-5">
                    <h3>Would you like to pay</h3>

                    <div class="row no-gutters">
                      <div class="pay-type">
                        <input
                          @click="payFull()"
                          id="radio-1"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                          checked
                        />

                        <label for="radio-1" class="radio-custom-label"
                          >Full</label
                        >
                      </div>

                      <div class="pay-type">
                        <input
                          @click="payHalf()"
                          id="radio-2"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                        />

                        <label for="radio-2" class="radio-custom-label"
                          >Pay 20% Advance
                        </label>
                      </div>
                    </div>
                   
                  </div>

                  <div class="col-md-4">
                    <div class="promo">
                      <div class="form-group">
                        <label for="">Apply Code</label>

                        <div class="row no-gutters">
                          <input
                            type="text"
                            v-model="postData.offer_code"
                            class="form-control"
                          />

                          <button>Apply</button>
                        </div>
                      </div>
                    </div>
                      
                  </div>

                  <div class="col-md-3">
                    <div class="amount">
                      <span>Payable Amount</span>

                      <h4>
                        <i class="mdi mdi-currency-inr"></i>
                        {{ payabled_price }} .00
                      </h4>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
          </form>
      </div>
    </section>
  </div>
</template>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
<script>
import Carousel from "vue-owl-carousel";

import Vue from "vue";

import axios from "axios";

import VueAxios from "vue-axios";

import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueAxios, axios);
Vue.use(VueSimpleAlert);

export default {
  components: {
    Carousel,
  },

  props: ["pck"],

  data() {
    return {
    
      error: [],

      trip:
        sessionStorage.getItem("book_type") == "local"
          ? ""
          : sessionStorage.getItem("trip"),
      authorization: "",

      postData: {

        pickup_locality:null,
        pickup_street:null,
        pickup_landmark:null,
        pickup_location:null,
        pickup_state:null,
        pickup_city:null,
        pickup_pincode:null,
        drop_locality:null,
        drop_street:null,
        drop_landmark:null,
        drop_location:null,
        drop_state:null,
        drop_city:null,
        drop_pincode:null,
        payMode: null,

        user_id: null,

        cus_name: sessionStorage.getItem('name')==''?null:sessionStorage.getItem('name')+ ' ' +sessionStorage.getItem('lname'),

        cus_email: sessionStorage.getItem('email')==''?null:sessionStorage.getItem('email'),

        cus_mobile: sessionStorage.getItem('mobile')==''?null:sessionStorage.getItem('mobile'),

        pick_address: null,

        drop_address: null,

        amount_given: "full",

        total_amount: null,

        pic_date: sessionStorage.getItem("pick_date"),

        return_date: sessionStorage.getItem("return_date"),

        pickupTime: sessionStorage.getItem("PickupTime"),

        returnTime: sessionStorage.getItem("ReturnTime"),

        chooseCar: sessionStorage.getItem("chooseCar"),
        chooseNoofDays:
          sessionStorage.getItem("chooseNoofDays") == null
            ? sessionStorage.setItem("chooseNoofDays", "1")
            : sessionStorage.getItem("chooseNoofDays"),

        offer_code: null,

        data: null,
      },

      isFull: true,

      payabled_price: null,
      difference: null,
      person: null,
    };
  },
  created() {
    if (!sessionStorage.getItem("auth")) {
        this.$router.push("/Taxitourbooking");
    }
    this.postData.chooseNoofDays =
      sessionStorage.getItem("chooseNoofDays") == null
        ? sessionStorage.setItem("chooseNoofDays", "1")
        : sessionStorage.getItem("chooseNoofDays");
    axios
      .get("Panel/JwtToken/GetTokenData", {})
      .then((res) => {
        this.authorization = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });

    this.person = sessionStorage.getItem("person");
    if (this.trip != "Round") {
      sessionStorage.setItem(
        "return_date",
        sessionStorage.getItem("pick_date")
      );
    }
    this.returnDate = this.trip == "Round" ? true : false;

    if (
      sessionStorage.getItem("book_type") == "" ||
      sessionStorage.getItem("book_type") == null
    ) {
      this.difference = sessionStorage.getItem("chooseNoofDays");
    } else {
      var date1 = sessionStorage.getItem("return_date").split("-");
      const myreturnDate = new Date(date1[2], date1[1] - 1, date1[0]);

      var date2 = sessionStorage.getItem("pick_date").split("-");
      const mypickDate = new Date(date2[2], date2[1] - 1, date2[0]);

      this.difference =
        1 + Math.round((myreturnDate - mypickDate) / (1000 * 60 * 60 * 24));
    }
  },

  updated() {
    this.setterPay();
  },

  methods: {
    totalToll(toll) {
      if (this.trip == "Round") {
        return 2 * toll;
      } else {
        return toll;
      }
    },
    basefareTotal(difference, daily_kmlimit, kmlimit, price_per_km) {
      if (this.trip == "Round") {
        if (difference * daily_kmlimit > 2 * kmlimit) {
          console.log(difference * daily_kmlimit * price_per_km);
          return difference * daily_kmlimit * price_per_km;
        } else {
          console.log(2 * kmlimit * price_per_km);
          return 2 * kmlimit * price_per_km;
        }
      } else {
        return difference * daily_kmlimit * price_per_km;
      }
    },
    basefareKM(difference, daily_kmlimit, kmlimit) {
      if (this.trip == "Round") {
        if (difference * daily_kmlimit > 2 * kmlimit) {
          console.log(difference * daily_kmlimit);
          return difference * daily_kmlimit;
        } else {
          console.log(2 * kmlimit);
          return 2 * kmlimit;
        }
      } else {
        return kmlimit;
      }
    },
    totalRoundPrice(
      daily_kmlimit,
      kmlimit,
      difference,
      price_per_km,
      driver_charge,
      carNo
    ) {
      if (parseInt(difference) * daily_kmlimit > 2 * kmlimit) {
        var daily_km = parseInt(daily_kmlimit) * parseInt(difference);
        var total_km_price = parseInt(daily_km) * parseInt(price_per_km);
        var total_driver = parseInt(difference) * parseInt(driver_charge);
        var grannd_tot = (total_km_price + total_driver) * parseInt(carNo);
        console.log("total- " + grannd_tot);
        return grannd_tot;
      } else {
        // return (
        //   (parseInt(difference) *
        //     (2 * parseInt(kmlimit) * parseInt(price_per_km)) +
        //     parseInt(difference) * parseInt(driver_charge)) *
        //   parseInt(carNo)
        // );
        var total_driver = parseInt(difference) * parseInt(driver_charge);
        var newTotal = this.basefareTotal(difference,daily_kmlimit,kmlimit,price_per_km);
        //console.log("total- " + newTotal);
        return (parseInt(newTotal)+parseInt(total_driver)) * parseInt(carNo);
      }

    },
    totalOnewayPrice(price, driver, car, difference) {
      return (
        (parseInt(price) + parseInt(driver)) *
        parseInt(car) *
        parseInt(difference)
      );
    },
    totalActualPrice(act_price, sid, pck_price, person, car) {
      if (sid == 19) {
        return parseInt(pck_price) * parseInt(person);
      } else {
        return (
          parseInt(act_price) *
          parseInt(this.postData.chooseNoofDays) *
          parseInt(car)
        );
      }
    },
    setterPay() {
      var total = 0;
      if (this.isFull == true) {
        if (this.trip == "Round") {
          // this.payabled_price = 2 * this.pck[0].act_price * this.postData.chooseCar * this.difference;
          this.payabled_price = this.totalRoundPrice(
            this.pck[0].daily_kmlimit,
            this.pck[0].kmlimit,
            this.difference,
            this.pck[0].price_per_km,
            this.pck[0].driver_charge,
            this.postData.chooseCar
          );
          // this.payabled_price = ((this.pck[0].daily_kmlimit*this.difference*this.pck[0].price_per_km + this.pck[0].driver_charge*this.difference)*this.postData.chooseCar);
        } else if (this.trip == "One way") {
          this.payabled_price = this.totalOnewayPrice(
            this.pck[0].act_price,
            this.pck[0].driver_charge,
            this.postData.chooseCar,
            this.difference
          );

          //this.payabled_price = this.pck[0].act_price * this.postData.chooseCar * this.difference + parseInt(this.pck[0].driver_charge);
        } else {
          this.payabled_price = this.totalActualPrice(
            this.pck[0].act_price,
            this.pck[0].sub_id,
            this.pck[0].pkg_price,
            this.person,
            this.postData.chooseCar
          );
        }
      } else {
        if (this.trip == "Round") {
          var roundtotal = "";
          roundtotal = this.totalRoundPrice(
            this.pck[0].daily_kmlimit,
            this.pck[0].kmlimit,
            this.difference,
            this.pck[0].price_per_km,
            this.pck[0].driver_charge,
            this.postData.chooseCar
          );

          this.payabled_price = Number((20 / 100) * roundtotal).toFixed(0);
          // this.payabled_price = Number(
          //   (15 / 100) * (2 * this.pck[0].act_price * this.postData.chooseCar * this.difference)
          // ).toFixed(0);
        } else if (this.trip == "One way") {
          total = this.totalOnewayPrice(
            this.pck[0].act_price,
            this.pck[0].driver_charge,
            this.postData.chooseCar,
            this.difference
          );
          this.payabled_price = Number((20 / 100) * total).toFixed(0);
        } else {
          total = this.totalActualPrice(
            this.pck[0].act_price,
            this.pck[0].sub_id,
            this.pck[0].pkg_price,
            this.person,
            this.postData.chooseCar
          );
          this.payabled_price = Number((20 / 100) * total).toFixed(0);
        }
      }
    },

    myPayStatus(data, response) {
      var postData = {
        Ordered_data: data,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
      };

      axios
        .post("Panel/jwtToken/orderSuccess", postData, {})
        .then((res) => {
          // this.$alert('payments done');
          if (res.data.status === 1) {
            this.$fire({
              title: "Thank You for Booking with Us!",
              text: "Payments done successfully",
              type: "success",
              showCloseButton: true,
            });
            this.$router.push("/ShowCabBooked");
          } else {
            this.$fire({
              title: "Thank You for Booking with Us!",
              text: "Payments faild !",
              type: "warning",
              showCloseButton: true,
            });
            alert(" payments faild !");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else e.preventDefault();
    },
    isNumbers(e) {
      const charCode = String.fromCharCode(e.keyCode);
      if (!/[0-9]/.test(charCode)) {
        e.preventDefault();
      }
    },

    bookingForm(e) {
      e.preventDefault();

      let token = this.authorization.token;
      if (sessionStorage.getItem("auth")) {
        this.postData.user_id = atob(sessionStorage.getItem("user"));

        this.postData.data = this.pck[0];

        this.postData.trip_type = this.trip;

        this.postData.total_amount = this.payabled_price;
        // this.postData.pick_address=this.postData.pickup_locality+'-'+
        //                           this.postData.pickup_street+'-'+
        //                           this.postData.pickup_state+'-'+
        //                           this.postData.pickup_city+'-'+
        //                           this.postData.pickup_pincode ;
        // this.postData.drop_address=this.postData.drop_locality+
        //                           this.postData.drop_street+'-'+
        //                           this.postData.drop_state+'-'+
        //                           this.postData.drop_city+'-'+
        //                           this.postData.drop_pincode ;

        axios
          .post("Panel/jwtToken/insertOrder", this.postData, {
            headers: {
              "content-type": "application/json",
              authorization: token,
            },
          })

          .then((res) => {
            if (res.data.status == 2200) {
              this.$fire({
                title: "Thank You for Booking with Us!",
                text: "Payments done successfully",
                type: "success",
                showCloseButton: true,
              });
              this.$router.push("/ShowCabBooked");
            }
            if (res.data.status == 200) {
              // console.log(res.data);
                var imgURL = "https://www.bizaad.com/img/logo.bca96c90.png";
              var options = {
                key: res.data.data.key, // Enter the Key ID generated from the Dashboard
                amount: res.data.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "Bizaad",
                description: "Bizaad Transaction",
                image: imgURL,
                order_id: res.data.data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

                handler: (response) =>
                  this.myPayStatus(res.data.data, response),
                prefill: {
                  name: res.data.data.prefill.name,
                  email: res.data.data.prefill.email,
                  contact: res.data.data.prefill.contact,
                },
                notes: {
                  address: res.data.data.notes.address,
                },
                theme: {
                  color: "#3399cc",
                },
              };

              var rzp1 = new Razorpay(options);
              rzp1.on("payment.failed", function (response) {
                console.log(response.error.code);
                // console.log(response.error.description);
                // console.log(response.error.source);
                // console.log(response.error.step);
                // console.log(response.error.reason);
                // console.log(response.error.metadata.order_id);
                // console.log(response.error.metadata.payment_id);
              });

              rzp1.open();

              // this.$router.push("/ShowCabBooked");
            } else {
              this.error.push("Booking failed ! try again");
            }
          })

          .catch((err) => {
            console.log(err.response);
          });

        console.log("vailed user");
      } else {
        console.log("invailed user");

        alert("first login");

        this.$router.push("/Loginform");
      }
    },

    payFull() {
      this.isFull = true;

      this.postData.amount_given = "full";

      this.setterPay();
    },

    payHalf() {
      this.isFull = false;

      this.postData.amount_given = "20%";

      this.setterPay();
    },
  },
};
</script>
<style>
.taxi-booking {
  padding: 40px 0px;
}

.taxi-booking .heading-res {
}

.taxi-booking .heading-res h2 {
  background-color: #ebebeb;

  font-size: 18px;

  /* margin: 0; */

  /* line-height: 36px; */

  padding: 10px 20px;
}

.taxi-booking .car-booking-details {
  /* border-bottom: #c4c4c4 1px solid; */

  /* padding-bottom: 17px; */

  /* float: left; */

  /* width: 70%; */
}

.taxi-booking .car-booking-details .video-img-section {
}

.taxi-booking .car-booking-details .video-img-section .img-set {
  margin-bottom: 10px;
}

.taxi-booking .car-booking-details .video-img-section .img-set img {
  width: 100%;
}

.taxi-booking .car-booking-details .video-img-section .video-set {
  margin-bottom: 10px;
}

.holiday-info {
  border-bottom: #cacaca 1px solid;

  padding-bottom: 18px;

  margin-bottom: 12px;
}

.holiday-info .holiday-name {
  display: block;

  font-size: 24px;
}

.holiday-info .holiday-days {
  display: block;
}

.holiday-discription {
}

.holiday-discription h3 {
  color: #2a0b67;

  font-size: 21px;
}

.holiday-discription h4 {
  font-size: 17px;

  color: #2a0b67;
}

.holiday-discription p {
  font-size: 13px;
}

.taxi-booking .car-booking-details .destination-show {
  line-height: 49px;

  display: flex;

  align-items: center;
}

.taxi-booking .car-booking-details .destination-show span {
  font-size: 25px;
}

.taxi-booking .car-booking-details .destination-show i {
  font-size: 32px;
}

.taxi-booking .car-booking-details .car-info-box {
  position: relative;
}

.taxi-booking .car-booking-details .car-info-box i {
  font-size: 30px;

  float: left;

  padding-top: 4px;

  padding-right: 28px;

  /* text-align: center; */

  padding-left: 2px;
}

.taxi-booking .car-booking-details .car-info-box .day {
  position: absolute;

  left: 6px;

  top: 40px;

  font-size: 21px;

  font-weight: 600;
}

.taxi-booking .car-booking-details .car-info-box .day sup {
  font-size: 15px;
}

.taxi-booking .car-booking-details .car-info-box .car-name {
  font-size: 28px;
}

.taxi-booking .car-booking-details .car-info-box .car-from {
  font-size: 18px;

  color: #6f6c6c;
}

.taxi-booking .car-booking-details .car-info-box .car-time {
  display: block;

  font-size: 18px;

  color: #676767;
}

.taxi-booking .car-booking-details .middle-info {
  text-align: center;

  line-height: 75px;

  font-size: 43px;

  color: #c8c8c8;
}

.taxi-booking
  .car-booking-details
  .amount-section
  .promo
  .form-group
  .form-control {
}

.taxi-booking
  .car-booking-details
  .amount-section
  .promo
  .form-group
  .form-control {
  width: 68%;

  height: 26px;

  border-radius: 0;
}

.taxi-booking .car-booking-details .amount-section .promo .form-group button {
  /* width: 68%; */

  /* height: 23px; */

  /* border-radius: 0; */

  border: none;

  font-size: 12px;

  background-color: #ff3d00;

  padding: 0px 14px 3px;

  color: #fff;
}

.taxi-booking .car-booking-details .amount-section .amount {
  text-align: right;
}

.taxi-booking .car-booking-details .amount-section .amount h4 {
}

.taxi-booking .car-booking-details .amount-section .amount span {
  font-size: 13px;
}

.pay-type .checkbox-custom,
.radio-custom {
  opacity: 0;

  position: absolute;
}

.pay-type .radio-custom-label {
}

.pay-type .checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";

  background: #fff;

  border: 2px solid #698073;

  display: inline-block;

  vertical-align: middle;

  width: 22px;

  height: 22px;

  padding: 0px;

  margin-right: 10px;

  text-align: center;
}

.pay-type .radio-custom + .radio-custom-label:before {
  border-radius: 0;

  line-height: 17px;

  color: #f73e3e;

  font-size: 20px;

  /* left: 0; */
}

.pay-type .radio-custom:checked + .radio-custom-label:before {
  content: "\F012C";

  font-family: "Material Design Icons";
}

#bar-fixed.stickIt {
  position: sticky;

  top: 0px;
}

.sticky-set {
  align-items: flex-start;
  position: sticky;
  top: 0;
}
.payment-info {
}

.payment-info .note {
  border-bottom: #cac2c2 1px solid;

  padding: 10px 0px;

  font-size: 14px;

  color: #757575;
}
.payment-info .note .accordion {
}
.payment-info .note .accordion .br-info {
  display: block;
}
.payment-info .note .accordion .card-header {
}
.payment-info .note .accordion .bt-long {
  margin: 0 !important;
  background-color: #2a0b67;
  /* display: block; */
  width: 100%;
  text-align: left;
  font-size: 13px;
  border-radius: 0;
}
.payment-info .note .accordion .bt-long i {
  float: right;
}
.payment-info .note .accordion .not-collapsed .up {
  display: block;
}
.payment-info .note .accordion .not-collapsed .down {
  display: none;
}
.payment-info .note .accordion .collapsed .down {
  display: block;
}
.payment-info .note .accordion .collapsed .up {
  display: none;
}

.payment-info .note .accordion .open-block {
}
.payment-info .note .accordion .open-block .card-body {
  padding: 0px 6px;
}
.payment-info .note .accordion .open-block .card {
  border: #c5c5c5 1px solid;
  border-radius: 0;
  padding: 6px 4px;
}

.payment-info .note .accordion .open-block span {
  font-size: 12px;
  color: #2a0b67;
}

.payment-info .note p {
  margin: 0;

  font-style: italic;

  line-height: 18px;
}

.payment-info h2 {
  margin: 0;

  font-size: 15px;

  background-color: #2a0b67;

  color: #fff;

  padding: 9px 8px;
}

.payment-info ul {
  padding: 0;

  background-color: #f3e8e8;

  margin: 0;
}

.payment-info ul li {
  list-style: none;

  font-size: 13px;

  overflow: hidden;

  padding: 5px 8px;

  border-bottom: #d4d0d0 1px solid;
}

.payment-info ul li span {
  font-weight: 100;
  float: right;
  text-align: right;
  font-weight: 500;
  color: #000;
}

.payment-info .amount-section .pay-type {
  margin-right: 8 px;
}

.payment-info .amount-section .promo .form-group button {
  border: none;
  font-size: 12px;
  background-color: #ff3d00;
  padding: 0px 14px 3px;
  color: #fff;
}

.payment-info .form-check 
{
    margin-right:10px;
}


.payment-info .amount-section .amount {
  display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.payment-info .amount-section .amount h4 {
}
.payment-info .amount-section .amount span {
}
.payment-info .amount-section .proccess-button {
}
.payment-info .amount-section .proccess-button button {
  border: none;
  background-color: #ff3d00;
  width: 100%;
  font-size: 20px;
  padding: 10px 0px;
  color: #fff;
}

.payment-info .amount-section .promo .form-group .form-control {
  width: 76%;
  height: 26px;
  border-radius: 0;
}

.payment-info .amount-section {
  border: #cfcdcd 1px solid;
  padding: 8px 10px;
  margin-top: 13px;
}

.payment-info .amount-section h3 {
  font-size: 14px;
}

.payment-info .amount-section .pay-type {
  margin-right: 8px;
}

.payment-info .amount-section .promo {
  border-bottom: #d9d9d9 1px solid;
  margin-bottom: 18px;
}

.payment-info .amount-section .promo .form-group {
}

.payment-info .amount-section .promo .form-group label {
  font-size: 13px;

  font-weight: 500;
}
.payment-info .amount-section .on-pay {
  border-bottom: #d9d9d9 1px solid;
  margin-bottom: 18px;
  padding-bottom: 16px;
}
.payment-info .amount-section .how-pay {
  border-bottom: #d9d9d9 1px solid;
  margin-bottom: 18px;
  padding-bottom: 16px;
}

.payment-info ul li.total-show {
  font-size: 15px;
  background-color: #e6f9ff;
}
.payment-info ul li.total-show strong {
}
.payment-info ul li.total-show span {
}

.personal-info {
  margin: 0 auto;
}

.personal-info h2 {
  border-bottom: #d6d6d6 1px solid;

  font-size: 18px;

  padding: 18px 0px;

  margin-bottom: 24px;
}

.personal-info .form-group {
  margin: 9px 6px;
}

.personal-info .form-group .form-control {
  border-radius: 0px;

  font-size: 13px;

  height: 40px;
}

.personal-info .form-group .form-control2 {
  border-radius: 0px;

  font-size: 13px;

  /* height: 40px; */

  width: 100%;

  border: none;

  outline: none;

  padding: 5px 5px;
  border: #d1d1d1 1px solid;
}

.personal-info .form-check {
  margin: 10px 18px;
}

.personal-info .form-check .form-check-label {
  font-size: 13px;
}

.personal-info .payment-select {
  background-color: #fff;
}

.personal-info .make-payment {
  border: none;

  background-color: #ff3d00;

  color: #fff;

  font-weight: 600;

  padding: 8px 56px;

  font-size: 18px;

  border-radius: 4px;

  margin-top: 20px;
}

.oye-empty {
}

.oye-empty h2 {
}

.oye-empty .empty-box {
  margin: 40px 0px;
}

.oye-empty .empty-box img {
}

.oye-empty .empty-box h3 {
}

.oye-empty .empty-box p {
}

.web-sld-img {
}
.mob-sld-img {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .web-sld-img {
    display: none !important;
  }
  .mob-sld-img {
    display: block !important;
  }
  .taxi-booking .car-booking-details .destination-show span {
    font-size: 16px;
  }

  .taxi-booking .car-booking-details .destination-show i {
    font-size: 26px;
  }

  .taxi-booking .car-booking-details .car-info-box i {
    padding-top: 4px;

    padding-right: 22px;
  }

  .taxi-booking .car-booking-details .car-info-box .car-name {
    font-size: 23px;
  }

  .taxi-booking .car-booking-details .car-info-box .car-from {
    font-size: 15px;

    color: #6f6c6c;
  }

  .taxi-booking .car-booking-details .car-info-box .car-time {
    display: block;

    font-size: 14px;

    color: #676767;
  }

  .taxi-booking .car-booking-details .middle-info {
    line-height: 50px;
  }

  .taxi-booking .car-booking-details .car-info-box {
    position: relative;

    margin-bottom: 12px;
  }

  .taxi-booking
    .car-booking-details
    .amount-section
    .promo
    .form-group
    .form-control {
    width: 75%;

    height: 34px;
  }

  .taxi-booking .car-booking-details .amount-section .promo .form-group button {
    padding: 0px 23px 3px;
  }

  .taxi-booking .personal-info .payment-select .form-check {
    width: 37%;
  }

  .taxi-booking .personal-info .payment-select {
    background-color: #fff;

    margin: 0px 8px;
  }
  .taxi-booking .car-booking-details .amount-section {
    margin-top: 16px;
  }
  .sticky-set {
    position: inherit;
  }
}
</style>
