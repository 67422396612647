<template>
<div class="wish-list">
     
     <div v-if="this.$store.state.getwishlist.length > 0">  
       <div class="container">
           <h2>
               Items ({{this.$store.state.getwishlist.length}})
           </h2>
         
            <div class="row g-2" >
               <div class="col-lg-3 col-6 " v-for="(obj, key) in this.$store.state.getwishlist" :key="key">
                <div class="product-thumbnil">
                     
                            <div class="pro-type">
                                <span class="tag" v-if="obj.product.image_tost_id == 1">New Arrival</span>
                                <span class="tag" v-if="obj.product.image_tost_id == 2">Sale</span>
                            </div>
                            <div class="remove-wh" @click="wishlistdelete(obj)">
                           <i class="mdi mdi-close-circle-outline"></i>
                           </div>
                            <div class="product-items">

                                <!-- <a href="#" class="wish-btn nasa-tip nasa-tip-left"> <span class="nasa-tip-content">Wishlist</span> <i class="nasa-icon icon-nasa-like"></i></a> -->
                                <!-- <a href="#" class="quick-btn nasa-tip nasa-tip-left"> <span class="nasa-tip-content">Quick View</span> <i class="nasa-icon pe-7s-look"></i></a>
                                <a href="#" class="compare-btn nasa-tip nasa-tip-left"> <span class="nasa-tip-content">Compare</span> <i class="pe-7s-repeat"></i></a> -->
                                <a href="javascript:void(0)" class="crt-btn"> <span @click="addToCartlog(obj)">Add to Cart</span> <i class="mdi mdi-plus"></i></a>
                            </div>
                            <div class="img-section">
                                 
                                <img
                          :src="
                            $baseURL +
                            '/public/images/product/original/' +
                            obj.product_image.product_image
                          "
                          alt=""
                        /> 
                            </div>

                            <div class="product-info">
                                 <a
                          href="javascript:void(0)" class="pro-name"
                          @click="productdetail(obj.product.slug)"
                          >{{ obj.product.name }} -
                          <span>{{ obj.product_size }}</span></a
                        >
                        <div class="star-rating">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                </div>

                                <div class="price-wrap">
                                    <span class="cut-price">₹{{ parseInt(obj.product.actual_price) }}</span>
                                    <span class="price">₹{{ parseInt(obj.product.offer_price) }}</span>
                                </div>
                                <!-- {{ obj.product_sizes }} -->
                                <div class="size">
                                  <span
                                        v-for="(sizes,index) in obj.product_sizes"
                                        :key="index" class="size">
                                        <a :class="['mysized'+obj.id,{ 'active' : index === 0 }]"
                                        :id="'sizename'+obj.id+','+sizes.id"
                                        @click="selectproductsize(obj.id,sizes.id)"
                                        href="javascript:void(0)">{{ sizes.variable_unit }}</a>
                                      </span> 
                                </div>

                                

                            </div>
                        </div>
               </div>
            </div>
             
        </div>
    </div>
 </div>  
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
 
export default {

    data() {
        return {
            user_info: "",
        };
    },
    methods:{
         productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
      window.location.reload();
    },
    selectproductsize(product_id,sizeID) {
                var selectedSize = document.getElementsByClassName('mysized'+product_id+' active');//mysized"+product_id
                 if(selectedSize !=null){
            [].forEach.call(selectedSize, function(elems) { 
                    elems.classList.remove("active");
                });
            }
            var element = document.getElementById("sizename"+product_id+','+sizeID);
            if(element!==null){
            element.classList.add("active");
            }
 
     },
     addToCartlog(product) {
      var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
      var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.product_id,
        size_id: size_id,
         qty: "1",
      };
      
     
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
          this.wishlistdeleteaddtocart(product);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    wishlistdeleteaddtocart(product) {
      axios.get("/api/wish-list-delete/" + product.id, {})
      .then((res) => {
        console.log(res);
         this.$store.commit("getuserwislist", product.user_id);
         }).catch((err) => {
              console.log(err.response);
          });
      },
    wishlistdelete(product) {
      this.$fire({
              title: 'Are you sure?',
              text: 'You can\'t revert your action',
              type: 'warning',
              // type: "error",
              showCancelButton: true,
              confirmButtonColor: '#fe4d01',
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, Keep it!',

             // timer: 5000,
            }).then((r) => {
              if (r.value) {
                  axios.get("/api/wish-list-delete/" + product.id, {}).then((res) => {
                     console.log(res);
                     this.$store.commit("getuserwislist", product.user_id);
                     //this.$store.commit("deleteFromcart", index);
                     this.$fire('Deleted!', 'Your Todo has been deleted', 'success')
            })
            .catch((err) => {
              console.log(err.response);
            });

              }
              else{
            this.$fire('Canceled', 'Your Todo is still in place', 'warning')
            }

               
            });
        },
    },
    created() {
    var token = localStorage.getItem("token");
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
}
</script>

<style >
.wish-list{    padding: 36px 0px;}
 .wish-list .product-thumbnil .remove-wh {
     position: absolute;
    right: 0;
    z-index: 10;
    font-size: 28px;
    top: -13px;
    right: -4px;
    color: #696363;
 }
.wish-list .product-thumbnil {
    position: relative;
    margin-bottom: 15px;
}
@media only screen and (max-width: 600px) {

    .wish-list{    height: auto;}
}
</style>