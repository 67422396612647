import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

Vue.use(Vuex);
let cart = window.localStorage.getItem('cart');
export default new Vuex.Store({
    state: {
        cart: cart ? JSON.parse(cart) : [],
        getitems: [],
        test: [],
        total:[],
        getwishlist: [],
        coupon: [],
        variablevalue:[],
        price:[],
    },
    getters: {
        // productQuantity: state => product => {
        //     const item = state.cart.find(i => i.id === product.id)
        //     if (item) return item.quantity
        //     else return null
        // }

    },
    mutations: {
        addToCart(state, product) {
            console.log(product);
            var itemm = state.cart.filter(function (elem) {
                if (elem.product_id == product.product_id && elem.size_id == product.size_id)
                    return elem.product_id;
            });
            if (itemm.length > 0) {
                itemm[0].quantity += 1;

            } else {
                //state.cart.push({...product, quantity: 1})
                state.cart.push({ ...product, quantity: 1 })
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));

        },
        removeFromCart(state, product) {

            let item = state.cart.find(i => i.size_id === product)
            
            if (item.size_id === product) {
                if (item.quantity == 1) {
                     item.quantity = 1;
                 
                } else {
                    item.quantity--
                   
                }
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        deleteFromcart(state, index) {
            state.cart.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },

      async getusercartitem(state, user_id) {
            console.log('ali' , state);
        await axios
                .get("/api/cart-items/" + user_id, {})
                .then((res) => {
                    state.getitems = res.data.data;
                    state.test = res.data.coutactive;
                    state.total = res.data.total;
                    state.variablevalue = res.data.variablevalue;
                    state.price = res.data.price;
                    state.delivery = res.data.delivery;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        getuserwislist(state, user_id) {
            axios
                .get("/api/all-wish-list/" + user_id, {})
                .then((res) => {
                    state.getwishlist = res.data.data;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        savecoupondata(state, savecoupondata) {
         
            state.coupon = savecoupondata;
            localStorage.setItem('coupon', JSON.stringify(state.coupon));
        }

        
    },
    actions: {},
    modules: {},


});
