 <template>
  <div class="myaccount-set">
    <div class="container">
      <div class="myaccount-set-in">
        <div class="row justify-content-center">
          <div class="col-lg-6 form-cover">

            <h3>Login/Register</h3>

            <h5>Verify with OTP</h5>
            <h5>{{ this.email }}</h5>
            <div class="row align-items-center justify-content-center g-1 otp-screen" id="otp-screen">
              <input required type="text" class="form-control text-center test" ref="myInputs" placeholder="-" v-model="otp_first_len"
                minlength="1" maxlength="1" value="name" />
                <input required type="text" class="form-control text-center" placeholder="-" v-model="otp_first_sec"
                minlength="1" maxlength="1" value="name" />
                <input required type="text" class="form-control text-center" placeholder="-" v-model="otp_first_thr"
                minlength="1" maxlength="1" value="name" />
                <input required type="text" class="form-control text-center" placeholder="-" v-model="otp_first_for"
                minlength="1" maxlength="1" value="name" @input="handleSubmitLogin($event.target.value)" />
                </div>
                <div class="resendTime text-center" v-if="time">
              <span> Resend OTP in: </span> 00:{{ time }}
            </div>
            <div class="resendTime text-center" v-else>
              <button class="ressendotp" v-on:click="resendotp($event)">RESEND OTP</button>
            </div>
            <div class="col-md-12">

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      otp_first_len: "",
      otp_first_sec: "",
      otp_first_thr: "",
      otp_first_for: "",
      message: "",
      error: "",
      email: "",
      timer: null,
      time: 59,

    };
  },
  methods: {


    async handleSubmitLogin(name) {
      const otp = this.otp_first_len + this.otp_first_sec + this.otp_first_thr + name;
      if (otp.length == '4') {
        const userData = {
          email: this.email,
          otp: otp,
        };
        console.log('userData',userData);

        await axios
          .post("/api/auth/login", userData)
          .then((res) => {

            this.message = res.data.access_token.error;
          if (res.data.access_token) {
              console.log('res.data.access_token',res.data.access_token);
              res.data.access_token;
               localStorage.setItem("token",res.data.access_token);

              this.getusertoken(res.data.access_token);
              window.location.reload();
              if (history.go(-1) == 'Login') {
                this.$router.push("/Dashboard");
              }
              else {
                history.go(-1);
              }
            } else {
              this.otp_first_len='',
              this.otp_first_sec='',
              this.otp_first_thr='',
              this.otp_first_for='',
              this.$refs.myInputs.focus();

              name
              this.$fire({
                title: "Bwitchedzone Login Failed !",
                text: this.message,
                type: "error",
                timer: 5000,
              }).then((r) => {
                console.log(r.value);
              });
            }  
          })
          .catch((err) => {
            console.log(err.response);
          });

      }

    },


    getusertoken(token) {

      axios.get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;

      
        this.addusertocartitems(this.user_info.id);
      })
      .catch((err) => {
        console.log(err.response);
      });
      
      },
    addusertocartitems(user_id) {
      
      if (localStorage.getItem("cart")) {
        this.cart = JSON.parse(localStorage.getItem("cart"));
        console.log('cha',JSON.parse(localStorage.getItem("cart")) );
        this.cart.forEach((item) => {
          let addtocartdata = {
            user_id: user_id,
            product_id: item.product_id,
            qty: item.quantity,
            size_id: item.size_id,
          };
          
          axios
            .post("/api/add-to-cart", addtocartdata)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err.response);
            });
        });
      }
      localStorage.removeItem("cart");
      sessionStorage.removeItem("email");

    },
    countdown() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer)
      }
    },

    resendotp() {

      this.time = 59;
      const userData = {
        email: this.email,
      };
      axios
        .post("/api/auth/get-otp", userData)
        .then((res) => {
          console.log(res);
          this.$refs.myInputs.focus();

          this.timer = setInterval(this.countdown, 1000);


        })
        .catch((err) => {
          console.log(err.response);
        });
    },


  },
  created() {
    this.email = sessionStorage.getItem("email");

  },
  mounted() {
  this.$refs.myInputs.focus();
    this.timer = setInterval(this.countdown, 1000);

    let otp = document.querySelector('#otp-screen');

    for (let pin of otp.children) {
      pin.onkeyup = function () {
        if (pin.nextElementSibling) {
          pin.nextElementSibling.focus();
        }
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  }


};
</script>

 <style>
 input.form-control.text-center {
    width: 10%;
    margin: 3px;
    font-size: 26px;
}
.myaccount-set .myaccount-set-in .otp-screen .form-control:focus {border: #000 1px solid; box-shadow: none;}
 </style>