<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="profile-info">
                  <!-- <div class="pro-img">
                    <img src="images/user.jpg" alt="" />
                  </div> -->
                  <div class="pro-info">
                    <h3>{{ user_info.name }}</h3>
                    <h4>+91{{ user_info.phone }}</h4>
                  </div>
                </div>

              </div>
              <div class="col-md-3">
                <div class="logout text-end">
                  <a href="javascript:void(0)" @click="logout()">Back<i class="mdi mdi-arrow-u-left-top"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr />

            <div class="dash-edit-section" v-for="(order, index) in displayedPosts" :key="index">
              <!-- {{ orders }} -->
              <div class="row">
                <div class="col-md-3 col-6">
                  <h2>Order placed</h2>
                  <span class="small-top">{{ order.orderdate }}</span>
                </div>
                <div class="col-md-3 col-6">
                  <h2>Amount</h2>
                  <span class="small-top">
                    <i class="mdi mdi-currency-inr"></i>{{ order.total_offer_price }}</span>
                </div>
                <div class="col-md-3 col-6">
                  <h2>shipping To</h2>
                  <span class="small-top">{{ order.name }},{{ order.town_city }},{{ order.state }},{{ order.pincode
                  }}</span>
                </div>
                <div class="col-md-3 col-6">
                  <h2>ORDER No:1000{{ order.id }}</h2>
                  <span class="small-top"><a href="javascript:void(0)" v-on:click="orderitem(order.id)">Order Details</a>
                    |
                    <a href="javascript:void(0)" target="invoice" v-on:click="invoice(order.id)">Invoice</a></span>
                </div>
              </div>

              <hr />
              <div class="order-show">
                <ul>
                  <!-- {{ order.order_items }} -->
                  <li v-for="(item, index) in order.order_items" :key="index">
                    <div class="row">
                      <div class="col-md-1 col-lg-2 col-3">
                        <div class="order-img">
                          <img :src="$baseURL +
                            '/public/images/product/original/' +
                            item.product_image
                            " alt="" />
                        </div>
                      </div>

                      <div class="col-md-10 col-lg-10 col-9">
                        <div class="order-dlt">
                          <h3>
                            <a href="javascript:void(0)" @click="productdetail(item.slug)">{{ item.product_name }}</a>
                          </h3>
                          <span class="other-dtl">
                            <strong> Price: </strong>
                            <i class="mdi mdi-currency-inr"></i>{{ item.offer_price }}</span>
                          <strong>Order Status</strong>
                          <div class="container" v-if="item.order_item_status != 6">
                            <div class="row">
                              <div class="col-12 col-md-12 hh-grayBox pt45 pb20">
                                <div class="row justify-content-between">
                                  <div
                                    v-if="item.order_item_status == 0 || item.order_item_status == 1 || item.order_item_status == 7"
                                    v-bind:class="{ order_tracking: true, completed: true }">
                                    <span class="is-complete"></span>
                                    <p>Process<br></p>
                                      <!-- <span>{{item.pending_date == null ? '': item.pending_date}}</span></p> -->
                                  </div>

                                  <div v-if="item.order_item_status == 1 || item.order_item_status == 7"
                                    v-bind:class="{ order_tracking: true, completed: true }">
                                    <span class="is-complete"></span>
                                    <p>Shipped<br></p>
                                      <!-- <span>{{item.shipping_date == null ? '' : item.shipping_date}}</span></p> -->
                                  </div>

                                  <div v-else v-bind:class="{ order_tracking: true, completed: false }">
                                    <span class="is-complete"></span>
                                    <p>Shipped<br><span></span></p>
                                  </div>
                                  <div v-if="item.order_item_status == 7"
                                    v-bind:class="{ order_tracking: true, completed: true }">
                                    <span class="is-complete"></span>
                                    <p>Delivered<br></p>
                                      <!-- <span>{{ item.delivery_date == null ? '': item.delivery_date }}</span></p> -->
                                  </div>
                                  <div v-else v-bind:class="{ order_tracking: true, completed: false }">
                                    <span class="is-complete"></span>
                                    <p>Delivered<br><span></span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div v-else class="container">

                            <div class="order-tracking">
                              <div class="row">
                                <div class="col-12 col-md-12 hh-grayBox pt45 pb20">
                                  <div class="row justify-content-between">
                                    <div 
                                    v-bind:class="{ order_tracking: true, completed: true }">
                                    <span class="is-cancle"></span>
                                    <p>Cancel<br></p>
                                      <!-- <span>{{ item.cancle_date == null ? '': item.cancle_date}}</span></p> -->
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <!-- <span class="other-dtl text-danger" v-if="item.order_item_status == 7">
                            <strong>Order Status:</strong>
                            Cancel
                          </span> -->





                          <!-- <span
                            class="other-dtl text-primary"
                            v-if="item.order_item_status == 0"
                          >
                            <strong>Order Status:</strong>
                            Process
                          </span>

                          <span
                            class="other-dtl text-secondary"
                            v-if="item.order_item_status == 1"
                          >
                            <strong>Order Status:</strong>
                            Dispatched
                          </span>

                          <span class="other-dtl" v-if="item.order_item_status == 1">
                            <strong> track number: </strong>
                             <a href="https://ecomexpress.in" target="blank"> {{ item.awb_number }}</a>
                          </span>

                          <span
                            class="other-dtl text-warning"
                            v-if="item.order_item_status == 2"
                          >
                            <strong>Order Status:</strong>
                            Refund
                          </span>

                          <span
                            class="other-dtl text-info"
                            v-if="item.order_item_status == 3"
                          >
                            <strong>Order Status:</strong>
                            Exchange
                          </span>
                          <span
                            class="other-dtl text-danger"
                            v-if="item.order_item_status == 6"
                          >
                            <strong>Order Status:</strong>
                            Cancel
                          </span>
                          <span
                            class="other-dtl text-success"
                            v-if="item.order_item_status == 7"
                          >
                            <strong>Order Status:</strong>
                            Delivered
                          </span> -->


                        </div>
                      </div>
                    </div>

                  </li>
                </ul>
              </div>
            </div>

            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <button type="button" class="page-link" v-if="page != 1" @click="page--">
                    Previous
                  </button>
                </li>
                <li class="page-item">
                  <button type="button" class="page-link" v-for="(pageNumber, index) in pages.slice(
                    page - 1,
                    page + 5
                  )" @click="page = pageNumber" :key="index">
                    {{ pageNumber }}
                  </button>
                </li>
                <li class="page-item">
                  <button type="button" @click="page++" v-if="page < pages.length" class="page-link">
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
 
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  name: "orders",
  data() {
    return {
      user_info: "",
      orders: "",
      posts: [""],
      page: 1,
      perPage: 9,
      pages: [],
    };
  },
  methods: {
    logout() {
      history.go(-1);
      // sessionStorage.setItem("token", "");
      // this.$router.push("/");
    },
    getPosts(userInfoid) {
      axios
        .get("/api/user-orders/" + userInfoid, {})
        .then((res) => {
          this.orders = res.data.datalist;
          this.orders.forEach((doc) => {
            this.posts.push(doc);

          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    setPages() {
      let numberOfPages = Math.ceil(this.posts.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return posts.slice(from, to);
    },
    // async getAddr(userInfoid) {
    //   await axios
    //     .get("/api/user-orders/" + userInfoid, {})
    //     .then((res) => {

    //       this.orders = res.data.datalist;
    //       console.log(this.orders);
    //     })
    //     .catch((err) => {
    //       console.log(err.response);
    //     });
    // },
    // async list(page = 1) {
    //     await axios
    //     .get(`/api/user-orders/32?page=${page}`)
    //     .then((res) => {
    //        console.log('testali');
    //        console.log(res.data);
    //       this.orders = JSON.parse(res.data);

    //       console.log(this.orders);
    //     })
    //     .catch(({ response }) => {
    //       console.error(response);
    //     });

    //     },

    orderitem(order_id) {
      this.$router.push({
        name: "/Dashboard/Orderitem",

        params: { oredr_id: order_id },
      });
    },
    invoice(order_id) {
      let routeData = this.$router.resolve({
        name: "Invoice",
        query: { oredr_id: order_id },
      });
      window.open(routeData.href, '_blank');
    },

    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
    },
  },

  async created() {
    var token = localStorage.getItem("token");
    await axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;

        this.getPosts(this.user_info.id);
      })
      .catch((err) => {
        this.$router.push("/login");
        console.log(err.response);
      });
  },
  computed: {

    displayedPosts() {
      return this.paginate(this.orders);
    },
  },

  watch: {
    posts() {
      this.setPages();
    },
  },
};
</script>
<style scoped>
button.page-link {
  display: inline-block;
}

button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}

.offset {
  width: 500px !important;
  margin: 20px auto;
}

button.page-link {


  padding: 10px 20px;

  font-size: 14px;
  display: inline-block;

  font-weight: 800;

}

.card-header:first-child {
  border-radius: calc(0.37rem - 1px) calc(0.37rem - 1px) 0 0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
}


/* order status  */

.hh-grayBox {
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}

.pt45 {
  padding-top: 45px;
}

.order_tracking {
  text-align: center;
  width: 33.33%;
  position: relative;
  display: block;
}

.order_tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #AFAFAF;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order_tracking .is-complete:after {
  display: block;
  position: absolute;
  content: '';
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.order_tracking.completed .is-complete {
  border-color: #27aa80;
  border-width: 0px;
  background-color: #27aa80;
}
.order_tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.order_tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}

.order_tracking p span {
  font-size: 14px;
}

.order_tracking.completed p {
  color: #000;
}

.order_tracking::before {
  content: '';
  display: block;
  height: 3px;
  width: calc(100% - 40px);
  background-color: #f7be16;
  top: 13px;
  position: absolute;
  left: calc(-50% + 20px);
  z-index: 0;
}

.order_tracking:first-child:before {
  display: none;
}

.order_tracking.completed:before {
  background-color: #27aa80;
}







.order_tracking .is-cancle {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #AFAFAF;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order_tracking .is-cancle:after {
  display: block;
  position: absolute;
  content: '';
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.order_tracking.completed .is-cancle {
  border-color: #df0909;
  border-width: 0px;
  background-color: #df0909;
}
.order_tracking.completed .is-cancle:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
</style>
