<template>
<div class="home">
 
<Invoiceorder/>
 
    </div>
</template>

<script>
 
import Invoiceorder from "../components/Dashboad/Invoiceorder.vue"
 
export default {
    name: "Orderdetailitem",
    components: {
        
        Invoiceorder,
        
        }
}
</script>