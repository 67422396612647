<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="profile-info">
                  <!-- <div class="pro-img">
                    <img src="images/user.jpg" alt="" />
                  </div> -->
                 <div class="pro-info">
                    <h3>{{ user_info.name }}</h3>
                    <h4>+91{{ user_info.phone }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="logout text-end">
                  <a href="javascript:void(0)" @click="logout()"
                    >Back<i class="mdi mdi-arrow-u-left-top"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr />

            <div class="dash-tab-cover">
              <div class="row g-3 ">
                <div class="col-md-4 col-4 mb-2 mb-lg-4">
                <router-link to="/Dashboard/profile">
                  <div class="dash-tabe-over">
                    <div class="dash-tab">
                      <i class="mdi mdi-account"></i>
                      <h3>Profile</h3>
                    </div>
                  </div>
                </router-link>
               
              </div>
                <div class="col-md-4 col-4 mb-2 mb-lg-4">
                  <router-link to="/Dashboard/Orderdetails">
                    <div class="dash-tabe-over">
                      <div class="dash-tab">
                        <i class="mdi mdi-order-bool-ascending-variant"></i>
                        <h3>Order</h3>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-4 mb-2 mb-lg-4">
                  <router-link to="">
                    <div class="dash-tabe-over">
                      <div class="dash-tab">
                        <i class="mdi mdi-message-draw"></i>
                        <h3>Review</h3>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-4 col-4 mb-2 mb-lg-4">
                  <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#wish-mpdel"
                    >
                    <div class="dash-tabe-over">
                      <div class="dash-tab">
                        <i class="mdi mdi-heart"></i>
                        <h3>Wishlist</h3>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- <div class="col-md-4 mb-4">
                <router-link to="/profile">
                  <div class="dash-tabe-over">
                    <div class="dash-tab">
                      <i class="mdi mdi-account"></i>
                      <h3>Profile</h3>
                    </div>
                  </div>
                </router-link>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  data() {
    return {
      user_info:'',
      
    };
  },
  methods: {
    logout() {
      history.go(-1);
      // sessionStorage.setItem("token", "");
      // this.$router.push("/");
    },
  },
   async created() {
     
    var token = localStorage.getItem("token");
    await axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
        })
      .catch((err) => {
        this.$router.push("/login");
        console.log(err.response);
      });


  },
};
</script>