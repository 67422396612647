<template >
    <div class="breadcum-cart">
        <h2>Shoping Cart</h2>
<ul>
    <li class="" :class="third === 'Cart' ? 'active': ''">
        <div class="number">
            01
        </div>
        <div class="text-check">
            <h3>Shoping Cart</h3>
            <p>Manage Your Items List</p>
        </div>
    </li>
    <li class="" :class="third === 'Checkout' ? 'active': ''">
        <div class="number">
            02
        </div>
        <div class="text-check">
            <h3>Checkout Details</h3>
            <p>Checkout Your Items List</p>
        </div>
    </li>
    <li>
        <div class="number">
            03
        </div>
        <div class="text-check">
            <h3>Order Complete</h3>
            <p>Review Your Order</p>
        </div>
    </li>
</ul>
    </div>
</template>
<script >

export default {
     data() {
        return {
           second:'',
           third:"",
           fourth:"",
           five:"",
           six:"",
           uri:"",
          // title: (this.$route.params.sub == ''?this.$route.params.cat:this.$route.params.sub)
        }
    },
   created() {


    let uri = window.location.href.split("/");
    this.second=uri[3];
    this.third=uri[3];
    this.fourth=uri[4];
    this.five=uri[5];
    this.six=uri[6];
    this.uri=uri;
  
  console.log(this.third);
    

    // console.log(uri[0]);
    // console.log(uri[1]);
    // console.log(uri[3]);
    // console.log('Productlist'+uri[3]);
    // console.log(uri.length);
    // console.log(uri[5]);

    },
}
</script>

<style>
.breadcum-cart {       background-color: #f8f8f8;
    padding: 4px 0px;
    text-align: center;
    padding: 4px 0px;}
    .breadcum-cart h2 {display: none;}
.breadcum-cart ul{       padding: 0px 0px;
    display: flex;
    justify-content: center;
    margin: 0;}
.breadcum-cart ul li{list-style: none;
    display: flex;
    width: 22%;
    align-items: center;
    justify-content: center;
    color: #c8c8c8; transition: 0.3s; }
    .breadcum-cart ul li.active{
    color: #000000;}
        .breadcum-cart ul li:hover{
    color: #000000;}
.breadcum-cart ul li .number{    font-size: 60px;
    font-weight: 700;}
.breadcum-cart ul li .text-check{margin-left: 9px;}
.breadcum-cart ul li .text-check h3{    margin: 0;
    text-transform: uppercase;
    font-size: 22px;}
.breadcum-cart ul li .text-check p{    margin: 0;}

@media only screen and (max-width: 980px) {
.breadcum-cart ul {display: none;}
.breadcum-cart h2 {    display: block;
    margin: 0;
    font-size: 17px;
    padding: 12px 0px;
    font-weight: 700;}

}

</style>