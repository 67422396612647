import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/view/Home'
import Productlist from '@/view/Productlist'
import Productdetail from '@/view/Productdetail'
import Newarrivalproductlist from '@/view/Newarrivalproductlist'

import Search from '@/view/Search'

import Cart from '@/view/Cart'
import Wishlist from '@/view/Wishlist'
import Checkout from '@/view/Checkout'
// import Payuform from '@/view/Payuform'
import Payusuccess from '@/view/Payusuccess'
import Razorpay from '@/view/Razorpay'
import Payufailure from '@/view/Payufailure'
 

import Login from '@/view/Login'
import Otp from '@/view/Otp'
 
import Registration from '@/view/Registration'
import Forgotpassword from '@/view/Forgotpassword'
import Forgotpasswordverify from '@/view/Forgotpasswordverify'
import Resetpassword from '@/view/Resetpassword'
import Dashboard from '@/view/Dashboard'
import Orderdetails from '@/view/Orderdetails'
import Thank from '@/view/Thank'
import Orderdetailitem from '@/view/Orderdetailitem'
import Invoice from '@/view/Invoice'
 

import Profile from '@/view/Profile'
import Faq from '@/view/Faq'
import Privacypolicy from '@/view/Privacypolicy'
import Caution from '@/view/Caution'
import Termandcondition from '@/view/Termandcondition'
import Aboutus from '@/view/Aboutus'
import Returnandexchange from '@/view/Returnandexchange'
import Order from '@/view/Order'
import Shippingandpolicy from '@/view/Shippingandpolicy'
import Refund from '@/view/Refund'
import Contactus from '@/view/Contactus'
import Filter from '@/view/Filter'



Vue.use(Router)
export default new Router({
  
    mode: 'history',
   
    routes: [
      { 
        path: '/', component: Home ,
      },
      { path: '/Filter/', component: Filter },
      { path: '/Productlist/:cat*/:sub*/:subsub*', component: Productlist },     
     
      { path: '/Productdetail/:prodName', component: Productdetail },

      { path: '/Search/:string', component: Search },

      { path: '/Cart', component: Cart },
      { path: '/Wishlist', component: Wishlist },
      { path: '/Checkout', component: Checkout },
      // { path: '/Payuform', component: Payuform },
      { path: '/Login', component: Login },
      { path: '/Otp', name:"Otp", component: Otp },
      
      
      { path: '/Registration', component: Registration },
      { path: '/Forgotpassword', component: Forgotpassword },
      { path: '/Forgotpasswordverify', component: Forgotpasswordverify },
      { path: '/Resetpassword', component: Resetpassword },
      { path: '/Dashboard', component: Dashboard },
      { path: '/Dashboard/Orderdetails', component: Orderdetails },
      // { path: '/Orderitem/:id', component: Orderdetailitem },
      {

        path: '/Dashboard/Orderitem',
        name: "/Dashboard/Orderitem",
        component : Orderdetailitem },
        {
           path: '/Invoice/',
           name: "Invoice",
           component : Invoice

        },
        { path: '/Thankyou', name:"Thankyou", component: Thank },


      { path: '/Dashboard/Profile', component: Profile },
      { path: '/Faq', component: Faq },
      { path: '/Privacypolicy', component: Privacypolicy },
      { path: '/Caution', component: Caution },
      { path: '/Termandcondition', component: Termandcondition },
      { path: '/Aboutus', component: Aboutus },
      { path: '/Returnandexchange', component: Returnandexchange },
      { path: '/Order', component: Order},
      { path: '/Shippingandpolicy', component: Shippingandpolicy },
      { path: '/Refund', component: Refund },
      { path: '/Contactus', component: Contactus },
      {
        path: '/Payuform',
        name: "Payuform", component: () =>
            import("../view/Payuform.vue")
      },
     { path: '/Razorpay', component: Razorpay },
     { path: '/Payusuccess', component: Payusuccess },
     { path: '/Payufailure', component: Payufailure },
     { path: '/Newarrivalproductlist', component: Newarrivalproductlist },
    ]
})