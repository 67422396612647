<template>
    <div class="home">
        <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <Header v-if="this.categories.length > 0" :categories="categories" :srhproducts="srhproducts"/>
        <Homefirstsection v-if="this.categories.length > 0" :categories="categories"  :slider="slider" :offer="offer"/> 
        <Homeexclusive/>
        <Homefiltersection />
        <Homeservice />
        <Footer v-if="this.categories.length > 0" :categories="categories"/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Homefirstsection from "../components/Homefirstsection.vue"
import Homeservice from "../components/Homeservice.vue"
import Homefiltersection from "../components/Homefiltersection.vue"

import Homeexclusive from "../components/Homeexclusive.vue"
// import Homeallproduct from "../components/Homeallproduct.vue"
// import Homefollow from "../components/Homefollow.vue"
import Footer from "../components/Footer.vue"
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    name: "Home",
    components: {
        Header,
        Homefirstsection,
        Homeservice,
        Homefiltersection,
        Homeexclusive,
        Footer,
    },
    data() {
        return {
            isLoading:true,
            categories: '', 
            slider:[],
            offer:[],
            trendingProducts:'',
            featuredProducts:'',
            best_sellings:'',
            top_rateds:'',
            allProducts:'',
            srhproducts:[],
        }
    },
  
    async  created() {
        await axios.get('/api/homecategory', {})
        .then((res) => {
            this.categories=res.data.data;
             
            })
        .catch(err => {
            console.log(err.response);
        });
        
    axios
      .get("/api/product-list", {})
      .then((res) => {
        this.searchproducts = res.data.data;
        this.searchproducts .forEach((doc) => {
        this.srhproducts.push(doc);
        this.isLoading = false;
        });
      })
      .catch((err) => {
        console.log(err.response);
      });

      await  axios
      .get("/api/slider-banner", {})
      .then((res) => {
        this.slider = res.data.data; 
      })
      .catch((err) => {
        console.log("gbjgjg",err.response);
      });
      await  axios
      .get("/api/offer-banner-link", {})
      .then((res) => {
        this.offer = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });


      
        // axios.get('/api/product-list/featured', {}) // all featured
        // .then(res => {
        //     this.featuredProducts=res.data.data;
        //     console.log(this.featuredProducts);
        //     })
        // .catch(err => {
        //     console.log(err.response);
        // });
        // axios.get('/api/product-list/best_selling', {}) // all best_selling
        // .then(res => {
        //     this.best_sellings=res.data.data;
        //     console.log(this.best_sellings);
        //     })
        // .catch(err => {
        //     console.log(err.response);
        // });
        // axios.get('/api/product-list/top_rated', {}) // all  top_rated
        // .then(res => {
        //     this. top_rateds=res.data.data;
        //     console.log(this.top_rateds);
        //     })
        // .catch(err => {
        //     console.log(err.response);
        // });
        // axios.get('/api/product-list/trending', {}) // all  trendingProducts
        // .then(res => {
        //     this. trendingProducts=res.data.data;
        //     console.log(this.trendingProducts);
        //     })
        // .catch(err => {
        //     console.log(err.response);
        // });

    },
 
}

</script>