<template>
  <div class="footer-section">
    <div class="foot-up">
      <div class="container">
        <div class="row g-0">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="first-info">
                  <div class="logo">
                    <router-link to="/">
                      <img src="/images/logo.png" alt="" />
                    </router-link>
                  </div>
                  <p>Kabir Collections</p>
                  <p>
                    B-1/8, Krishna Nagar, Delhi-51(India)
                  </p>
                  <!-- <a href="mailto:support@kabircollections.in">support@kabircollections.in</a>
                  <a href="tel:+91-9971010391">+91-9971010391</a> -->
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="social-section">
                  <a href="https://twitter.com/Kabir-Collection" target="blank" class="nasa-tip">
                    <i class="fa fa-twitter"></i>
                    <span class="nasa-tip-content">Follow us on Twitter</span>
                  </a>
                  <a href="https://www.facebook.com/Kabir-Collection/" target="blank" class="nasa-tip">
                    <span class="nasa-tip-content">Follow us on facebook</span><i class="fa fa-facebook"></i></a>
                  <a href="https://www.instagram.com/Kabir-Collection/" target="blank" class="nasa-tip">
                    <span class="nasa-tip-content">Follow us on instagram</span><i class="fa fa-instagram"></i></a>
                  <a href="https://t.me/Kabir-Collection" target="blank" class="nasa-tip">
                    <span class="nasa-tip-content">Follow us on telegram</span><i class="fa fa-telegram"></i></a>
                </div>
                <div class="links">
                  <h3>Hot Sellers</h3>
                  <ul>
                    <li v-for="category in categories" :key="category.id">
                      <a href="javascript:void(0)" @click="goProductCat(category.slug)">{{ category.title }}</a>
                    </li>
                    <li>
                      <router-link to="/Order"> How To Order </router-link>
                    </li>
                    <li>
                      <router-link to="/Caution"> Caution </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- <div class="subscribe-set">
              <label htmlFor="">Newsletter</label>
              <input
                type="text"
                v-model="subscriveemail"
                placeholder="Enter your email here"
                class="form-control"
              />
              <button type="button" @click="subscribeemail()">Subscribe</button>
            </div> -->
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="links">

                  <ul>
                    <li>
                      <router-link to="/AboutUs"> About Us </router-link>
                    </li>
                    <li>
                      <router-link to="/Contactus"> Contact Us</router-link>
                    </li>
                    <li>
                      <router-link to="/Termandcondition"> Terms & Conditions </router-link>
                    </li>
                    <li>
                      <router-link to="/Returnandexchange"> Return & Exchange Policy </router-link>
                    </li>
                    <!-- <li>
                      <router-link to="/Faq"> FAQS </router-link>
                    </li>-->
                    <!--<li>
                      <router-link to="/refund"> Refund Policy </router-link>
                    </li>-->
                    <li>
                      <router-link to="/Privacypolicy"> Privacy Policy </router-link>
                    </li>
                    <!-- <li>
                      <router-link to="/Shippingandpolicy"> Shipping & Policy </router-link>
                    </li>-->
                   

                  </ul>
                </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <div class="timing">
                  <h3>Customer Care</h3>
                  <ul>
                    <li>
                      <a href="mailto:support@kabircollections.com">support@kabircollections.com</a>
                     <div class="d-flex">
                      Helpline: &nbsp;<a href="tel:+91-9971010391">+919971010391</a>
                     </div>
                     <div class="d-flex">
                      Phone: &nbsp;<a href="tel:+91-9971010391"> 011-45150391</a>
                     </div>
                    </li>
                    <!-- <li>
                      <span>Monday - Friday</span>
                      <span>08:00 - 20:00</span>
                    </li>
                    <li>
                      <span>Saturday</span>
                      <span>09:00 - 21:00</span>
                    </li>
                    <li>
                      <span>Sunday</span>
                      <span>13:00 - 22:00</span>
                    </li> -->
                    <li>
                      <span></span>
                      <span>
                        <br />
                        <img src="/images/payment-icons.png" alt="" /></span>
                    </li>
                    <li>

                      <div class=" whats-app">
                        <a href="https://wa.me/+919971010391?text=Hi " target="blank">
                          <img src="/images/whts-ic.png" alt="whatsapp" />
                          <span>WhatsApp Us</span>
                        </a>

                      </div>


                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="footer-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-3">
            <p>© 2024 <strong>Kabir Collections</strong> | All Right reserved</p>
          </div>
          <div class="col copy-links">
            <router-link to="/Faq"> FAQS </router-link>
            <router-link to="/Caution"> Caution </router-link>
            <router-link to="/Privacypolicy"> Privacy Policy </router-link>
            <router-link to="/Shippingandpolicy">
              Shipping & policy
            </router-link>
            <router-link to="/Termandcondition">
              Terms & Conditions
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="cart-pop">
      <div class="modal fade" id="cart-mpdel" tabindex="-1" aria-labelledby="cart-mpdel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content" v-if="getitems.length > 0">
            <div class="empty-cart" v-if="getitems.length > 0">
              <div class="modal-header">
                <h5 class="modal-title" id="cart-mpdel">

                  My Cart ({{ getitems.length }})
                </h5>
                <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div class="cart-content">
                <ul>

                  <li v-for="(obj, key) in getitems" :key="key">
                    <div class="cart-item">
                      <div class="img-set">
                        <img :src="$baseURL +
                          '/public/images/product/original/' +
                          obj.image[0].product_image
                          " alt="" />
                      </div>
                      <div class="text-show">
                        <a href="javascript:void(0)" @click="productdetail(obj.product.slug)">{{ obj.product.name }} -
                        </a>
                        <span class="size-sh">Size: {{ obj.size_name }}</span>

                        <div class="def-number-input">

                          <input type="text" class="ad-qty" :value="obj.qty"  />
                          <span class="ad-btn" @click="Updatedecriment(obj)">-</span>
                          <span class="ad-btn" @click="addToCartlogupdate(obj)">+</span>
                        </div>
                        <span class="price">
                          <i class="mdi mdi-close"></i>
                          {{
                            (parseInt(obj.offer_price.offer_price) + parseInt(obj.variablevalue != null ? obj.variablevalue
                              : 0)) *
                            parseInt(obj.qty)
                          }}
                        </span>
                      </div>


                      <div class="remove-bt">
                        <button @click="getitedeletFrocar(obj)">
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                  <hr />
                </ul>
              </div>

              <div class="cart-footer">
               
                <div class="sub-total">
                  <span>Subtotal</span>
                  <span>₹{{ this.$store.state.total }}</span>
                </div>

                <button class="view-cart" @click="gotoCart()">view-cart</button>
                <button class="checkout" @click="proceedtoCheckout()">checkout</button>
              </div>
            </div>

            <div class="cart-empty" v-else>
              <div class="img-set">
                <img :src="$homeBaseURL + '/images/empty-cart.png'" />
              </div>
            </div>
          </div>

          <div class="modal-content" v-else>
            <div class="modal-header">
              <h5 class="modal-title" id="cart-mpdel">
                My Cart ({{ cart.length }})
              </h5>
              <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="empty-cart" v-if="cart.length > 0">

              <div class="cart-content">
                <ul>
                  <li v-for="(obj, key) in cart" :key="key">
                    <div class="cart-item">
                      <div class="img-set">
                        <img :src="$baseURL +
                          '/public/images/product/original/' +
                          obj.product_image
                          " alt="" />
                      </div>
                      <div class="text-show">
                        <a href="javascript:void(0)" @click="productdetail(obj.slug)">{{ obj.product_name }} -
                          <span>{{ obj.sizeName }}</span></a>
                        <div class="def-number-input">
                          <input type="text" class="ad-qty" :value="obj.quantity" disabled />
                          <span class="ad-btn" @click="removeFromCart(obj.size_id)">-</span>
                          <span class="ad-btn" @click="addToCart(obj)">+</span>
                        </div>
                        <span class="price">
                          <i class="mdi mdi-close"></i>
                          {{
                            parseInt(obj.offer_price) * parseInt(obj.quantity)
                          }}
                        </span>
                      </div>
                      <div class="remove-bt">
                        <button @click="deleteFromcart(key)">
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                  <hr />
                </ul>
              </div>

              <div class="cart-footer">
                <div class="sub-total">
                  <span>Subtotal</span>
                  <span>₹{{ subtotalPrice }}</span>
                </div>
                <button class="view-cart" @click="gotoCart()">view-cart</button>
                <button class="checkout" @click="proceedtoCheckout()">checkout</button>
              </div>
            </div>
            <div class="cart-empty" v-else>
              <div class="img-set">
                <img :src="$homeBaseURL + '/images/empty-cart.png'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="cart-pop">
      <div class="modal fade" id="wish-mpdel" tabindex="-1" aria-labelledby="wish-mpdel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="wish-mpdel">
                wishlist
              </h5>
              <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="empty-cart" v-if="this.$store.state.getwishlist.length > 0">


              <div class="cart-content">
                <ul>
                  <li v-for="(obj, key) in this.$store.state.getwishlist" :key="key">
                    <div class="cart-item">
                      <div class="img-set">
                        <img :src="$baseURL +
                          '/public/images/product/original/' +
                          obj.product_image.product_image
                          " alt="" />
                      </div>
                      <div class="text-show">
                        <a href="javascript:void(0)" @click="productdetail(obj.product.slug)">{{ obj.product.name }} -
                          <span>{{ obj.product_size }}</span></a>

                        <span class="price">
                          ₹
                          {{ parseInt(obj.product.offer_price) }}
                        </span>
                      </div>
                      <div class="remove-bt">
                        <button @click="wishlistdelete(obj)">
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                  <hr />
                </ul>

              </div>
              <div class="cart-footer">

                <button class="view-cart" @click="gotoWishlist()">
                  Go to wishlist
                </button>
              </div>
            </div>
            <div class="cart-empty" v-else>
              <div class="img-set">
                <img :src="$homeBaseURL + '/images/empty-cart.png'" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



    
  </div>
</template>
<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script> -->
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  props: ['categories'],
  data() {
    return {
      user_info: "",
      user_cart_item: "",
      subscriveemail: "",
    };
  },
  methods: {
    goProductCat(catName) {
      this.$router.push("/Productlist/" + catName);
      location.reload();
    },
    removeFromCart(item) {
      this.$store.commit("removeFromCart", item);
      this.flash("Product Quantity removed", "error", {
        timeout: 3000,
        beforeDestroy() { },
      });
    },
    addToCart(product) {
      console.log('product',product.product_sizes);
      product.product_sizes.forEach((doc) => {
        if (doc.size_id == product.size_id) {
          this.stock = doc.stock;
          console.log(this.stock);
        }
      });



      if (this.stock > product.quantity) {
        this.$fire({
          title: "quantity added ",
          type: "success",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        //return false;
      }
      else {
        this.$fire({
          title: "Not Available In Stock",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }



      this.$store.commit("addToCart", product);
      // this.flash("Product Quantity Added", "success", {
      //   timeout: 3000,
      //   beforeDestroy() {
      //     //alert('oh no, not again!');
      //   },
      // });
    },
    deleteFromcart(index) {
      this.$store.commit("deleteFromcart", index);
    },
    gotoCart() {
      //$('#couponpop').modal('hide')
      this.$router.push("/Cart");
      location.reload();

    },

    gotoWishlist() {
      this.$router.push("/Wishlist");
      location.reload();

    },
    Updatedecriment(product) {
      if (product.qty > 1) {
        const addtocartdata = {
          user_id: product.user_id,
          product_id: product.product_id,
          size_id: product.size_id,
          status: product.status,
          qty: product.qty - 1,
        };
        axios
          .post("/api/update-cart", addtocartdata)
          .then((res) => {
            console.log(res);
            this.$store.commit("getusercartitem", product.user_id);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
    addToCartlogupdate(product) {
      product.product_sizes.forEach((doc) => {
        
        if (doc.size_id == product.size_id) {
          console.log('doc',doc)
          this.stock = doc.stock;
        }
      });


      // if (this.stock > product.qty) {
      //   this.$fire({
      //     title: "quantity added ",
      //     type: "success",
      //     timer: 5000,
      //   }).then((r) => {
      //     console.log(r.value);
      //   });
      // }
      // else {
      //   this.$fire({
      //     title: "Not Available In Stock",
      //     type: "error",
      //     timer: 5000,
      //   }).then((r) => {
      //     console.log(r.value);
      //   });
      //   return false;
      // }


      const addtocartdata = {
        user_id: product.user_id,
        product_id: product.product_id,
        size_id: product.size_id,
        status: product.status,
        qty: product.qty + 1,
      };


      console.log(addtocartdata);
      axios
        .post("/api/update-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", product.user_id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getitedeletFrocar(product) {
      axios
        .get("/api/cart-item-delete/" + product.user_id + "/" + product.id, {})
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", product.user_id);
        })
        .catch((err) => {
          console.log(err.response);
        });

      // this.$store.commit("deleteFromcart", index);
    },
    wishlistdelete(product) {
      axios
        .get("/api/wish-list-delete/" + product.id, {})
        .then((res) => {
          console.log(res);
          this.$store.commit("getuserwislist", product.user_id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
      window.location.reload();
    },
    subscribeemail() {
      if (this.subscriveemail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(this.subscriveemail) == true) {
          // return true;
        } else if (re.test(this.subscriveemail) == false) {
          this.$fire({
            title: "email Not Valid",
            type: "error",
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
          return false;
        }
      }

      const storedata = {
        subscribeemail: this.subscriveemail,
      };
      axios
        .post("/api/add-to-news-letter", storedata)
        .then((res) => {
          console.log(res.status);
          if (res.status == "200") {
            this.$fire({
              title: res.data.message,
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            this.$fire({
              title: res.data.message,
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    proceedtoCheckout() {


      this.$router.push("/Checkout");
      window.location.reload();
    },
  },

  computed: {
    cart: function () {
      return this.$store.state.cart;
    },
    subtotalPrice() {
      let total = 0;
      for (let item of this.$store.state.cart) {
        total += item.quantity * item.offer_price;
      }
      return total.toFixed(2);
    },
    getitems: function () {
      return this.$store.state.getitems;
    },
    getitemssubtotalp() {
      let total = 0;
      for (let item of this.$store.state.getitems) {
        total += item.qty * item.product.offer_price;
      }
      return total.toFixed(2);
    },

    // getwishlist: function () {
    //   return this.$store.state.getwishlist;
    // },
  },


};
</script>
<style>
.whats-app {


  background-color: #e4e4e4;
  color: #7f7d7d;
  border-radius: 25px;
  font-size: 13px;
  line-height: 27px;
  width: 70%;
}

.whats-app a {
  display: flex !important;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;

}

.whats-app img {

  width: 26%;
  /* display: table-cell; */
  height: auto;
  position: absolute;
  left: 0;
}

.whats-app span {
  padding: 0px 0px 0px 43px;
}
</style>