<template>
  <div class="myaccount-set">
    <div class="container">
      <div class="myaccount-set-in">
        <div class="row justify-content-center">
          <div class="col-md-6 form-cover">
            <h3>Login/Register</h3>
             <flash-message class="myCustomClass"></flash-message>
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <span class="ico mdi mdi-at"></span>
                    <input
                      type="text"
                      required
                      v-model="email"
                      class="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <span class="ico mdi mdi-account-outline"></span>
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Phone No."
                      v-model="phone"
                      maxlength="12"
                      minlength="10"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <span class="ico mdi mdi-key-variant"></span>
                    <input
                      required
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="password"
                       minlength="6"
                    />
                    
                  </div>
                  <span v-if="errorMsg.password" class="errorMsg alartting">{{errorMsg.password}}</span>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <span class="ico mdi mdi-key-variant"></span>
                    <input
                      required
                      type="password"
                      class="form-control"
                      placeholder="Confirm Password"
                      v-model="repassword"
                      minlength="6"
                    />
                  
                  </div>
                     <span v-if="errorMsg.repassword" class="errorMsg alartting">{{errorMsg.repassword}}</span>
                </div>
                <div class="col-md-12">
                  <div class="reminder">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked
                      />
                      <label
                        class="form-check-label"
                        checked
                        for="flexCheckChecked"
                      >
                        I accept the
                        <a href="#" >Terms of Service and Privacy Policy</a>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="submit">
                    <button>Sign Up</button>
                  </div>
                  <span class="extra-action">
                    Already User
                    <router-link to="/Login">Login Here</router-link>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      phone: "",
      email: "",
      password: "",
      repassword: "",
      message: "",
      errorMsg: [],
    };
  },
  watch: {
    password(newVal){
      this.password = newVal;
      this.validatePassword(newVal);
    },
     repassword(newVal){
      this.repassword = newVal;
      this.validateConfirmPwd(newVal);
    }
  },
  methods: {
    validateConfirmPwd(newVal){

       let difference = this.password;
      if (newVal !== difference) {
        this.errorMsg['repassword'] = 'The password confirmation does not match.' ;
      } else {
         this.errorMsg['repassword'] = '';
      }

    },
    validatePassword(newVal){
       let difference = 6 - newVal.length;
      if (newVal.length<6) {
        this.errorMsg['password'] = 'Must be 6 characters! '+ difference + ' characters left' ;
      } else {
         this.errorMsg['password'] = '';
      }
    },
    handleSubmit() {
      if (!this.phone) {
        this.flash("Please Enter Phone No", "warning", {
          timeout: 500000,
          beforeDestroy() {},
        });
        return false;
      }
      if (!this.email) {
        this.flash("Please Enter Your Email", "error", {
          timeout: 500000,
          beforeDestroy() {},
        });
        return false;
      }
      if (!this.password) {
        this.flash("Please Enter Password", "error", {
          timeout: 500000,
          beforeDestroy() {},
        });
        return false;
      }
      if (this.password != this.repassword) {
        this.flash("Your Retype Password Not Match", "error", {
          timeout: 500000,
          beforeDestroy() {},
        });
        return false;
      }
      
      const userData = {
        phone: this.phone,
        email: this.email,
        password: this.password,
      };

      axios
        .post("/api/signup", userData)
        .then((res) => {
          console.log(res.data.status);
          if(res.data.status == 0){
            this.message = res.data.message.original.email[0];
          }else{
            this.message = res.data.message;
          }
           
          if (res.data.status == 1) {
    
            this.$fire({
              title: "Bwitchedzone registration",
              text: this.message,
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
            this.$router.push("/login");
          } else {
            this.$fire({
              title: "Bwitchedzone registration failed!",
              text: this.message,
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>