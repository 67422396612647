<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row justify-content-between">
              <div class="col-md-4">
                  <div class="pro-info">
                    <h3>Payment Success </h3>
                    </div>
                    <button id="rzp-button1" @click="rozarpayclick()">Checkout</button>
                </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
 
<script>
 
export default {
  data() {
    return {};
  },

  methods:{
    rozarpayclick(){

    
    var options = {
    "key": "rzp_test_TT6oC24nkbnFqv", // Enter the Key ID generated from the Dashboard
    "amount": "100", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Acme Corp",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    "order_id": "order_JaCxf4SuV9fIoM", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response){
        console.log('response');
        console.log(response);

        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
    },
    "prefill": {
        "name": "Gaurav Kumar",
        "email": "gaurav.kumar@example.com",
        "contact": "9999999999"
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#3399cc"
    }
};
 
var rzp1 = new Razorpay(options);

rzp1.on('payment.failed', function (response){
        console.log('payment_response');
        console.log(response);

        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
});
document.getElementById('rzp-button1').onclick = function(e){
    rzp1.open();
    e.preventDefault();
}


    }
  },
  
};

</script>