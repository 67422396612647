<template>
  <div class="product-list-page">
      <div class="container" id="people">
	<div class="filter">
		<label><input type="radio" v-model="selectedCategory" value="All" /> All</label>
		<label v-for="brd in brand" :key="brd.id">
            <input type="radio" v-model="selectedCategory" :value="brd.id" /> {{brd.name}}
        </label>
	</div>
	
	<ul class="people-list">
		<li v-for="person in filteredPeople" :key="person.id">{{person.id}}-{{ person.name }}</li>
	</ul>{{products}}
</div>
  </div>
</template>

<script>
export default {
    props:["products"],
data(){
    return{
        brand: [
			{ name: "AGNI", id: "1" },
			{ name: "LIVICE", id: "2" },
			{ name: "ZARA", id: "3" },
		],
		selectedCategory: "All",
        people:this.products
    }
},
	computed: {
		filteredPeople: function() {
			var vm = this;
			var category = vm.selectedCategory;
			
			if(category === "All") {
				return vm.people;
			} else {
				return vm.people.filter(function(person) {
                    console.log(person.brand_id);
					return parseInt(person.brand_id) === parseInt(category);
				});
			}
		}
	}
};
</script>

<style>

</style>