<template>
  <div class="home-fisrt-section">
    <div class="main_slider" v-if="slider.length > 0">
      <carousel :items="1" :nav="false" >
        <div class="item animate__fadeIn" v-for="(sld, index) in slider" :key="index">
          <!-- <img :src="$homeBaseURL + '/images/main-slider/slider1.jpg'" class="web-sld"/> -->
          <a :href="sld.url">
            <img :src="$baseURL + '/public/images/banners/' + sld.image" class="web-sld" />
          </a>
          <a :href="sld.mobile_url">
            <img :src="$baseURL + '/public/images/banners/' + sld.mobile_image" class="mob-sld" />
          </a>
          <!-- <img :src="$homeBaseURL + '/images/main-slider/mob-slide.jpg'" class="mob-sld" /> -->
        </div>
      </carousel> 
    </div>

    <!-- <div class="main_slider" v-else>
      <carousel :items="1" :nav="false" >
        <div class="item animate__fadeIn">
          <img :src="$homeBaseURL + '/images/main-slider/slider1.jpg'" class="web-sld"/>
          
          <img :src="$homeBaseURL + '/images/main-slider/mob-slide.jpg'" class="mob-sld" />
        </div>
      </carousel> 
    </div> -->
    
    
    <!-- <carousel :items="1" :nav="false" v-else>
      <div class="item animate__fadeIn">
        <img :src="$homeBaseURL + '/images/main-slider/slider1.jpg'" class="web-sld"/>
      </div>

    </carousel> -->
    <!-- {{ offer }} -->



    <div class="offer-thumbnil" v-if="offer.length > 0">

      <div class="container">
        <div class="row g-4" v-for="(off, index) in offer" :key="index">
          <div class="col-md-6">
            <div class="img-box">
              <a :href="off.url1">

                <img :src="$baseURL + '/public/images/offerbanner/' + off.image1" alt="" />
              </a>
            </div>
          </div>
          <div class="col-md-6">

            <div class="row g-2 g-md-4">
              <div class="col-md-6 col-6">
                <div class="img-box">
                  <a :href="off.url2">

                    <img :src="$baseURL + '/public/images/offerbanner/' + off.image2" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <div class="img-box">
                  <a :href="off.url3">

                    <img :src="$baseURL + '/public/images/offerbanner/' + off.image3" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="img-box">
                  <a :href="off.url4">

                    <img :src="$baseURL + '/public/images/offerbanner/' + off.image4" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="offer-thumbnil" v-else>

      <div class="container">
        <div class="row g-4">
          <div class="col-md-6">
            <div class="img-box">
              <a href="javascript:void(0)" @click="goProductCat('Women/dress')">
                <img :src="$homeBaseURL + '/images/home-offer/home-mix.jpg'" alt="" />
              </a>
            </div>
          </div>
          <div class="col-md-6">

            <div class="row g-2 g-md-4">
              <div class="col-md-6 col-6">
                <div class="img-box">
                  <a href="javascript:void(0)" @click="goProductCat('Women')">
                    <img :src="$homeBaseURL + '/images/home-offer/home3.jpg'" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-6">
                <div class="img-box">
                  <a href="javascript:void(0)" @click="goProductCat('Men')">
                    <img :src="$homeBaseURL + '/images/home-offer/homeslide4.jpg'" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="img-box">
                  <a href="javascript:void(0)" @click="goProductCat('Women/dress')">
                    <img :src="$homeBaseURL + '/images/home-offer/home-blue.jpg'" alt="" />
                  </a>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {

  components: { carousel },
  name: "b-dropdown-hover",
  props: ["categories", "slider", "offer"],
  methods: {
    // goProductCat(catName) {
    //   this.$router.push("/Productlist/" + catName + "/" + " ");
    // },
    // goProductSubt(catName, subName) {
    //   alert(catName);
    //   this.$router.push("/Productlist/" + catName + "/" + subName);
    // },

    goProductCat(catName) {
      this.$router.push("/Productlist/" + catName);
      location.reload();
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
  },
};
</script>

<style>
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  top: -14px;
  left: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: none;
  pointer-events: visible;
  transform: translate(8px, 0);
  transition: all 200ms ease;
  position: absolute;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;

  z-index: 1000;
  width: 200px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
  visibility: visible;

  left: 100%;
  z-index: 9999;
  opacity: 1;

  pointer-events: auto;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.web-sld {
  display: block;
}

.mob-sld {
  display: none !important;
}

@media only screen and (max-width: 480px) {

  .web-sld {
    display: none !important;
  }

  .mob-sld {
    display: block !important;
  }
}
</style>