<template>
  <div class="home">
    <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <Header
      v-if="this.categories.length > 0"
      :categories="categories"
      :srhproducts="srhproducts"
    />
    <Secondbreadcum />
    <Cartpage />
    <Footer v-if="this.categories.length > 0" :categories="categories"/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Secondbreadcum from "../components/Secondbreadcum.vue";
import Cartpage from "../components/Cartpage.vue";
import Footer from "../components/Footer.vue";
 import Loader from "../components/Loader";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  name: "Home",
  components: {
    Header,
    Secondbreadcum,
    Cartpage,
    Footer,
    Loader,
  },
  data() {
    return {
      isLoading:true,
      categories: "",
      srhproducts: [],
     
    };
  },
  created() {
    axios
      .get("/api/homecategory", {})
      .then((res) => {
        this.categories = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });
      
      axios
      .get("/api/product-list", {})
      .then((res) => {
        this.searchproducts = res.data.data;
        this.searchproducts.forEach((doc) => {
          this.srhproducts.push(doc);
          setTimeout(() => {
              this.isLoading = false
            }, 3000)
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
   },
  };
</script>