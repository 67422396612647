<template>
  <div class="home">
    <Header />
    <Breadcrumbs />
    <Forgotpwdverify />

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Forgotpwdverify from "../components/Auth/Forgotpwdverify.vue";

import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Breadcrumbs,
    Forgotpwdverify,

    Footer,
  },
};
</script>