<template>
  <div class="Razorpaypage">
    <Razorpaypage />
  </div>
</template>
<script>
import Razorpaypage from "../components/Dashboad/Razorpaypage.vue";
export default {
  name: "Razorpaypage",
  components: {
    Razorpaypage,
  },
};
</script>