
<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import NewArrivalList from "../components/NewArrivalList.vue";
import Footer from "../components/Footer.vue";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    name: "Home",
    components: {
        Header,
        Breadcrumbs,
        NewArrivalList,
        Footer,
    },
    data() {
        return {
            isLoading: true,
            products: "",
            categories: "",
            srhproducts: [],
            filterdata: "",
        };
    },

    created() {
        var token = localStorage.getItem("token");
        let uri = window.location.href.split("/");

        

        if (uri.length == "5") {
            axios
                .get("/api/category-products/" + uri["4"], { headers: { Authorization: "Bearer " + token }, })
                .then((res) => {
                    this.products = res.data.data;
                    this.filterdata = res.data.filterdata;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        } else if (uri.length == "6") {
            axios
                .get("/api/category-products/" + uri["4"] + "/" + uri["5"], { headers: { Authorization: "Bearer " + token }, })
                .then((res) => {
                    this.products = res.data.data;
                    this.filterdata = res.data.filterdata;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        } else if (uri.length == "7") {
            console.log('products' + this.products);

            axios
                .get(
                    "/api/category-products-sub/" +
                    uri["4"] +
                    "/" +
                    uri["5"] +
                    "/" +
                    uri["6"],
                    { headers: { Authorization: "Bearer " + token }, }
                )
                .then((res) => {
                    this.products = res.data.data;
                    this.filterdata = res.data.filterdata;

                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
        axios
            .get("/api/homecategory", {})
            .then((res) => {
                this.categories = res.data.data;
            })
            .catch((err) => {
                console.log(err.response);
            });

        axios
            .get("/api/product-list", {})
            .then((res) => {
                this.searchproducts = res.data.data;
                this.searchproducts.forEach((doc) => {
                    this.srhproducts.push(doc);
                    this.isLoading = false;
                });
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
};
</script>

<template>
    <div class="home">
        <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" name="circular"></loader>
        <Header v-if="this.categories.length > 0" :categories="categories" :srhproducts="srhproducts" />
        <Breadcrumbs v-if="products" />{{ products.filterdata }}
        <NewArrivalList />
        <Footer v-if="this.categories.length > 0" :categories="categories" />
    </div>
</template>