<template>
    <div class="home">
<Header />
<Breadcrumbs />
<Forgotpasswordpage />


<Footer />
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Breadcrumbs from "../components/Breadcrumbs.vue"
import Forgotpasswordpage from "../components/Auth/Forgotpasswordpage.vue"

import Footer from "../components/Footer.vue"
export default {
    name: "Home",
    components: {

        Header,
        Breadcrumbs,
        Forgotpasswordpage,
        
        Footer,
        
    }
}
</script>