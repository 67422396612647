<template>
  <div class="home">
    <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <Header v-if="this.categories.length > 0" :categories="categories" :srhproducts="srhproducts" />
    <Breadcrumbs />
    <Productdetailspage :products="products" />
    <Relatedproduct  :products="products" />
    <Footer v-if="this.categories.length > 0" :categories="categories"/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Productdetailspage from "../components/Productdetailspage.vue";
import Relatedproduct from "../components/Relatedproduct.vue";
import Footer from "../components/Footer.vue";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  name: "Home",
  components: {
    Header,
    Productdetailspage,
    Relatedproduct,
    Breadcrumbs,
    Footer,
  },
  data() {
    return {
      isLoading:true,
      products: "",
      categories: "",
      srhproducts:[],
    };
  },
  async created() {
    var token = localStorage.getItem("token");
    if (this.$route.params.prodName != "") {
      await axios
        .get("/api/product-detail/" + this.$route.params.prodName, {headers: { Authorization: "Bearer " + token }} )
        .then((res) => {
          this.products =res.data.data;
          console.log('products',this.products);
          })
        .catch((err) => {
          console.log(err.response);
          console.log('error');
        });
    }
    axios
      .get("/api/homecategory", {})
      .then((res) => {
        this.categories = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });

      axios
      .get("/api/product-list", {})
      .then((res) => {
        this.searchproducts = res.data.data;
        this.searchproducts .forEach((doc) => {
        this.srhproducts.push(doc);
          this.isLoading = false;
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  
};
</script>