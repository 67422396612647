<template>
  <div class="breadcrumb-top">
    <div
      class="path-section"
      :style="{ 'background-image': 'url(/images/product-cover.jpg)' }"
    >
      <div class="container">
        <div class="path-section-in">
           
          <div class="Show-page-info" v-if="third=='Search'">
           <h1>{{fourth.replace(/%20/g, " ")}}</h1>
           
            <a href="/">Home</a>/
            <a href="javascript:void(0)" @click="refreshpaget(third)" >{{third.replace("%20", " ")}}</a>

            <a href="javascript:void(0)" @click="refreshpagett(fourth)" >{{fourth.replace(/%20/g, " ")}}</a>
          </div>
          <div class="path-section-in" v-else>
          <div class="Show-page-info" v-if="uri.length > 4">
            <h1>{{fourth.replace(/-/g," ")}} </h1>
            <a href="/">Home</a>/
            <a href="javascript:void(0)" @click="refreshpaget(third)" >{{third.replace("%20", " ")}} </a>
            <a href="javascript:void(0)" @click="refreshpagett(fourth)" > / {{fourth.replace(/-/g," ")}}</a>
            <a href="javascript:void(0)" @click="refreshpagettt(five)" v-if="five"> / {{five.replace(/-/g," ")}} </a>
            <a href="javascript:void(0)" @click="refreshpagetttt(six)" v-if="six">/ {{six}} </a>
          </div>
           <div class="Show-page-info" v-else>
            <h1>{{fourth}}</h1>
            <a href="/">Home</a>/
              <!-- <a href="javascript:void(0)" @click="refreshpagettsi(third)" >{{third}}</a>  -->
            <a href="javascript:void(0)" @click="refreshpage()" >{{second.replace("%20", " ")}}</a>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
           second:'',
           third:"",
           fourth:"",
           five:"",
           six:"",
           uri:"",
          // title: (this.$route.params.sub == ''?this.$route.params.cat:this.$route.params.sub)
        }
    },
    methods:{
      refreshpagettsi(data){
        //  alert(data);
          // this.$router.push(data);
          console.log(data);
          // location.reload();
        },
        refreshpage(){
            
            // this.$router.push('/'+this.second);
            // location.reload();
         
         
        },
        refreshpaget(){
         
         if(this.second=='Productlist'|| this.second=='Productdetail' || this.second=='Search'){
          // this.$router.push('/');
          // location.reload();
          }
          else{
            //  this.$router.push('/'+this.third);
            //  location.reload();
          }
       
        },
 
      refreshpagett(data){
        // alert(this.third);
        // alert(data);
          
          // this.$router.push('/'+this.third);
          console.log(data);
          
          // this.$router.push('/'+this.third+'/'+data);
          console.log(data);
          // location.reload();
          
        },
        refreshpagettt(data){
          //  this.$router.push('/'+this.third+'/'+this.fourth+'/'+data);
          console.log(data);
          // location.reload();
          },
           refreshpagetttt(data){
          //  this.$router.push('/'+this.third+'/'+this.fourth+'/'+this.five+'/'+data);
          console.log(data);
          // location.reload();
          }

    },
    created() {
    let uri = window.location.href.split("/");
    this.second=uri[3];
    this.third=uri[3];
    this.fourth=uri[4];
    this.five=uri[5];
    this.six=uri[6];
    this.uri=uri;
  
  console.log(this.third);
    

    // console.log(uri[0]);
    // console.log(uri[1]);
    // console.log(uri[3]);
    // console.log('Productlist'+uri[3]);
    // console.log(uri.length);
    // console.log(uri[5]);

    },
};
</script>