<template>
    <div class="checkout">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 information-block">
                    <div class="logo-links">
                        <router-link to="/">
                            <div class="logo">
                                <img :src="$baseURL + '/images/logo.png'" alt="" />
                            </div>
                        </router-link>
                        <div class="links">
                            <a href="javascript:void(0)" @click="gotoCart()">Cart</a><i class="mdi mdi-chevron-right"></i>
                            <a href="javascript:void(0)" id="information" :class="informa">Information</a><i
                                class="mdi mdi-chevron-right"></i>
                            <a href="javascript:void(0)" id="payment" :class="payment_act">Payment</a>
                        </div>
                    </div>
                    <div id="information-tab" :class="information">
                        <div class="form-section" v-if="getAddress.length > 0">
                            <h3>Select Address</h3>
                            <div class="row select-address">
                                <div class="col-lg-6" v-for="(obj, key) in getAddress" :key="key">
                                    <label class="radio-all">
                                        <!-- Email: {{ obj.email }} <br /> -->
                                        <strong> Name:</strong> {{ obj.name }} <br />
                                        <strong> Phone:</strong> {{ obj.phone_no }} <br />
                                        <strong>Address:</strong> {{ obj.house_and_street_no }},{{ obj.town_city }},{{
                                            obj.state }},{{ obj.pincode }} <br />
                                        <strong>Adress Type:</strong> {{ obj.address_type }} <br />
                                        <router-link to="/Dashboard/profile" class="remove" v-if="key == 0"><i
                                                class="mdi mdi-account-edit"></i></router-link>
                                        <div v-else>
                                            <a href="javascript:void(0)" class="remove" @click="deleteAddress(obj.id)"><i
                                                    class="mdi mdi-delete-forever-outline"></i></a>
                                            <a href="javascript:void(0)" class="edit-block" @click="editmodel(obj.id)">
                                               
                                            </a>
                                        </div>
                                        <input type="radio" class="selectaddressid" :id="key" name="address_id"
                                            :value="obj.id" :checked="key == 0 ? 'true' : ''" /><span
                                            class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <a href="#shipping-ad" class="add-block" data-bs-toggle="modal"
                                        data-bs-target="#saveaddress" role="button" aria-expanded="false"
                                        aria-controls="shipping-ad">
                                        <i class="mdi mdi-plus"></i>
                                    </a>
                                </div>
                            </div>
                         
                            <div class="row justify-content-between action-area">
                                <div class="col-lg-4 col-6">
                                    <a href="javascript:void(0)" @click="gotoCart()"><i class="mdi mdi-chevron-left"></i>
                                        Return to Cart</a>
                                </div>
                                <div class="col-lg-5 col-6">
                                    <button @click="continoue_shipping()">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-section" v-else>
                            <div class="row justify-content-between shipping-action">
                                <div class="col-lg-12">
                                    <div class="form-body">
                                        <h3>Billing & Shipping</h3>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-item" v-if="user_info.email">
                                                    <input type="email" id="email" class="email" :value="user_info.email"
                                                        placeholder="Enter Email*" autocomplete="off" required="required" />
                                                </div>
                                                <div class="form-item" v-else>
                                                    <input type="text" id="email" class="email" value=""
                                                        placeholder="Enter Email*" autocomplete="off" required="required" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-item" v-if="user_info.name">
                                                    <input type="text" id="name" class="name" :value="user_info.name"
                                                        placeholder="Name *" autocomplete="off" required="required" />
                                                </div>
                                                <div class="form-item" v-else>
                                                    <input type="text" id="name" class="name" value="" placeholder="Name *"
                                                        autocomplete="off" required="required" />
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="form-item">
                                                    <input type="text" id="phone" class="phone_no" :value="user_info.phone"
                                                        placeholder="Phone No.*" autocomplete="off" maxlength="10"
                                                        required="required" />
                                                    <!-- <label for="phone_no">Phone No.*</label> -->
                                                </div>
                                            </div>

                                            <div class="col-lg-6">

                                                <div class="form-item">
                                                    <input type="text" id="pin" placeholder="Pin Code*" autocomplete="off"
                                                        required="required" v-model="pincode" value="name" maxlength="6"
                                                        @input="updateSelf($event.target.value)" />
                                                    <!-- <label for="pin">Pin Code*</label> -->
                                                </div>

                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-item">
                                                    <textarea id="house" v-model="house_and_street_no"
                                                        placeholder="House number and street name*" class="form-control"
                                                        rows="3"></textarea>
                                                  
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-item">
                                                    <input type="text" id="town_city" :value="city_state_data.District"
                                                        placeholder="City*" autocomplete="off" disabled="disabled"
                                                        required />

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-item">
                                                    <input type="text" id="state" :value="city_state_data.State"
                                                        placeholder="State*" autocomplete="off" disabled="disabled"
                                                        required="required" />

                                                </div>
                                            </div>
                                           

                                            <div class="col-md-6">
                                                <div class="form-item">
                                                    <input type="text" id="locality" v-model="locality"
                                                        placeholder="Locality(optional)" autocomplete="off"
                                                        required="required" />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-item">
                                                    <input type="text" id="landmark" v-model="landmark"
                                                        placeholder="Landmark(optional)" autocomplete="off"
                                                        required="required" />

                                                </div>

                                            </div>

                                            <div class="col-md-8 d-flex">
                                                <label class="radio-all">
                                                    <input type="radio" v-model="address_type" name="address_type"
                                                        class="selectaddressid" value="Home" />
                                                    <span class="checkmark"></span>
                                                    Home
                                                </label>

                                                <label class="radio-all">
                                                    <input type="radio" v-model="address_type" name="address_type"
                                                        class="selectaddressid" value="Office" />
                                                    <span class="checkmark"></span>
                                                    Office
                                                </label>
                                            </div>
                                            <div class="col-md-4">
                                                <button class="add-more" type="button" @click="addaddress()">
                                                    Submit Addressss
                                                </button>
                                            </div>
                                        </div>
                                        <!-- {{this.data[0].}} -->
                                        <!-- {{this.data[0].PostOffice}} -->

                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-between action-area">
                                <div class="col-lg-4 col-6">
                                    <a href="javascript:void(0)" @click="gotoCart()"><i class="mdi mdi-chevron-left"></i>
                                        Return to Cart</a>
                                </div>
                                <div class="col-lg-5 col-6">
                                    <button :disabled="checkVal" @click="continoue_shipping()">
                                        Continue to Shipping
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="payment-tab" :class="payment_tab">
                        <div class="form-section">
                            <div class="shipping-details">
                                <div class="detail-list">
                                    <span class='info-type'>Contact</span>
                                    <span class='info-show'> <strong>{{ shipingaddress.phone_no }} </strong> </span>

                                    <a href="#" class='info-change' @click="editmodel(shipingaddress.id)">Change</a>
                                </div>
                                <div class="detail-list border-none">
                                    <span class='info-type'>Ship to</span>
                                    <span class='info-show'>
                                        <strong>{{ shipingaddress.house_and_street_no }},{{ shipingaddress.state }},{{ shipingaddress.town_city }},{{ shipingaddress.pincode }}.</strong>
                                    </span>
                                    <a href="#" class='info-change' @click="editmodel(shipingaddress.id)">Change</a>
                                </div>

                            </div>
                        </div>
                        <div class="form-section">
                            <h3>Shipping Method</h3>
                            <div class="shipping-method">
                                <!-- <label class="radio-all"
                  >Online payment
                  <input
                    type="radio"
                    class="messageCode"
                    v-model="payment_method"
                    name="radio"
                    value="Payu"
                    checked
                  />
                  <span class="checkmark"></span>
                </label> -->
                                <!-- <label class="radio-all">Pay Online
                                <img src="https://cdn.razorpay.com/static/assets/logo/payment.svg" alt="Credit Card/Debit Card/NetBanking" class="razorpayimg">
                                <input type="radio" class="messageCode" v-model="payment_method" name="radio" value="Razorpay" checked />
                                <span class="checkmark"></span>
                            </label> -->

                                <div>
                                    <p>
                                        Your personal data will be used to process your order, support your experience
                                        throughout this website, and for other purposes described in our <router-link
                                            to="/Privacypolicy">
                                            Privacy policy
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                            <div class="row justify-content-between action-area">
                                <div class="col-lg-4 col-6">
                                    <a href="javascript:void(0)" @click="return_to_informations()"><i
                                            class="mdi mdi-chevron-left"></i> Return to Cart</a>
                                </div>
                                <div class="col-lg-5 col-6">
                                    <!-- <button>Continue to Shipping</button> -->
                                    <button type="button" @click="checkout()" :disabled="hidden">Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="cart-items">
                        <div class="item-show">
                            <!-- {{user_cart_item}} -->
                            <ul v-for="(obj, key) in user_cart_item" :key="key">
                                <!-- {{ obj.variablevalue }} -->
                                <!-- {{ obj.offer_price[0].offer_price }} -->
                                <li v-if="obj.status == '1'">
                                    <div class="img-show">
                                        <div class="img-cover">
                                            <img :src="$baseURL +
                                                '/public/images/product/original/' +
                                                obj.image[0].product_image
                                                " alt="" />
                                        </div>
                                        <span class="count">{{ obj.qty }}</span>
                                    </div>
                                    <div class="info-show">
                                        <h3>{{ obj.product.name }}</h3>
                                        <span>Size: {{ obj.size_name }}</span>
                                    </div>
                                    <div class="price-show">₹ {{  obj.offer_price.offer_price +parseInt(obj.variablevalue !=null ?obj.variablevalue :0) }}</div>
                                </li>
                            </ul>
                            <!-- ₹{{ this.$store.state.price }} -->
                        </div>

                        <!--  <div class="item-coupon">
                         <a data-bs-toggle="collapse" href="#coupon" role="button" aria-expanded="false" aria-controls="coupon">Appy Coupon Code?
                        </a>

                        <div class="collapse" id="coupon">
                            <div class="form-body">
                                <input type="text" name="" id="" />
                                <button>Apply</button>
                            </div>
                        </div> 
                        </div>-->
                        <div class="item-subtotal">
                            <span class="text-sb">Discount coupon</span>
                            <span class="price-sb"
                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">-₹{{
                                    this.$store.state.coupon.couponprice }}
                            </span>
                            <span class="price-sb" v-else>₹0

                            </span>
                        </div>
                        <!-- <div v-if="this.$store.state.variablevalue !== null" class="item-subtotal">
                            <span class="text-sb">Variable Price</span>
                            <span class="price-sb">₹{{ this.$store.state.variablevalue }}
                                <span></span>
                            </span>
                        </div>
                        <div v-else>

                        </div> -->

                        <div class="item-subtotal">
                            <span class="text-sb">Shipping Charge</span>
                            <span class="price-sb">₹{{ this.$store.state.delivery}} </span>
                        </div>

                        <div class="item-subtotal">
                            <span class="text-sb">Subtotal</span>
                            <span class="price-sb"
                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">₹{{
                                    parseInt(this.$store.state.total - this.$store.state.coupon.couponprice) }}</span>
                            <span class="price-sb" v-else>₹{{ this.total }}</span>
                        </div>
                        <div class="item-subtotal">
                            <span class="text-total">Total</span>
                            <span class="text-total"
                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">₹{{
                                    parseInt(this.$store.state.total - this.$store.state.coupon.couponprice) }}</span>
                            <span class="total-price" v-else>₹{{ this.total }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="address-pop">
            <div class="modal" id="saveaddress" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3>Add Address</h3>
                            <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>

                        <div class="row g-2 g-lg-2 ">

                            <div class="col-md-12">
                                <div class="form-item">
                                    <input type="text" id="name" v-model="name" placeholder="Name*" autocomplete="off"
                                        required="required" />

                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-item">
                                    <input type="text" id="phone_no" v-model="phone_no" placeholder="Phone No.*"
                                        autocomplete="off" maxlength="10" required="required" />

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-item">
                                    <input type="text" id="pin" placeholder="Pin Code*" autocomplete="off"
                                        required="required" v-model="pincode" value="name" maxlength="6"
                                        @input="updateSelf($event.target.value)" />

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-item">
                                    <textarea id="house" v-model="house_and_street_no" placeholder="House & street no.*"
                                        class="form-control" rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="town_city" v-model="town_city" placeholder="City*"
                                        autocomplete="off"  required />
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="state" v-model="state" placeholder="State*"
                                        autocomplete="off"  required="required" />
                                </div>
                            </div>

                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="locality" v-model="locality" placeholder="Locality(optional)"
                                        autocomplete="off" required="required" />

                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="landmark" v-model="landmark" placeholder="Landmark(optional)"
                                        autocomplete="off" required="required" />

                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="radio-all">
                                    <input type="radio" v-model="address_type" name="address_type" value="Home"
                                        class="selectaddressid" :checked="address_type == 'Home' ? 'true' : ''" />
                                    <span class="checkmark"></span>
                                    Home
                                </label>
                                <label class="radio-all">
                                    <input type="radio" v-model="address_type" name="address_type" value="Office"
                                        class="selectaddressid" />
                                    <span class="checkmark"></span>
                                    Office
                                </label>
                            </div>
                            <div class="
                  col-md-12
                  d-flex
                  align-items-center
                  justify-content-between
                ">
                                <button class="save-bt" @click="addnewaddress()">
                                    Save Addresss
                                </button>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="address-pop edit">
            <div class="modal" id="editaddress" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3>Update Address</h3>
                            <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>

                        <div class="row g-2 g-lg-2 ">

                            <div class="col-md-12">
                                <div class="form-item">
                                    <input type="text" id="name" v-model="editaddress.name" placeholder="Name*"
                                        autocomplete="off" required="required" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-item">
                                    <input type="text" id="phone_no" v-model="editaddress.phone_no" placeholder="Phone No.*"
                                        autocomplete="off" maxlength="10" required="required" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-item">
                                    <input type="text" id="pin" placeholder="Pin Code*" autocomplete="off"
                                        required="required" v-model="editaddress.pincode" value="name" maxlength="6"
                                        @input="updateSelf($event.target.value)" />

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-item">
                                    <textarea id="house" v-model="editaddress.house_and_street_no"
                                        placeholder="House & street no.*" class="form-control" rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item" v-if="city_state_data.District">
                                    <input type="text" id="town_city" :value="city_state_data.District" placeholder="City*"
                                        autocomplete="off" disabled="disabled" required />
                                </div>
                                <div class="form-item" v-else>
                                    <input type="text" id="town_city" :value="editaddress.town_city" placeholder="City*"
                                        autocomplete="off" disabled="disabled" required />
                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item" v-if="city_state_data.State">
                                    <input type="text" id="state" :value="city_state_data.State" placeholder="State*"
                                        autocomplete="off" disabled="disabled" required="required" />
                                </div>
                                <div class="form-item" v-else>
                                    <input type="text" id="state" :value="editaddress.state" placeholder="State*"
                                        autocomplete="off" disabled="disabled" required="required" />
                                </div>
                            </div>

                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="locality" v-model="editaddress.locality"
                                        placeholder="Locality(optional)" autocomplete="off" required="required" />

                                </div>
                            </div>
                            <div class="col-md-6 col-6">
                                <div class="form-item">
                                    <input type="text" id="landmark" v-model="editaddress.landmark"
                                        placeholder="Landmark(optional)" autocomplete="off" required="required" />

                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="radio-all">
                                    <input type="radio" v-model="editaddress.address_type" name="address_type" value="Home"
                                        class="selectaddressid" :checked="address_type == 'Home' ? 'true' : ''" />
                                    <span class="checkmark"></span>
                                    Home
                                </label>
                                <label class="radio-all">
                                    <input type="radio" v-model="editaddress.address_type" name="address_type"
                                        value="Office" class="selectaddressid" />
                                    <span class="checkmark"></span>
                                    Office
                                </label>
                            </div>
                            <div class="
                  col-md-12
                  d-flex
                  align-items-center
                  justify-content-between
                ">

                                <button class="save-bt" @click="updateaddress()">
                                    Update Addresss
                                </button>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
        <div id="spinner" v-if="hidden"></div>
    </div>
</template>
<!-- <script src="https://checkout.razorpay.com/v1/checkout.js"></script> -->
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    props: ["user_info"],
    data() {
        return {
            hidden: false,
            name: "",
            phone_no: "",
            house_and_street_no: "",
            pincode: "",
            payment_method: "COD",
            // payment_method: "Razorpay",
            address_id: "",
            // user_info: "",
            getAddress: "",

            address_type: "Home",
            address_typefi: "Home",
            information: "",
            informa: "active",
            payment_act: "",
            payment_tab: "fading",
            user_cart_item: "",
            total: '',
            // next:null,
            city_state_data: '',
            locality: '',
            landmark: '',
            shipingaddress: '',
            editaddress: {
                name: '',
                phone_no: '',
                pincode: '',
                house_and_street_no: '',
                town_city: '',
                state: '',
                locality: '',
                landmark: '',
                address_type: ''
            }

        };
    },

    methods: {
        editmodel(id) {
            axios
                .get("/api/get-address/" + id, {})
                .then((res) => {
                    this.editaddress = res.data.data;

                    ('#editaddress').modal('show')
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },

        updateSelf(name) {
            if (name.length == '6') {
                
                axios
                .get("https://api.postalpincode.in/pincode/" + name, {})
                .then((res) => {
                    
                    this.data = res.data;
                    this.data[0].PostOffice
                    console.log(this.data[0].PostOffice);
                    this.data[0].PostOffice.forEach((doc) => {
                        if (doc.BranchType == 'Sub Post Office') {
                            this.city_state_data = doc;
                            //  this.data.push(doc);
                        }
                        
                    });
                    //   return res.data.data;
                    console.log('name',this.city_state_data);
                    })
                    .catch((err) => {
                        console.log(err.response);
                        // var dt='0';
                        //   return dt;
                    });

            }
        },
        gotoCart() {
            this.$router.push("/Cart");
            location.reload();
        },
        continoue_shipping() {

            this.information = "fading";
            this.informa = "deactive";
            this.payment_act = "active";
            this.payment_tab = "active";

            var id = document.querySelector(
                ".selectaddressid:checked"
            ).value;

            axios
                .get("/api/get-address/" + id, {})
                .then((res) => {
                    this.shipingaddress = res.data.data;
                })
                .catch((err) => {
                    console.log(err.response);
                });

        },
        return_to_informations() {
            this.information = "";
            this.informa = "active";
            this.payment_act = "deactive";
            this.payment_tab = "fading";
        },
        myPaycheckpaystatus(data, response) {

            console.log(data);
            var postDatarespon = {
                // Ordered_data: data,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                // razorpay_signature: response.razorpay_signature,
                razorpay_response: response,
            };

            axios
                .post("api/razorpay-success", postDatarespon, {})
                .then((res) => {
                    // this.$alert('payments done');

                    if (res.data.status == 1) {
                        this.$fire({
                            title: "Thank You for Booking with Us!",
                            text: "Payments done successfully",
                            type: "success",
                            showCloseButton: true,
                        });

                        this.$router.push("/Dashboard/Orderdetails");
                    } else {
                        this.$fire({
                            title: "Thank You for Booking with Us!",
                            text: "Payments faild !",
                            type: "warning",
                            showCloseButton: true,
                        });
                        alert(" payments faild !");
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },

        checkout() {
            var addresscheckedValue = document.querySelector(
                ".selectaddressid:checked"
            ).value;

            if (!this.payment_method) {
                this.$fire({
                    title: "Selected Payment Method",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            if (this.user_cart_item.length == 0) {
                this.$fire({
                    title: "Add To Product",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            if (this.$store.state.coupon!==null && this.$store.state.total > this.$store.state.coupon.min_value) {
             var coupon_id=this.$store.state.coupon.coupon_id;
            } else {
               coupon_id='';
            }
            const orderdata = {
                user_id: this.user_info.id,
                payment_method: this.payment_method,
                address_id: addresscheckedValue,
                coupon_id:coupon_id,
            };
            (this.hidden = true),
            axios
            .post("/api/place-order", orderdata)
            .then((res) => {
                    console.log('orderdata',res);
                    
                    (this.hidden = true);
                    localStorage.removeItem("coupon");
                    if (res.data.paytype == "COD") {

                        this.$router.push({
                            name: "Thankyou",
                            params: {
                                order_data: res.data.order_data,
                            },
                        });
                    } 
                    // else if (res.data.paytype == "Razorpay") {
                    //     console.log(res.data.data);
                    //     console.log(res.data.data.user);
                    //     var options = {
                            
                    //         key: res.data.data.key, // Enter the Key ID generated from the Dashboard
                    //         amount: res.data.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    //         currency: "INR",
                    //         name: res.data.data.company_name,
                    //         description: "online transactions",
                    //         image: "/images/logo.png",
                    //         order_id: res.data.data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

                    //         handler: (response) =>
                    //             this.myPaycheckpaystatus(res.data.data, response),

                    //         "prefill": {
                    //             "name": res.data.data.user.name,
                    //             "email": res.data.data.user.email,
                    //             "contact": res.data.data.user.phone
                    //         },
                    //         "notes": {
                    //             "address": "Razorpay Corporate Office"
                    //         },
                    //         "theme": {
                    //             "color": "#3399cc"
                    //         }
                    //     };

                    //     var rzp1 = new Razorpay(options);

                    //     rzp1.on('payment.failed', function (response) {
                    //         console.log('payment_response');
                    //         console.log(response);

                    //         // alert(response.error.code);
                    //         // alert(response.error.description);
                    //         // alert(response.error.source);
                    //         // alert(response.error.step);
                    //         // alert(response.error.reason);
                    //         // alert(response.error.metadata.order_id);
                    //         // alert(response.error.metadata.payment_id);
                    //     });
                    //     // document.getElementById('rzp-button1').onclick = function(e){
                    //     //     rzp1.open();
                    //     //     e.preventDefault();
                    //     // }
                    //     rzp1.open();
                    // }
                    //  else if {
                    //   this.posted = res.data.posted;
                    //   this.hash = res.data.hash;
                    //   this.action = res.data.action;
                    //   console.log("posted" + this.posted);
                    //   console.log("hash" + this.posted);
                    //   console.log("action" + this.posted);

                    //   this.$router.push({
                    //     name: "Payuform",
                    //     params: {
                    //       posted: this.posted,
                    //       hash: this.hash,
                    //       action: this.action,
                    //     },
                    //   });
                    // }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },

        addaddress() {
            var email = document.getElementById('email').value;
            var name = document.getElementById('name').value;
            var town_city = document.getElementById('town_city').value;
            var state = document.getElementById('state').value;
            var phone_no = document.getElementsByClassName('phone_no')[0].value;

            if (email) {
                const re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (re.test(email) == true) {
                    // return true;
                } else if (re.test(email) == false) {
                    this.$fire({
                        title: "email Not Valid",
                        type: "error",
                        timer: 5000,
                    }).then((r) => {
                        console.log(r.value);
                    });
                    return false;
                }
            }

            if (!email) {
                this.$fire({
                    title: "Enter Your Email",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            if (!name) {
                this.$fire({
                    title: "Enter Your Name",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            if (!phone_no) {
                this.$fire({
                    title: "Enter Your Phone No",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!this.pincode) {
                this.$fire({
                    title: "Enter Your Pincode",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!this.house_and_street_no) {
                this.$fire({
                    title: "Enter Your House And Streent No",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!town_city) {
                this.$fire({
                    title: "Enter Your Town City",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!state) {
                this.$fire({
                    title: "Enter Your State",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            const addressdata = {
                user_id: this.user_info.id,
                email: email,
                name: name,
                house_and_street_no: this.house_and_street_no,
                town_city: town_city,
                state: state,
                pincode: this.pincode,
                phone_no: phone_no,
                address_type: this.address_type,
                landmark: this.landmark,
                locality: this.locality,
            };
            axios
                .post("/api/addUserAdress", addressdata)
                .then((res) => {
                    this.getAddress = res.data.data;
                    location.reload();
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        addnewaddress() {

           

            var town_city = document.getElementById('town_city').value;
            var state = document.getElementById('state').value;

            if (!this.name) {
                this.$fire({
                    title: "Enter Your Name",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!this.phone_no) {
                this.$fire({
                    title: "Enter Your Phone No",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!this.pincode) {
                this.$fire({
                    title: "Enter Your Pincode",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!this.house_and_street_no) {
                this.$fire({
                    title: "Enter Your House And Street No",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!town_city) {
                this.$fire({
                    title: "Enter Your Town City",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }
            if (!state) {
                this.$fire({
                    title: "Enter Your State",
                    type: "error",
                    timer: 5000,
                }).then((r) => {
                    console.log(r.value);
                });
                return false;
            }

            const addressdata = {
                user_id: this.user_info.id,
                name: this.name,
                phone_no: this.phone_no,
                pincode: this.pincode,
                house_and_street_no: this.house_and_street_no,
                town_city: town_city,
                state: state,
                landmark: this.landmark,
                locality: this.locality,
                address_type: this.address_type,
            };
             
            axios  
                .post("/api/addUserAdress", addressdata)
                .then((res) => {
                    this.getAddress = res.data.data;
                    location.reload();
                    // console.log(res);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        updateaddress() {

            var town_city = document.getElementById('town_city').value;
            var state = document.getElementById('state').value;
            this.editaddress.town_city = town_city;
            this.editaddress.state = state;

            this.$fire({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                // type: "error",
                showCancelButton: true,
                confirmButtonColor: '#fe4d01',
                confirmButtonText: 'Yes, Update it!',
                cancelButtonText: 'No, Keep it!',

                // timer: 5000,
            }).then((r) => {
                if (r.value) {

                    axios.post("/api/update-user-address", this.editaddress).then((res) => {
                            this.status = res.data.status;
                            console.log(this.status);
                            if (this.status == '200') {
                                this.$fire('Update!', 'Your Todo has been Updated', 'success')
                                window.location.reload();
                            }

                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                } else {
                    this.$fire('Canceled', 'Your Todo is still in place', 'warning')
                }

            });

        },
        deleteAddress(id) {

            this.$fire({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                // type: "error",
                showCancelButton: true,
                confirmButtonColor: '#fe4d01',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, Keep it!',

                // timer: 5000,
            }).then((r) => {
                if (r.value) {
                    axios.get("/api/delete-user-address/" + id, {}).then((res) => {
                            this.status = res.data.status;
                            if (this.status == '200') {
                                this.$fire('Deleted!', 'Your Todo has been deleted', 'success')
                                window.location.reload();
                            }

                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                } else {
                    this.$fire('Canceled', 'Your Todo is still in place', 'warning')
                }

            });

        }
    },
    created() {
        axios
            .get("/api/getUserAddress/" + this.user_info.id, {})
            .then((res) => {
                this.getAddress = res.data.data;
            })
            .catch((err) => {
                console.log(err.response);
            });

        axios
            .get("/api/cart-items/" + this.user_info.id, {})
            .then((res) => {
                this.user_cart_item = res.data.data;
                this.total = res.data.total;
                if (this.user_cart_item.length == 0) {
                    this.$fire({
                        title: "Yor Cart is empty",
                        type: "error",
                        timer: 5000,
                    }).then((r) => {
                        console.log(r.value);
                    });
                    this.$router.push("/");
                }
                console.log(this.user_cart_item);
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    computed: {
        checkVal: function () {
            return this.getAddress.length > 0 ? false : true;
        }
    },

};
</script>

<style>.checkout .information-block .fading {
    display: none;
}

#spinner:not([hidden]) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#spinner::after {
    content: "";
    width: 80px;
    height: 80px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #f25a41;
    border-radius: 100%;
    will-change: transform;
    animation: spin 1s infinite linear;
}

.add-block {
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    margin-right: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    border: #dfdfdf 1px solid;
    padding: 36px 23px;
}

.add-block i {
    font-size: 20px;
}

a.edit-block {
    font-size: 26px;
    color: #979797;
    position: absolute;
    right: 28px;
    top: 0px;
}

img.razorpayimg {
    width: 200px;
}</style>
