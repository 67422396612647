<template>
  <div class="home">
    <Header />
    <Breadcrumbs />
    <Resetpasswordpage />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Resetpasswordpage from "../components/Auth/Resetpasswordpage.vue";

import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Breadcrumbs,
    Resetpasswordpage,

    Footer,
  },
};
</script>