<template>
  <div class="myaccount-set">
    <div class="container">
      <div class="myaccount-set-in">
        <form @submit.prevent="handleSubmitForget">
        <div class="row justify-content-center">
          <div class="col-lg-5 form-cover">
            <h3>Forgot Password</h3>
            <div class="row2">
              <div class="col-md-12">
                <div class="form-group">
                  <span class="ico mdi mdi-account-outline"></span>
                  <input
                    required
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                    v-model="email"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="submit">
                  <button type="submit" :class="isDisabled==true?'disabled':'' "  :disabled='isDisabled'>Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
        email:'',
        message:'',
        isDisabled:false
    };
  },
  methods: {
     async handleSubmitForget() {
           if (!this.email) {
        this.flash("Please Enter Your Email", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }
        const userData = {
        email: this.email,
      };
        this.isDisabled = true;
      await axios.post("/api/forgetpassword", userData)
        .then((res) => {
           console.log(res.data.status);
           this.message = res.data.message;
           if (res.data.status == 1) {
               this.$fire({
              title: "Bwitchedzone OTP send ",
              text: this.message,
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
            this.$router.push("/Forgotpasswordverify");
           } else {

               this.$fire({
              title: "Forget password Failed !",
              text: this.message,
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
               
           }
        })
        .catch((err) => {
          console.log(err.response);
        });


      }
  },
};
</script>