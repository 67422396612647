<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row justify-content-between">
              <div class="col-md-4">
                  <div class="pro-info">
                    <h3>Payment Failures</h3>
                    </div>
                </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>