<template>
  <div class="home">
    <Header />
    <Breadcrumbs />
    <Filterpage v-if="products" :products = "products" />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Filterpage from "../components/Filterpage.vue"
import Footer from "../components/Footer.vue";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "Home",
  components: {
    Header,
    Breadcrumbs,
    Filterpage,
    Footer,
  },
  data() {
    return {
      products: "",
    };
  },
created() {
      
       if(this.$route.params.sub == ''){
            axios.get("/api/category-products/women", {})
      .then((res) => {
        this.products = res.data.data;
       
      })
      .catch((err) => {
        console.log(err.response);
      });

       }else{
            axios.get("/api/category-products/women/dress", {})
      .then((res) => {
       this.products = res.data.data;
      
        
      })
      .catch((err) => {
        console.log(err.response);
      });

       }
       
  },

};
</script>