<template>
  <div>
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
.loader {
     display: inline-block;
    width: 100%;
    height: 100vh;
    /* height: 64px; */
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    /* transform: translateX(-50%) translateY(-50%); */
    left: 0;
    background-color: #fff;
    z-index: 1000;
}
.loader:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #2e2e2e transparent #2e2e2e transparent;
  animation: loader 0.7s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>