<template>
  <div class="inner-page">
    <div class="container">
      <div class="content-section">
        <h2>ABOUT US : WELCOME TO KABIR COLLECTIONS</h2>
        <p>
          We at Kabir Collections offer an latest collection of Suits & Kurties
          of renowned Pakistani Brands. We are the leading importers of
          PAKISTANI SUITS of all brands and the largest wholesale supplier in
          INDIA. Pakistani Dresses are very famous and highly preferable
          clothing wear world-wide. Pakistani Designer Suits are best fashion
          wear with outstanding quality as well as the fabric has enormous edge
          over others. Our products range from printed lawns with Chiffon, Voil,
          Brasso and Ombre dyed dupattas in summer to printed Linen, Khaddar,
          Sateen and Karandi in Winters.
        </p>
        <p>
          You can find a collage of various renowned designer brands from
          Pakistan in ladies suits, fabrics, dress materials and readymade
          garments throughout the year. A sizzling blend of Indian designs and
          Pakistani cuts on plush fabrics with fade resistant print designs and
          exquisite embroidery is what we call our USP. Highly skilled
          customized designing marks another feather in our cap. Setting high
          standards in quality and craftsmanship, we strive to have a satisfied
          customer base pan India. Kabir Collections has the largest collection
          of Pakistani designer lawn Suits at the most reasonable price which
          can be purchased online and are delivered all over the world. Try it,
          order it and got it at your door step.......
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

style
