<template>
  <div class="Payufailuremony">
    <Payufailuremony />
  </div>
</template>
<script>
import Payufailuremony from "../components/Dashboad/Payufailuremony.vue";
export default {
  name: "Payufailure",
  components: {
      Payufailuremony,
  },
};
</script>