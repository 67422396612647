import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    data() {
        return {
            titless: 'hithis is the mixi',
            isLoading: '',
            active_ws:[],
            delayLoading: 2
        };
    },
    methods: {
         
        add_wishlist(product,user_id) {
           if(user_id ==undefined){
                this.$router.push("/login");
            }
            else {

                this.active_ws.push(product.id+user_id);
                // this.active_ws[]=product.id+user_id;
                this.isLoading=product.id+user_id;
                 const data_wish = {
                    product_id: product.id,
                    user_id: user_id,
                    size_id:product.variable_list[0].size_id,
                    
                  };
          console.log('chandan0',data_wish)

                  axios
                    .post("/api/add-to-wish-list", data_wish)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("getuserwislist", user_id);
                        setTimeout(() => { this.isLoading = false; }, this.delayLoading * 500)

                        this.$fire({
                            title: 'Wishlist Added SuccessFully',
                            type: "success",
                            timer: 5000,
                          }).then((r) => {
                            console.log(r.value);
                          });


                    })
                    .catch((err) => {
                      console.log(err.response);
                    });

                  //  location.reload();

            }

            
            
        }
    },
}


