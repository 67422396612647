<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="profile-info">
                  <!-- <div class="pro-img">
                    <img src="images/user.jpg" alt="" />
                  </div> -->
                  <div class="pro-info">
                    <h3>{{ user_info.name }}</h3>
                    <h4>+91{{ user_info.phone }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="logout text-end">
                  <a href="javascript:void(0)" @click="logout()">Back<i class="mdi mdi-arrow-u-left-top"></i></a>
                </div>
              </div>
            </div>
            <hr />

            <div class="dash-edit-section" v-if="getAddress">
              <h2>Update Profile</h2>
              <hr />
              <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Name">Name</label>
                      <input
                        type="text"
                        class="form-control user_name"
                        id="user_name"
                        :value="user_info.name"
                        placeholder="Name"
                        
                      />
                    </div>
                  </div>
                 
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Name">Email</label>
                      <input
                        type="email"
                        id="email"
                        class="form-control email"
                        :value="user_info.email"
                        placeholder="Email*"
                        
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        id="phone"
                        class="form-control phone"
                        :value="user_info.phone"
                        placeholder="Phone*"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    
                    <div class="form-group">
                     
                          <input
                            type="text"
                            id="pincode"
                            placeholder="Pin Code*"
                            autocomplete="off"
                            class="form-control pincode"
                            required="required"
                            v-model="pincode"
                            value="name"
                            maxlength="6"
                            @input="updateSelf($event.target.value)"
                          />
                    </div>
                  </div>
         
                  
                  <div class="col-md-6" v-if="city_state_data.District">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control town_city"
                            id="town_city"
                            :value="city_state_data.District"
                            placeholder="City*"
                            autocomplete="off"
                            disabled="disabled"
                            required
                          />
                    </div>
                   
                  </div>
                  <div class="col-md-6" v-else>
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control town_city"
                            id="town_city"
                            :value="getAddress.town_city"
                            placeholder="City*"
                            autocomplete="off"
                            disabled="disabled"
                            required
                          />
                    </div>
                    
                  </div>
                 
                  <div class="col-md-6" v-if="city_state_data.State">
                    <div class="form-group">
                     <input
                            type="text"
                            id="state"
                            class="form-control state"
                            :value="city_state_data.State"
                            placeholder="State*"
                            autocomplete="off"
                            disabled="disabled"
                            required="required"
                          />
                    </div>
                     
                  </div>
                  <div class="col-md-6" v-else>
                    <div class="form-group">
                     <input
                            type="text"
                            id="state"
                            class="form-control state"
                            :value="getAddress.state"
                            placeholder="State*"
                            autocomplete="off"
                            disabled="disabled"
                            required="required"
                          />
                    </div>
                     
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                       <textarea id="house_and_street_no" class="form-control house_and_street_no" :value="getAddress.house_and_street_no" placeholder="House number and street name*"  rows="3"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                    type="text"
                    id="locality"
                    class="form-control locality"
                    :value="getAddress.locality"
                    placeholder="Locality(optional)"
                    autocomplete="off"
                    required="required"
                  />
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                   <div class="form-group">
                  <input
                    type="text"
                    id="landmark"
                    class="form-control landmark"
                   :value="getAddress.landmark"
                    placeholder="Landmark(optional)"
                    autocomplete="off"
                    required="required"
                  />
                  <input
                    type="hidden"
                    id="address_id"
                    class="form-control address_id"
                   :value="getAddress.id"
                   />
                    </div>
                  </div>


                  <div class="col-md-12 d-flex justify-content-end">
                    <button @click="updateprofile()">save Details</button>
                  </div>
                </div>
               
            </div>
            <div class="dash-edit-section" v-else>
              <h2>Update Profile</h2>
              <hr />
              <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Name">Name</label>
                      <input
                        type="text"
                        class="form-control user_name"
                        id="user_name"
                        :value="user_info.name"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                 
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Email">Email</label>
                      <input
                        type="email"
                        id="email"
                        class="form-control email"
                        :value="user_info.email"
                        placeholder="Email*"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        id="phone"
                        class="form-control phone"
                        :value="user_info.phone"
                        placeholder="Phone*"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    
                    <div class="form-group">
                     
                          <input
                            type="text"
                            id="pincode"
                            placeholder="Pin Code*"
                            autocomplete="off"
                            class="form-control pincode"
                            required="required"
                            v-model="pincode"
                            value="name"
                            maxlength="6"
                            @input="updateSelf($event.target.value)"
                          />
                    </div>
                  </div>
         
                  
                  <div class="col-md-6">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control town_city"
                            id="town_city"
                            :value="city_state_data.District"
                            placeholder="City*"
                            autocomplete="off"
                            disabled="disabled"
                            required
                          />
                    </div>
                   
                  </div>
                   
                 
                  <div class="col-md-6">
                    <div class="form-group">
                     <input
                            type="text"
                            id="state"
                            class="form-control state"
                            :value="city_state_data.State"
                            placeholder="State*"
                            autocomplete="off"
                            disabled="disabled"
                            required="required"
                          />
                    </div>
                     
                  </div>
                  
                  <div class="col-md-12">
                    <div class="form-group">
                       <textarea id="house_and_street_no" class="form-control house_and_street_no" value="" placeholder="House number and street name*"  rows="3"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                    type="text"
                    id="locality"
                    class="form-control locality"
                   value=""
                    placeholder="Locality(optional)"
                    autocomplete="off"
                    required="required"
                  />
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                   <div class="form-group">
                  <input
                    type="text"
                    id="landmark"
                    class="form-control landmark"
                   value=""
                    placeholder="Landmark(optional)"
                    autocomplete="off"
                    required="required"
                  />
                  
                    </div>
                  </div>


                  <div class="col-md-12 d-flex justify-content-end">
                    <button @click="updateprofiledata()">save Details</button>
                  </div>
                </div>
               
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  data() {
    return {
       user_info:'',
       getAddress:'',
       city_state_data:'',
       pincode:'',
       
      

    };
  },
  methods: {

 

     logout() {
        history.go(-1);
      // sessionStorage.setItem("token", "");
      // this.$router.push("/");
    },
    updateSelf(name) {
     if(name.length=='6'){
     //console.log(name);
    

 axios
 .get("https://api.postalpincode.in/pincode/" +name, {})
      .then((res) => {
      this.data = res.data;
      this.data[0].PostOffice
       console.log(this.data[0].PostOffice);
       this.data[0].PostOffice.forEach((doc) => {
         if(doc.BranchType=='Sub Post Office'){
           this.city_state_data=doc;
          //  this.data.push(doc);
         }
         
        });
      //   return res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      
      });


      }
    },
    updateprofile() {
 
  var user_name = document.getElementsByClassName('user_name')[0].value;
  var email = document.getElementsByClassName('email')[0].value;
  var phone = document.getElementsByClassName('phone')[0].value;
  //var pin_code = document.getElementsByClassName('pin_code')[0].value;
  var house_and_street_no = document.getElementsByClassName('house_and_street_no')[0].value;
  var town_city = document.getElementsByClassName('town_city')[0].value;
  var state = document.getElementsByClassName('state')[0].value;
  var locality = document.getElementsByClassName('locality')[0].value;
  var landmark = document.getElementsByClassName('landmark')[0].value;
  var address_id = document.getElementsByClassName('address_id')[0].value;

  if (!user_name) {
        this.$fire({
          title: "Enter Your Name",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
if (email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(email) == true) {
          // return true;
        } else if (re.test(email) == false) {
          this.$fire({
            title: "email Not Valid",
            type: "error",
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
          return false;
        }
      }
   

  if (!email) {
        this.$fire({
          title: "Enter Your Email",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }

  if (!phone) {
        this.$fire({
          title: "Enter Your Phone",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  if (!house_and_street_no) {
        this.$fire({
          title: "Enter Your House And Street No",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  if (!town_city) {
        this.$fire({
          title: "Enter Your House And Street No",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  if (!state) {
        this.$fire({
          title: "Enter Your State",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  


 


       const addressdata = {
        user_id: this.user_info.id,
        name: user_name,
        email: email,
        phone: phone,
        pincode: this.pincode,
        house_and_street_no: house_and_street_no,
        town_city:town_city,
        state: state,
        locality: locality,
        landmark:landmark,
        address_id: address_id,
      };

      //console.log(addressdata);
      
      axios.post('/api/updateUserprofile', addressdata)
     .then((res) =>{
       if(res.data.status=='200'){
          location.reload();
       }
         
       else if(res.data.status=='201'){
         this.$fire({
          title: 'The email has already been taken',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
       }
        
       //console.log(res);
     })
     .catch((error) => {
       console.log(error.response);
       });

    },
    updateprofiledata() {
 
  var user_name = document.getElementsByClassName('user_name')[0].value;
  var email = document.getElementsByClassName('email')[0].value;
  var phone = document.getElementsByClassName('phone')[0].value;
 
  var house_and_street_no = document.getElementsByClassName('house_and_street_no')[0].value;
  var town_city = document.getElementsByClassName('town_city')[0].value;
  var state = document.getElementsByClassName('state')[0].value;
  var locality = document.getElementsByClassName('locality')[0].value;
  var landmark = document.getElementsByClassName('landmark')[0].value;

  
  if (!user_name) {
        this.$fire({
          title: "Enter Your Name",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
      if (email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(email) == true) {
          // return true;
        } else if (re.test(email) == false) {
          this.$fire({
            title: "email Not Valid",
            type: "error",
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
          return false;
        }
      }
  if (!email) {
        this.$fire({
          title: "Enter Your Email",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  if (!phone) {
        this.$fire({
          title: "Enter Your Phone",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
       if (!this.pincode) {
        this.$fire({
          title: "Enter Your Pin Code",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }


  if (!town_city) {
        this.$fire({
          title: "Enter Your Town City",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
  if (!state) {
        this.$fire({
          title: "Enter Your State",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
        if (!house_and_street_no) {
        this.$fire({
          title: "Enter Your House And Street No",
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
 
 


       const addressdata = {
        user_id: this.user_info.id,
        name: user_name,
        email: email,
        phone: phone,
        pincode: this.pincode,
        house_and_street_no: house_and_street_no,
        town_city:town_city,
        state: state,
        locality: locality,
        landmark:landmark,
         
      };

      // console.log('addressdata',addressdata);
       axios.post('/api/updateUserprofile', addressdata)
     .then((res) =>{

      console.log('addressdata',res.data.status);
       if(res.data.status=='200'){
          location.reload();
       }
        else if(res.data.status=='201'){
         this.$fire({
          title: 'The email has already been taken',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
       }
        
       //console.log(res);
     })
     .catch((error) => {
       console.log(error.response);
       });

    },
    getaddress(user_id){
       axios
      .get("/api/getUserAddress/" + user_id, {})
      .then((res) => {
        this.getAddress = res.data.data[0];
        this.pincode = res.data.data[0].pincode;
      })
      .catch((err) => {
        console.log(err);

       });

    }
  },
  created() {
    var token = localStorage.getItem("token");
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
         this.getaddress(this.user_info.id)
      })
      .catch((err) => {
        this.$router.push("/login");
        console.log(err.response);
      });
  },
};
</script>