<template>
  <div class="product-details">
    <div class="container">
      <div class="product-details-in" v-if="products !== null">
        <div class="left-gallery">
          <div class="row">
            <div class="col-lg-6">


              <div class="product-line">
                <div class="bt-up">
                  <i class="pe-7s-angle-up test" id="next"></i>

                </div>
                <ul class="swap-this">

                  <li v-for="(image, index) in products.product_image" :key="index"
                    :class="isActive === index ? 'active' : ''">
                    <img :src="$baseURL + '/public/images/product/original/' + image.product_image"
                      @click="imageactive(index)" alt="" />
                  </li>
                </ul>
                <div class="bt-down">
                  <i class="pe-7s-angle-down test" id="previous"></i>

                </div>
              </div>
              <div class="product-zoom">
                <div class="pro-type">
                  <span class="tag" v-if="products.image_tost_id == 1">New Arrival hdpe+</span><span class="tag"
                    v-if="products.image_tost_id == 2">Sale</span>
                </div>
                <div class="more-option">
                  <a href="javascript:void(0)" @click="add_wishlist(products, user_info.id)"
                    class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w, index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == products.id + user_info.id"></i>

                    </span>
                    <span v-if="isLoading == products.id + user_info.id" v-show="isLoading"><i
                        class="fa fa-refresh fa-spin"></i></span>
                    <span class="nasa-tip-content">Wishlist</span>
                    <i class="nasa-icon icon-nasa-like bg-warning" v-if="products.is_wishlist == '1'"></i><i
                      class="nasa-icon icon-nasa-like" v-else></i>
                  </a>
                  <!-- <a href="javascript:void(0)" class="wish-btn nasa-tip nasa-tip-right"><span class="nasa-tip-content">Wishlist</span><i class="nasa-icon icon-nasa-like"></i></a> -->
                  <!-- <a href="javascript:void(0)" class="wish-btn nasa-tip nasa-tip-right"><span
                      class="nasa-tip-content">Compare</span><i class="nasa-icon icon-nasa-compare-2"></i></a> -->
                </div>

                <div class="img-zoom">
                  <zoom-on-hover v-for="(image, index) in products.product_image" :key="index" :img-normal="$baseURL +
                    '/public/images/product/original/' +
                    image.product_image
                    " :img-zoom="$baseURL +
    '/public/images/product/original/' +
    image.product_image
    " :scale="2.5" :disabled="false" @loaded="onload" @resized="onresize" :class="isActive === index ? 'active' : ''">
                  </zoom-on-hover>
                  <div class="prev-next">
                    <span class="prev test" v-if="isActive === 0"><i class="pe-7s-angle-left"></i></span>
                    <span class="prev" @click="imageactive(parseFloat(isActive - 1))" v-else><i
                        class="pe-7s-angle-left"></i></span>
                    <span class="next test" v-if="parseFloat(products.product_image.length - 1) === isActive"> <i
                        class="pe-7s-angle-right"></i> </span>
                    <span class="next" @click="imageactive(parseFloat(isActive + 1))" v-else> <i
                        class="pe-7s-angle-right"></i> </span>
                  </div>
                </div>
                <div class="img-bulet">
                  <a href="javascript:void(0)" :class="isActive === index ? 'active' : ''" @click="imageactive(index)"
                    v-for="(image, index) in products.product_image" :key="index"></a>

                </div>


              </div>
            </div>

            <!-- {{ products.color_id }} -->

            <div class="col-lg-6">
              <div class="right-details">
                <div class="head">
                  <h1>{{ products.name }}</h1>
                  <div class="">
                    <p>{{ products.brand_id }}</p>

                    <!-- <img :src="$baseURL + '/public/images/logo/' + products.product_brand.logo" alt="" /> -->
                  </div>

                  <!-- <div class="">
                          <span class="color-round"  >{{  products.color_id}}</span>
                        </div> -->

                  <div className="col-md-4">
                    <div class="price">
                      ₹<span id="offer_price">{{ products.offer_price }}</span>
                      <span hidden="hidden" id="offer_hidden_price">{{ products.offer_price }}</span>
                      ₹<span class="cut" id="actual_price">{{ products.actual_price }} </span>

                    </div>
                  </div>


                  <div className="col-md-4">
                    <div class="nasa-last-sold nasa-crazy-inline">
                      <img class="last-sold-img" src="/images/fire-icon.jpg" alt="Last Sold" width="2%">
                      &nbsp;&nbsp;{{ time }}{{ Math.floor(Math.random() * (1 + 2) + 1) }} sold in last {{
                        Math.floor(Math.random() * (10 - 1 + 1)) + 1 }} hours
                    </div>
                  </div>


                  <!-- <div class="short-dis">
                    <p v-html="products.short_des"></p>
                  </div> -->
                </div>

                <div className="col-md-4">
                  <div class="size-section">

                    <span v-for="(sizes, key) in products.variable_list" :key="key">
                      <input :checked="key == 0 ? 'true' : ''" :id="'radio-' + sizes.id" class="radio-custom"
                        name="radio-group" type="radio" :value="sizes.size_id" />
                      <label :for="'radio-' + sizes.id" :id="'sizename' + sizes.size_id" class="radio-custom-label"
                        @click="selectproductsize(sizes.size_id, sizes.actual_price, sizes.offer_price)">{{
                          sizes.size_name
                        }}</label>
                    </span>

                    <!-- <span class="clear">clear</span> -->
                  </div>
                </div>

                <!-- <div className="col-md-4" v-if="products.product_similar == 1">
                <span>Color</span>
              <div className='color-set'   v-for="(simi, key) in products.similar_products" :key="key" >
                <div v-if="products.id == simi.id" >
                  <a className='activecolor'  :style="'background-color:'+simi.color_id">cvcv</a>
                </div>
                <div v-else>
                  <a href="javascript:void(0)" @click="goSimillarProduct(simi.slug)">
                  <a className='activecolor'  :style="'background-color:'+simi.color_id">cvcv</a>
                </a>
                </div>
              </div>

            </div> -->


                <div className="col-md-4" v-if="products.variable_price.length > 0">
                  {{ products.variation_name }}
                  <div>
                    <div className="form-group">
                      <h4></h4>
                      <select v-model="selectedDisplayShapeText" v-on:change="variablepriceAdd($event)"
                        class="form-select" id="variable">
                        <option disabled value="" selected="selected">select</option>
                        <option v-for="(vprice, key) in products.variable_price" :key="key" :value="vprice.price">
                          <span>{{ vprice.variation_type }}</span>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>



                <div v-else>

                </div>
                <div class="booking-action">

                  <button data-bs-toggle="modal" data-bs-target="#cart-mpdel" class="add-cart"
                    @click="addToCartlog(products)" v-if="user_info">
                    Add to cart
                  </button>
                  <button data-bs-toggle="modal" data-bs-target="#cart-mpdel" class="add-cart"
                    @click="addToCart(products)" v-else>
                    Add to cart
                  </button>

                  <button class="buy-cart" @click="addToCartBuynowlog(products)" v-if="user_info">Buy Now</button>

                  <button class="buy-cart" @click="addToBuynowCart(products)" v-else>
                    Buy Now
                  </button>
                </div>

                <!-- @click="proceedtoCheckout()" -->

                <!-- proceedtoCheckout() {


this.$router.push("/Checkout");
window.location.reload();
}, -->

                <div class="size-popup-area">
                  <button data-bs-toggle="modal" data-bs-target="#sizechart" title="Size Guide">
                    <i class="nasa-icon pe-7s-note2 pe-icon"></i> Size Guide
                  </button>
                  <button data-bs-toggle="modal" data-bs-target="#deliverydetails" title="Delivery & Return">
                    <i class="nasa-icon pe-7s-next-2 pe-icon"></i> Delivery &
                    Return
                  </button>
                </div>


                <div class="estimated-delivery">
                  <i class="nasa-icon icon-nasa-car-2"></i>
                  <strong>Estimated Delivery : </strong> {{ products.delivery_date }}
                </div>
                <div class="estimated-delivery">
                  <i class="nasa-icon pe-7s-smile pe-icon"></i>
                  <strong>19 people &nbsp;</strong> are viewing this right now
                </div>

              </div>
            </div>
          </div>
        </div>



        <div class="more-information-tab">
          <b-tabs content-class="mt-3">
            <b-tab title="Description" active>
              <p v-html="products.description"></p>
            </b-tab>
            <b-tab title="Additional information">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Size</strong></td>
                    <td>
                      <span v-for="(sizes, key) in products.variable_list" :key="key">
                        {{ sizes.size_name }},
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Brand</strong></td>
                    <td>
                      <span>{{ products.brand_id }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-tab>

          </b-tabs>
        </div>
        <div class="sku-show">
          <span> <strong>SKU:</strong> {{ products.sku }} </span>
          <span> / </span>
          <span>
            {{ products.sub_category_without_cat[0].category_name }}
            <strong>Categories:</strong>
            <a href="javascript:void(0)" @click="goProductCat(catSubName.category_name)"
              v-for="catSubName in products.sub_category_without_cat" :key="catSubName.id">
              {{ catSubName.category_name }} ,
            </a>
            <a href="javascript:void(0)" @click="
              goProductSubt(
                catSubName.category_name,
                catSubName.sub_category_name
              )
              " v-for="catSubName in products.sub_category_without_cat" :key="catSubName.id">
              {{ catSubName.sub_category_name }}
            </a>
          </span>
          <span> / </span>
          <span>
            <strong>Tags:</strong>
            <a href="javascript:void(0)"> {{ products.tags }} </a>

          </span>
        </div>
      </div>
    </div>

    <div class="size-chart-pop">
      <div class="modal" id="sizechart" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Size Guide</h5>
              <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <b-tabs content-class="mt-3">
              <b-tab title="DRESSES" active>
                <table class="nasa-info-size-guide">
                  <thead>
                    <tr>
                      <td>Size</td>
                      <td>Chest</td>
                      <td>Waist</td>
                      <td>Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XS</td>
                      <td>34</td>
                      <td>28</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>S</td>
                      <td>36</td>
                      <td>30</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>38</td>
                      <td>32</td>
                      <td>38</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>40</td>
                      <td>34</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>42</td>
                      <td>36</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>2XL</td>
                      <td>44</td>
                      <td>38</td>
                      <td>44</td>
                    </tr>

                    <tr>
                      <td class="padding-top-25 nasa-font-color" colspan="4">
                        <p class="margin-bottom-0 text-center">
                          All measurements are in INCHES
                        </p>
                        <p class="margin-bottom-0 text-center">
                          and may vary a half inch in either direction.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="T-SHIRT">
                <table class="nasa-info-size-guide">
                  <thead>
                    <tr>
                      <td>Size</td>
                      <td>Chest</td>
                      <td>Waist</td>
                      <td>Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2XS</td>
                      <td>32</td>
                      <td>26</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>XS</td>
                      <td>34</td>
                      <td>28</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>S</td>
                      <td>36</td>
                      <td>30</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>38</td>
                      <td>32</td>
                      <td>38</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>40</td>
                      <td>34</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>42</td>
                      <td>36</td>
                      <td>42</td>
                    </tr>

                    <tr>
                      <td class="padding-top-25 nasa-font-color" colspan="4">
                        <p class="margin-bottom-0 text-center">
                          All measurements are in INCHES
                        </p>
                        <p class="margin-bottom-0 text-center">
                          and may vary a half inch in either direction.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
              <b-tab title="BOTTOMS">
                <table class="nasa-info-size-guide">
                  <thead>
                    <tr>
                      <td>Size</td>
                      <td>Chest</td>
                      <td>Waist</td>
                      <td>Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XS</td>
                      <td>34</td>
                      <td>28</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>S</td>
                      <td>36</td>
                      <td>30</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>38</td>
                      <td>32</td>
                      <td>38</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>40</td>
                      <td>34</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>42</td>
                      <td>36</td>
                      <td>42</td>
                    </tr>
                    <tr>
                      <td>2XL</td>
                      <td>44</td>
                      <td>38</td>
                      <td>44</td>
                    </tr>

                    <tr>
                      <td class="padding-top-25 nasa-font-color" colspan="4">
                        <p class="margin-bottom-0 text-center">
                          All measurements are in INCHES
                        </p>
                        <p class="margin-bottom-0 text-center">
                          and may vary a half inch in either direction.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class="size-chart-pop">
      <div class="modal" id="deliverydetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <h2>Delivery</h2>
            <p>
              We ship to all 50 states, Washington DC.<br />
              All orders are shipped with a UPS tracking number.<br />
              Always free shipping for orders over US $200.<br />
              During sale periods and promotions the delivery time may be longer
              than normal.<br />
            </p>

            <h2>Return</h2>
            <p>
              Elessi will accept exchanges and returns of unworn and unwashed
              garments within 30 days of the date of purchase (14 days during
              the sales period), on presentation of the original till receipt at
              any store where the corresponding collection is available within
              the country of purchase.
            </p>
            <p>
              Your return will usually be processed within a week to a week and
              a half. We’ll send you a Return Notification email to notify you
              once the return has been completed.<br />
              Please allow 1-3 business days for refunds to be received to the
              original form of payment once the return has been processed.
            </p>

            <h2>Help</h2>
            <p>
              Give us a shout if you have any other questions and/or
              concerns.<br />
              Email:
              <a href="mailto:contact@mydomain.com">contact@mydomain.com</a><br />
              Phone: +1 (23) 456 789
            </p>
          </div>
        </div>
      </div>
      <!-- {{products.}} -->
      <!-- {{products.product_color.color_name}} -->
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import $ from "jquery";
import wishlistMixin from "../mixins/wishlistMixin.js";
Vue.use(VueAxios, axios);
export default {
  mixins: [wishlistMixin],
  props: ["products"],
  data() {
    return {
      active_el: 2,
      sizeName: "M",
      user_info: "",
      add_quantity: "1",
      onload: "",
      name: "",
      got: "",
      isActive: 0,
      time: '',


    };
  },

  // goSimillarProduct

  methods: {
    imageactive(index) {
      this.isActive = index;
    },
    incrementCounter() {
      this.add_quantity++;
    },
    dcrementCounter() {
      if (this.add_quantity > 1) {
        this.add_quantity--;
      }
    },
    goProductCat(catName) {
      this.$router.push("/Productlist/" + catName);
    },
    goProductSubt(catName, subName) {
      this.$router.push("/Productlist/" + catName + "/" + subName);
    },


    goSimillarProduct(simillar) {
      this.$router.push("/Productdetail/" + simillar);
      // this.$router.reload();
      window.location.reload();
    },



    selectproductsize(sizeID, actual, offer) {
      this.sizeName = document.getElementById("sizename" + sizeID).innerHTML;
      document.getElementById("actual_price").innerHTML = actual;
      document.getElementById("offer_price").innerHTML = offer;
      document.getElementById("offer_hidden_price").innerHTML = offer;

      this.active_el = sizeID;
    },

    variablepriceAdd(e) {
      let selected = e.target.value;
      var prev;
      let flag = 0;

      if (flag == 0) {
        let offer = document.getElementById("offer_hidden_price").innerHTML;
        let final_price1 = parseInt(offer) + parseInt(selected);
        document.getElementById("offer_price").innerHTML = final_price1;
        prev = selected;
        flag = 1;
      }
      else {
        let offer = document.getElementById("offer_price").innerHTML;
        let final_price1 = parseInt(offer) - parseInt(prev) + parseInt(selected);
        document.getElementById("offer_price").innerHTML = final_price1;
        prev = selected;
      }
      // console.log('prev',prev,flag)
    },


    addToCart(products) {
      console.log('products1232', products);

      var checkedValue = document.querySelector(
        ".radio-custom:checked"
      ).value;



      let item = products.variable_list.find(i => i.size_id == checkedValue)
      var variablevalue = document.getElementById("variable") !== null ? document.getElementById("variable").value : '';

      if (item.stock >= this.add_quantity) {
        //return true;
      }
      else {
        this.$fire({
          title: 'Available Qty:' + item.stock,
          text: 'OUT OF STOCK',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
      if (products == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() { },
        });
      } else {
        let offer = document.getElementById("offer_price").innerHTML;

        const data = {
          sizeName: this.sizeName,
          size_id: checkedValue,
          // size_id: this.active_el,
          product_id: products.id,
          product_name: products.name,
          slug: products.slug,

          offer_price: offer,
          product_sizes: products.variable_list,
          product_image: products.product_image[0].product_image,
          variablevalue: variablevalue,
        };
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() { },
        });
      }
    },



    addToCartlog(product) {
      console.log('products1232', product);
      var checkedValue = document.querySelector(
        ".radio-custom:checked"
      ).value;
      var variablevalue = document.getElementById("variable") !== null ? document.getElementById("variable").value : '';
      // console.log('variablevalue',variablevalue);

      let item = product.variable_list.find(i => i.size_id == checkedValue)
      // console.log('cart1',item);

      if (item.stock >= this.add_quantity) {
        //return true;
      }
      else {
        this.$fire({
          title: 'Available Qty:' + item.stock,
          text: 'OUT OF STOCK',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }


      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        size_id: checkedValue,
        qty: this.add_quantity,
        variablevalue: variablevalue,

      };
      console.log('add', addtocartdata);
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });

      //  this.$store.commit("addToCartlog", addtocartdata);
    },
    addToBuynowCart(products) {

      var checkedValue = document.querySelector(
        ".radio-custom:checked"
      ).value;



      let item = products.variable_list.find(i => i.size_id == checkedValue)
      var variablevalue = document.getElementById("variable") !== null ? document.getElementById("variable").value : '';

      if (item.stock >= this.add_quantity) {
        //return true;
      }
      else {
        this.$fire({
          title: 'Available Qty:' + item.stock,
          text: 'OUT OF STOCK',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }
      if (products == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() { },
        });
      } else {
        let offer = document.getElementById("offer_price").innerHTML;

        const data = {
          sizeName: this.sizeName,
          size_id: checkedValue,
          // size_id: this.active_el,
          product_id: products.id,
          product_name: products.name,
          slug: products.slug,

          offer_price: offer,
          product_sizes: products.variable_list,
          product_image: products.product_image[0].product_image,
          variablevalue: variablevalue,
        };
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() { },
        });
      }

      if (this.user_info == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/Checkout");
      }

    },



    addToCartBuynowlog(product) {
      var checkedValue = document.querySelector(
        ".radio-custom:checked"
      ).value;
      var variablevalue = document.getElementById("variable") !== null ? document.getElementById("variable").value : '';
      // console.log('variablevalue',variablevalue);

      let item = product.variable_list.find(i => i.size_id == checkedValue)
      // console.log('cart1',item);

      if (item.stock >= this.add_quantity) {
        //return true;
      }
      else {
        this.$fire({
          title: 'Available Qty:' + item.stock,
          text: 'OUT OF STOCK',
          type: "error",
          timer: 5000,
        }).then((r) => {
          console.log(r.value);
        });
        return false;
      }


      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        size_id: checkedValue,
        qty: this.add_quantity,
        variablevalue: variablevalue,

      };
      console.log('add', addtocartdata);
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });

      if (this.user_info == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/Checkout");
        // window.location.reload();
      }

      //  this.$store.commit("addToCartlog", addtocartdata);
    },
    // <!-- @click="proceedtoCheckout()" -->

    //  proceedtoCheckout() {


    // this.$router.push("/Checkout");
    // window.location.reload();
    // }, 
  },
  created() {
    var token = localStorage.getItem("token");
    console.log(token);
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
        // this.getAddr(this.user_info.id);
      })
      .catch((err) => {
        //  this.$router.push("/login");
        console.log(err.response);
      });
    var date = new Date()
    this.time = date.getHours();
  },
};

$(document).ready(function () {

  var next = $("#next")
  var previous = $("#previous")


  $(document).ready(function () {

    $(next).click(function () {
      $(".swap-this").css({
        'margin-top': '-303px',
        'transition': 'all 1.5s ease-in-out'
      });

    });

    $(previous).click(function () {
      $(".swap-this").css("margin-top", "0");

    });

  });
});


</script>

<style>
.product-details .product-line {}

.product-details .product-line ul {
  margin: 0;
}

.product-details .product-line .bt-up {
  text-align: center;
  color: #515151;
  font-size: 30px;
  margin-bottom: 4px;
  position: absolute;
  top: 0;
  width: 100%;
}

.product-details .product-line .bt-down {
  text-align: center;
  color: #515151;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  margin-top: 4px;
  width: 100%;
}

.product-details .product-line .bt-up i,
.product-details .product-line .bt-down i {
  display: block;
  cursor: pointer;
  line-height: 16px;
}

.product-details .product-details-in .left-gallery .product-zoom .img-zoom {
  position: relative;
  cursor: zoom-in;
}

.product-details .product-details-in .left-gallery .product-zoom .more-option {
  position: absolute;
  z-index: 10;
  padding: 14px 0px;
}

.product-details .product-details-in .left-gallery .product-zoom .more-option a {
  height: 35px;
  width: 35px;
  display: block;
  border: none;
  margin: 0;
  padding: 3px;
  text-align: center;
  font-size: 21px;
  letter-spacing: initial;
  border-radius: 6px;
  transition: all 210ms ease;
  transform: translateX(10px);
  background-color: #ffffff96;
  line-height: 39px;
  color: #939393;
  margin-bottom: 8px;
}

.product-details .product-details-in .left-gallery .product-zoom .more-option a:hover {
  background-color: #fff;
}

.product-details .product-details-in .left-gallery .product-zoom .prev-next {
  position: absolute;
  top: 292px;
  z-index: 10;
  width: 100%;
  height: 0;
}

.product-details .product-details-in .left-gallery .product-zoom .prev-next .prev {
  float: left;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.5s;
  margin-left: -63px;
  font-size: 60px;
}

.product-details .product-details-in .left-gallery .product-zoom .prev-next .next {
  float: right;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.5s;
  margin-right: -63px;
  font-size: 60px;
}

.product-details .product-details-in .left-gallery .product-zoom:hover .prev-next .prev {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.5s;
  margin-left: 0px;
}

.product-details .product-details-in .left-gallery .product-zoom:hover .prev-next .next {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.5s;
  margin-right: 0px;
}

.product-details .product-details-in .left-gallery .product-zoom .pro-type {
  position: absolute;
  right: 15px;
  top: 14px;
  z-index: 10;
}

.product-details .product-details-in .left-gallery .product-zoom .pro-type .tag {
  display: block;
  padding: 3px 10px;
  z-index: 4;
  background: #ff0000;
  color: #fff;
  float: right;
  clear: both;
  font-size: 70%;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.8;
  border-radius: 4px;
  margin-top: 7px;
}

.product-details .product-details-in .left-gallery .product-zoom .img-bulet {
  display: flex;
  margin-top: 22px;
  align-items: center;
  justify-content: center;
}

.product-details .product-details-in .left-gallery .product-zoom .img-bulet a {
  width: 8px;
  height: 8px;
  background-color: #C0C0C0;
  display: block;
  margin: 0px 6px;
  border-radius: 30px;
  transition: 0.5s;
}

.product-details .product-details-in .left-gallery .product-zoom .img-bulet a.active {
  width: 33px;
  background-color: #fe4d01;
}

@media only screen and (max-width: 980px) {}


* {
  padding: 0;
  margin: 0;
}

body {
  font-family: verdana;
  color: #121212;
  background: #ffffff;
}

hgroup {
  text-transform: uppercase;
  padding: 1em 0;
  text-align: center;
}

hgroup h1 {
  font-size: 2em;
  color: #9b59b6;
  font-weight: 800;
  letter-spacing: .1em;
  padding: .2em 0;
}

hgroup h2 {
  font-weight: 300;
  font-size: 1em;
  letter-spacing: .4em;
}

#image-slider {
  display: block;
  margin: 0 auto;
  width: 500px;
  height: 300px;
  overflow: hidden;
  background: #fff;
}

#image-slider ul {
  list-style: none;
}

#image-slider ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

#image-slider ul img {
  width: 100%;
  max-width: 500px;
  height: 300px;
}

.controls {
  padding: 1em 0;
  width: 500px;
  display: block;
  margin: 0 auto;
}

.controls a {
  text-decoration: none;
  color: #34495e;
}

.controls a:hover {
  cursor: pointer;
}

.img-up {
  margin-top: -300px;
}

.color-set {
  display: flex;
  align-items: center;
  margin-top: 26px;
}

.activecolor {
  box-shadow: 0 0 1px 1px #262525;

  display: inline-block;
  width: 23px;
  height: 23px;
  margin-left: 15px;
  border-radius: 100%;
}
</style>