<template>
  <div class="home-exclusive">
    <div class="container">
      <!-- {{ products.sub_category_without_cat}} -->
      <div class="head">
        <h2>Related Product</h2>
      </div>
<!-- {{this.$store.state.getwishlist}} -->
      <div class="exclusive-sec" v-if="dealsproduct.length > 0">
        <carousel
          v-bind:items="5"
          v-bind:autoplay="true"
          v-bind:nav="true"
          v-bind:dots="false"
          v-bind:loop="false"
          v-bind:navText="true"
          v-bind:responsive="{
            0: { items: 2.2, nav: false, margin: 10 },
            600: { items: 3.2, nav: false, margin: 10 },
            1080: { items: 5, nav: true, margin: 10 },
          }"
        >
         <!-- v-bind:navText="true" -->
       
      
          <div
            class="ex-block"
            v-for="(product, key) in dealsproduct"
            :key="key" 
          >
          <!-- {{ product }} -->
        
            <div class="product-thumbnil">
              <div class="pro-type">
                <span class="tag" v-if="product.image_tost_id == 1">New Arrival hdpe+</span>
                <span class="tag" v-if="product.image_tost_id == 2">Sale</span>
              </div>
              <div class="product-items">
                <a href="javascript:void(0)"
                      @click="add_wishlist(product, user_info.id)"
                      class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w,index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == product.id + user_info.id"
                      ></i
                      >
                    </span>
                     <span
                        v-if="isLoading == product.id + user_info.id"
                        v-show="isLoading"
                        ><i class="fa fa-refresh fa-spin"></i
                      ></span>
                      <span class="nasa-tip-content">Wishlist</span>
                      <i
                        class="nasa-icon icon-nasa-like bg-warning"
                        v-if="product.is_wishlist == '1'"
                      ></i
                      ><i class="nasa-icon icon-nasa-like" v-else></i>
                    </a>

                 

                 
                <!-- <a href="#" class="quick-btn nasa-tip nasa-tip-left">
                  <span class="nasa-tip-content">Quick View</span>
                  <i class="nasa-icon pe-7s-look"></i
                ></a>
                <a href="#" class="compare-btn nasa-tip nasa-tip-left">
                  <span class="nasa-tip-content">Compare</span>
                  <i class="pe-7s-repeat"></i
                ></a> -->
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#cart-mpdel"
                  class="crt-btn"
                >
                  <span @click="addToCartlog(product)" v-if="user_info"
                    >Add to Cart</span
                  >
                  <span @click="addToCart(product)" v-else>Add to Cart</span>
                  <i class="mdi mdi-plus"></i
                ></a>
              </div>
              <div class="img-section" @click="productdetail(product.slug)">
                <img
                  class="fst-img"
                  :src="
                    $baseURL +
                    '/public/images/product/original/' +
                    product.product_image[0].product_image
                  "
                  alt=""
                />
                <img
                  v-if="product.product_image[1]"
                  class="sec-img"
                  :src="
                    $baseURL +
                    '/public/images/product/original/' +
                    product.product_image[1].product_image
                  "
                  alt=""
                />
              </div>
              <div class="product-info">
                <a href="#" class="pro-name">{{ product.name }}</a>
                <div class="star-rating">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star-o" aria-hidden="true"></i>
                </div>

                <div class="price-wrap">
                  <span class="cut-price">₹ {{ product.actual_price }}</span
                  ><span class="price">₹ {{ product.offer_price }}</span>
                </div>
                <div class="size">
                      <!-- <span>Size</span> -->
                      <span
                        v-for="(sizes,index) in product.variable_list"
                        :key="index" >
                        <a :class="['mysized'+product.id,{ 'active' : index === 0 }]"
                        :id="'sizename'+product.id+','+sizes.size_id"
                        @click="selectproductsize(product.id,sizes.size_id)"
                        href="javascript:void(0)">{{ sizes.size_name }}</a>
                        </span>
                        </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import wishlistMixin from "../mixins/wishlistMixin.js";
Vue.use(VueAxios, axios);
export default {
  mixins: [wishlistMixin],
  props: ["products"],
  components: { carousel },
  data() {
    return {
      active_el: 2,
      sizeName: "M",
      dealsproduct: "",
      user_info: "",
    };
  },
  methods: {
    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
      window.location.reload();
    },
   addToCart(product) {
        var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
        var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
        var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;

      
      if (product == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
      } else {
        const data = {
          sizeName: size_name,
          size_id:size_id,
          product_id: product.id,
          product_name: product.name,
          slug: product.slug,
          offer_price: product.offer_price,
          product_image: product.product_image[0].product_image,
        };
         
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() {},
        });
      }
    },
     addToCartlog(product) {
      var sizeName = document.getElementsByClassName('mysized'+product.id+' active');
      console.log('sizename',sizeName)
      var size_id=(sizeName[0].id).replace('sizename'+product.id+',',"");
      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        //  size_id: product.product_size[0].size_id,
         size_id: size_id,
         qty: "1",
      };
     
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
     selectproductsize(product_id,sizeID) {
         var selectedSize = document.getElementsByClassName('mysized'+product_id+' active');//mysized"+product_id
  if(selectedSize !=null){
   [].forEach.call(selectedSize, function(elems) { 
        elems.classList.remove("active");
    });
  }
  var element = document.getElementById("sizename"+product_id+','+sizeID);
  if(element!==null){
  element.classList.add("active");
  }
 
    },
  },

  created() {
    var token = localStorage.getItem("token");
    let uri = window.location.href.split("/");
    console.log('uri',uri[4])
    axios
      .get("/api/related-products/" + uri["4"], {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.dealsproduct = res.data.data;
        this.length = 5;
      })
      .catch((err) => {
        console.log(err.response);
      });

    
    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  computed: {

    // getwishlist() {
    //   let id = 0;
    //   for (let item of this.$store.state.getwishlist) {
    //     id = item;
    //     return id;
    //   }
    // },
    //  getitemssubtotalp() {
    //   let total =[];
    //   for (let item of this.$store.state.getwishlist) {
    //     total += JSON.stringify(item);
        
       
    //   }
    //   return total; 
    // },
  

  },
  };
</script>

<style >
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-prev::after {
  content: "\F0141";
  font-family: "Material Design Icons";
}
.home-exclusive .exclusive-sec .owl-carousel .owl-nav .owl-next::after {
  content: "\F0142";
  font-family: "Material Design Icons";
}
</style>