
<template>
  <div class="inner-page">
    <div class="container">
      <div class="content-section">
        <h2>RETURN & EXCHANGE POLICY</h2>
        <p>
         You can only Exchange the piece with same brands if available at that time and available in our stock. No refunds.
        </p>
        <h3>Exchange Policy : Within 4 Days you can Exchange.</h3>
        <p>
         We strive to give you a good customer experience each time you shop with us, if you are not 100% satisfied with your purchase, you can buy any product in exchange.
        </p>
        <p>You need to drop an email at kabircollections@gmail.com within a period of 4 days, from the date of delivery of Product.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

style


