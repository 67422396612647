import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-flash-message/dist/vue-flash-message.min.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/css/main.css'
import './assets/css/cart.css'
import 'animate.css';
import './assets/css/hover-min.css'
import './assets/css/font-awesome.min.css'
import './assets/css/fonts-min.css'
import './assets/css/materialdesignicons.min.css'
import './assets/css/pe-icon-7-stroke.css'
import './assets/css/productlist.css'
import './assets/css/responsive.css'
import ProductZoomer from 'vue-product-zoomer'
import axios from "axios";
import ZoomOnHover from "vue-zoom-on-hover"
import VueFlashMessage from 'vue-flash-message';
import VueSimpleAlert from "vue-simple-alert";
// import loader from "vue-ui-preloader";
// Vue.use(loader);
Vue.use(VueFlashMessage);
Vue.use(VueSimpleAlert)
Vue.use(ZoomOnHover)

// Vue.prototype.$homeBaseURL ="https://www.gibazar.com/";
// axios.defaults.baseURL = 'https://www.gibazar.com/simple-lara-backend';
// Vue.prototype.$baseURL = "https://www.gibazar.com/simple-lara-backend";

// Vue.prototype.$homeBaseURL ="https://www.bwitchedzone.com/";
// axios.defaults.baseURL = 'https://www.bwitchedzone.com/backend';
// Vue.prototype.$baseURL = "https://www.bwitchedzone.com/backend";     http://localhost:8080/

 

// Vue.prototype.$homeBaseURL = "http://localhost:8080/"; 
// Vue.prototype.$homeBaseURL = "http://192.168.1.13:8080/"; 
// Vue.prototype.$baseURL = "http://192.168.1.66/kabir-backend";
// axios.defaults.baseURL = 'http://192.168.1.66/kabir-backend';



Vue.prototype.$homeBaseURL = "https://www.kabircollections.com/"; 
Vue.prototype.$baseURL = "https://www.kabircollections.com/kabir-backend";
axios.defaults.baseURL = 'https://www.kabircollections.com/kabir-backend';

Vue.use(ProductZoomer)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

