<template>
  <div class="home-product-show">
    
    <div class="container">
      <div class="pro-head">
        <h3>SHOP NOW</h3>
      </div>

      <div class="gallery_product">
        <b-tabs content-class="mt-3">
          <b-tab title="NEW ARRIVALS" active @click="getshopproduct('arrival')">
            <div class="row g-2 g-md-4 g-lg-3">
              <div
                class="product-home"
                v-for="(product, index) in productsshoploaded"
                :key="index"
                :id="'productss'+product.id"
              >
                <div class="product-thumbnil">
                  <div class="pro-type">
                    <span class="tag" v-if="product.image_tost_id == 1">New Arrival </span>
                    <span class="tag" v-if="product.image_tost_id == 2">Sale</span>
                  </div>

                  <div class="product-items">
                    <a
                      href="javascript:void(0)"
                      @click="add_wishlist(product, user_info.id)"
                      class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w,index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == product.id + user_info.id"
                      ></i
                      >
                    </span>
                     <span
                        v-if="isLoading == product.id + user_info.id"
                        v-show="isLoading"
                        ><i class="fa fa-refresh fa-spin"></i
                      ></span>
                      <span class="nasa-tip-content">Wishlist</span>
                      <i
                        class="nasa-icon icon-nasa-like bg-warning"
                        v-if="product.is_wishlist == '1'"
                      ></i
                      ><i class="nasa-icon icon-nasa-like" v-else></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#cart-mpdel"
                      class="crt-btn"
                    >
                      <span @click="addToCartlognewarrivals(product)" v-if="user_info"
                        >Add to Cart</span
                      >
                      <span @click="addToCartnewarrivals(product)" v-else
                        >Add to Cart</span
                      >
                      <i class="mdi mdi-plus"></i
                    ></a>
                  </div>
                  <div class="img-section" @click="productdetail(product.slug)">
                    <img
                      class="fst-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[0].product_image
                      "
                      alt=""
                    />
                    <img
                      v-if="product.product_image[1]"
                      class="sec-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[1].product_image
                      "
                      alt=""
                    />
                  </div>

                  <div class="product-info">
                    <a href="javascript:void(0)" class="pro-name" @click="productdetail(product.slug)">{{ product.name }}</a>
                    <div class="star-rating">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star-o" aria-hidden="true"></i>
                    </div>

                    <!-- {{ product }} -->

                    <div class="price-wrap">
                      ₹<span :id="'actual_price' + product.id " class="cut-price"> {{ product.actual_price }}</span >
                        ₹<span :id="'offer_price'+ product.id " class="price"> {{ product.offer_price }}</span>
                        <span hidden="hidden" :id="'offer_hidden_price' + product.id">{{ products.offer_price }}</span>
                    </div>
                    <div class="size">
                      <!-- <span>Size {{active_el}} {{sizeName}} </span> -->
                      
                      <span
                        v-for="(sizes,index) in product.variable_list"
                        :key="index"
                     ><a
                           :class="['mysizedee'+product.id,{ 'active' : index === 0 }]"
                          :id="'sizenameed'+product.id+','+sizes.size_id"
                          @click="selectproductsized(product.id,sizes.size_id, sizes.actual_price, sizes.offer_price)"
                          href="javascript:void(0)">{{ sizes.size_name }}</a>
                          </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <!-- <b-tab title="WOMEN" @click="getshopproduct('products')">
            <div class="row">

              <div
                class="product-home"
                v-for="(product, index) in productsshoploaded"
                :key="index"
              >
                <div class="product-thumbnil">
                  <div class="pro-type">
                    <span class="tag" v-if="product.image_tost_id == 1">New Arrival</span>
                    <span class="tag" v-if="product.image_tost_id == 2">Sale</span>
                  </div>
                  
                  <div class="product-items">
                   <a
                      href="javascript:void(0)"
                      @click="add_wishlist(product, user_info.id)"
                      class="wish-btn nasa-tip nasa-tip-left">
                    <span v-for="(active_w,index) in active_ws" :key="index">
                      <i class="nasa-icon icon-nasa-like bg-warning" v-if="active_w == product.id + user_info.id"
                      ></i>
                    </span>
                     <span
                        v-if="isLoading == product.id + user_info.id"
                        v-show="isLoading"
                        ><i class="fa fa-refresh fa-spin"></i
                      ></span>
                      <span class="nasa-tip-content">Wishlist</span>
                      <i
                        class="nasa-icon icon-nasa-like bg-warning"
                        v-if="product.is_wishlist == '1'"
                      ></i
                      ><i class="nasa-icon icon-nasa-like" v-else></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#cart-mpdel"
                      class="crt-btn"
                    >
                      <span @click="addToCartlog(product)" v-if="user_info"
                        >Add to Cart</span
                      >
                      <span @click="addToCart(product)" v-else
                        >Add to Cart</span
                      >
                      <i class="mdi mdi-plus"></i
                    ></a>
                  </div>
                  <div class="img-section" @click="productdetail(product.slug)">
                    <img
                      class="fst-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[0].product_image
                      "
                      alt=""
                    />
                    <img
                      v-if="product.product_image[1]"
                      class="sec-img"
                      :src="
                        $baseURL +
                        '/public/images/product/original/' +
                        product.product_image[1].product_image
                      "
                      alt=""
                    />
                  </div>

                  <div class="product-info">
                    <a href="javascript:void(0)" class="pro-name" @click="productdetail(product.slug)">{{ product.name }}</a>
                    <div class="star-rating">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star-o" aria-hidden="true"></i>
                    </div>
                    <div class="price-wrap">
                      ₹<span  :id="'actual_price' + product.id + 'women' "  class="cut-price"> {{ product.actual_price }}</span>
                      ₹<span :id="'offer_price'+ product.id + 'women' " class="price"> {{ product.offer_price }}</span>
                      <span hidden="hidden" :id="'offer_hidden_price' + product.id + 'women'">{{ product.offer_price }}</span>
                    </div>
                    <div class="size">
                  
                      <span
                        v-for="(sizes,index) in product.variable_list"
                        :key="index"
                      >
                      <a :class="['mysizede'+product.id,{ 'active' : index === 0 }]"
                          :id="'sizenamee'+product.id+','+sizes.size_id"   
                          @click="selectproductsize(product.id,sizes.size_id,sizes.actual_price, sizes.offer_price)"
                          href="javascript:void(0)">{{ sizes.size_name }}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab> -->
        </b-tabs>
      </div>
       <div class="loader-btn" v-if="parseInt(this.products.length-this.length)>-1">
        <!-- <a href="javascript:void(0)" @click="loadMore"> -->
        <a href="javascript:void(0)" @click="goProductCat">
          <!-- <i class="nasa-icon compare-icon icon-nasa-refresh"></i> -->
          View More 
          <!-- ({{parseInt(this.products.length-this.length) }}) Item... -->
        </a>
      </div>
 
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import wishlistMixin from "../mixins/wishlistMixin.js";
Vue.use(VueAxios, axios);


export default {
  mixins: [wishlistMixin],
  data() {
    return {
      active_el: '',
      sizeName: "M",
      trendingProducts: "",
      featuredProducts: "",
      best_sellings: "",
      top_rateds: "",
      products: "",
      length: "",
      user_info: "",
      isActivesize:0,
    };
  },

  methods: {
    loadMore() {
      if (this.length > this.products.length) return;
      this.length = this.length + 10;
    },
    getshopproduct(CatName) {
      var token = localStorage.getItem("token");
      if(CatName == "arrival"){
         axios
        .get("/api/new-arrival-products", {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          this.products = res.data.data;
          this.length = 10;
        })
        .catch((err) => {
          console.log(err.response);
        });
      }else{
         axios
        .get("/api/category-products/" + CatName, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          this.products = res.data.data;
          this.length = 10;

          // this.length = 0;
          // console.log(this.length);
        })
        .catch((err) => {
          console.log(err.response);
        });
      }
     
        
    },
    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
    },
    goProductCat() {
      this.$router.push("/Newarrivalproductlist/");
    },
    addToCart(product) {
      var sizeName = document.getElementsByClassName('mysizede'+product.id+' active');
      console.log('cbc',sizeName)
      var size_id=(sizeName[0].id).replace('sizenamee'+product.id+',',"");
      var size_name = document.getElementById('sizenamee'+product.id+','+size_id).innerHTML;
      let item =product.variable_list.find(i =>i.size_id == size_id)
          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }
      //console.log(size_name);
      if (product == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
      } else {
        const data = {
          sizeName: size_name,
          size_id:size_id,
          product_id: product.id,
          product_name: product.name,
          slug: product.slug,
          offer_price: product.offer_price,
          product_sizes: product.variable_list,
          product_image: product.product_image[0].product_image,
        };
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() {},
        });
      }
    },
    addToCartlog(product) {
      var sizeName = document.getElementsByClassName('mysizede'+product.id+' active');
      var size_id=(sizeName[0].id).replace('sizenamee'+product.id+',',"");
      let item =product.variable_list.find(i =>i.size_id == size_id)

          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }
      //var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;

      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        //  size_id: product.product_size[0].size_id,
         size_id: size_id,
         qty: "1",
      };
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
      addToCartnewarrivals(product) {
        var sizeName = document.getElementsByClassName('mysizedee'+product.id+' active');
        var size_id=(sizeName[0].id).replace('sizenameed'+product.id+',',"");
        var size_name = document.getElementById('sizenameed'+product.id+','+size_id).innerHTML;
       

      let item =product.variable_list.find(i =>i.size_id == size_id)

          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }
      //console.log(size_name);
      if (product == "") {
        this.flash("Select any Products", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
      } else {
        const data = {
          sizeName: size_name,
          size_id:size_id,
          product_id: product.id,
          product_name: product.name,
          slug: product.slug,
          offer_price: product.offer_price,
           product_sizes: product.variable_list,
          product_image: product.product_image[0].product_image,
        };
        this.$store.commit("addToCart", data);
        this.flash("Product Added Successflly", "success", {
          timeout: 3000,
          beforeDestroy() {},
        });
      }
    },
    addToCartlognewarrivals(product) {
         var sizeName = document.getElementsByClassName('mysizedee'+product.id+' active');
         console.log('sizeName',sizeName)
      var size_id=(sizeName[0].id).replace('sizenameed'+product.id+',',"");
      let item =product.variable_list.find(i =>i.size_id == size_id)

          if(item.stock >= 1){
          //return true;
          }
          else {
              this.$fire({
                        title:'Available Qty:'+item.stock,
                        text: 'OUT OF STOCK',
                        type: "error",
                        timer: 5000,
                      }).then((r) => {
                        console.log(r.value);
                      });
                    return false;
        }
      //var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;

      const addtocartdata = {
        user_id: this.user_info.id,
        product_id: product.id,
        // size_id: this.active_el,
        //  size_id: product.product_size[0].size_id,
         size_id: size_id,
         qty: "1",
      };
      axios
        .post("/api/add-to-cart", addtocartdata)
        .then((res) => {
          console.log(res);
          this.$store.commit("getusercartitem", this.user_info.id);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },


    selectproductsize(product_id,sizeID,actual, offer) {
      // console.log(document.getElementById('offer_price'+product_id))

  var selectedSize = document.getElementsByClassName('mysizede'+product_id+' active');//mysized"+product_id
  if(selectedSize !=null){
   [].forEach.call(selectedSize, function(elems) { 
        elems.classList.remove("active");
  
    });
  }
  var element = document.getElementById("sizenamee"+product_id+','+sizeID);
  if(element!==null){
  element.classList.add("active");
  document.getElementById('actual_price'+product_id +'women').innerHTML = actual;
  document.getElementById("offer_price"+product_id + 'women').innerHTML = offer;
  document.getElementById("offer_hidden_price"+product_id +'women' ).innerHTML = offer;
  }
 



  
  // var element = document.getElementById("sizename"+product_id+sizeID);
  // var el = document.getElementsByClassName("mysized"+product_id);
  // var elems = document.querySelector(".active");
  // if(elems !=null){
  // //  elems.classList.remove("active");
  //    var actives = document.querySelector("mysized"+product_id+'.active');
  //  actives.classList.remove("active");
  //   // [].forEach.call(actives, function(elems) {  
  //   //     elems.classList.remove("active");
  //   // });
  // }
  // //el.classList.remove("active");
  // element.classList.add("active");
  // console.log(el);
  

 
 // console.log(element);
      //e.classList.add("active");
      // this.isActivesize = index;
      // this.sizeName = document.getElementById("sizename"+product_id+sizeID).innerHTML;
      // this.active_el = sizeID;
    },
    selectproductsized(product_id,sizeID,actual, offer) {

        var selectedSize = document.getElementsByClassName('mysizedee'+product_id+' active');//mysized"+product_id
        if(selectedSize !=null){
          [].forEach.call(selectedSize, function(elems) { 
              elems.classList.remove("active");
             
          });
        }
        var element = document.getElementById("sizenameed"+product_id+','+sizeID);
        if(element!==null){
        element.classList.add("active");
        document.getElementById('actual_price'+product_id).innerHTML = actual;
              document.getElementById("offer_price"+product_id).innerHTML = offer;
              document.getElementById("offer_hidden_price"+product_id).innerHTML = offer;
        }
 
    },
    //  addToCartmen(product) {
    //   var sizeName = document.getElementsByClassName('mysizedeedd'+product.id+' active');
    //   var size_id=(sizeName[0].id).replace('sizenameedd'+product.id+',',"");
    //   var size_name = document.getElementById('sizenameedd'+product.id+','+size_id).innerHTML;
    //   let item =product.product_size.find(i =>i.size_id == size_id)

    //       if(item.stock >= 1){
    //       //return true;
    //       }
    //       else {
    //           this.$fire({
    //                     title:'Available Qty:'+item.stock,
    //                     text: 'OUT OF STOCK',
    //                     type: "error",
    //                     timer: 5000,
    //                   }).then((r) => {
    //                     console.log(r.value);
    //                   });
    //                 return false;
    //     }

    //   //console.log(size_name);
    //   if (product == "") {
    //     this.flash("Select any Products", "error", {
    //       timeout: 3000,
    //       beforeDestroy() {},
    //     });
    //   } else {
    //     const data = {
    //       sizeName: size_name,
    //       size_id:size_id,
    //       product_id: product.id,
    //       product_name: product.name,
    //       slug: product.slug,
    //       offer_price: product.offer_price,
    //        product_sizes: product.product_size,
    //       product_image: product.product_image[0].product_image,
    //     };
    //     this.$store.commit("addToCart", data);
    //     this.flash("Product Added Successflly", "success", {
    //       timeout: 3000,
    //       beforeDestroy() {},
    //     });
    //   }
    // },
    // addToCartlogmen(product) {
    //      var sizeName = document.getElementsByClassName('mysizedeedd'+product.id+' active');
    //      var size_id=(sizeName[0].id).replace('sizenameedd'+product.id+',',"");
    //      let item =product.product_size.find(i =>i.size_id == size_id)

    //       if(item.stock >= 1){
    //       //return true;
    //       }
    //       else {
    //           this.$fire({
    //                     title:'Available Qty:'+item.stock,
    //                     text: 'OUT OF STOCK',
    //                     type: "error",
    //                     timer: 5000,
    //                   }).then((r) => {
    //                     console.log(r.value);
    //                   });
    //                 return false;
    //       }


    //   //var size_name = document.getElementById('sizename'+product.id+','+size_id).innerHTML;

    //   const addtocartdata = {
    //     user_id: this.user_info.id,
    //     product_id: product.id,
    //     // size_id: this.active_el,
    //     //  size_id: product.product_size[0].size_id,
    //      size_id: size_id,
    //      qty: "1",
    //   };
    //   axios
    //     .post("/api/add-to-cart", addtocartdata)
    //     .then((res) => {
    //       console.log(res);
    //       this.$store.commit("getusercartitem", this.user_info.id);
    //     })
    //     .catch((err) => {
    //       console.log(err.response);
    //     });
    // },
    selectproductsizedd(product_id,sizeID) {

        var selectedSize = document.getElementsByClassName('mysizedeedd'+product_id+' active');//mysized"+product_id
        if(selectedSize !=null){
          [].forEach.call(selectedSize, function(elems) { 
              elems.classList.remove("active");
          });
        }
        var element = document.getElementById("sizenameedd"+product_id+','+sizeID);
        if(element!==null){
        element.classList.add("active");
        }
 
    },
  },
  created() {
    
    var token = localStorage.getItem("token");
    console.log('token',token)
    axios
      .get("/api/new-arrival-products", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.products = res.data.data;
        this.length = 10;
        
        // this.isLoading = false;
      })
      .catch((err) => {
        console.log(err.response);
      });

    axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
        console.log("cbc",this.user_info)
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
  computed: {
    productsshoploaded() {
      return this.products.slice(0, 10);
    },
  },
  
};


</script>

