<template>
  <div class="home">
    <Header v-if="this.categories.length > 0" :categories="categories" :srhproducts="srhproducts" />
    <Breadcrumbs />
    <Otppage />

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Otppage from "../components/Auth/Otppage.vue";
import Footer from "../components/Footer.vue";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  name: "Otp",
  components: {
    Header,
    Breadcrumbs,
    Otppage,
    Footer,
  },
  data() {
    return {
      categories: "",
      srhproducts:[],
    };
  },
  created() {
    axios
      .get("/api/homecategory", {})
      .then((res) => {
        this.categories = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });

        axios
      .get("/api/product-list", {})
      .then((res) => {
        this.searchproducts = res.data.data;
        this.searchproducts .forEach((doc) => {
        this.srhproducts.push(doc);
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>