<template>
  <div className="invoice-set" id="app">
    <div className="table-1" id="printable-content">
      <div id="invoice-app">
        <div class="text" align="center">
          <table width="80%" style="margin: 0 auto">
            <thead>
              <tr>
                <img src="/images/logo.png" alt="Kabir-Collection" height="50px" width="100px" />
                <th colspan="8" style="text-align: center">
                  <!-- <h3>Tax Invoice</h3> -->
                </th>
              </tr>
              <tr>

                <td colspan="4">
                  <h5 style="font-size: 14px;">
                    <strong>Sold by:- </strong> Kabir Collection <br />
                    <b> Ship-From:- </b>B-1/8, Krishna Nagar, Delhi-51
                    <br />
                    <b>Contact Number:-</b> +91-9971010391, 9871371375<br>
                    <!-- <b>Order NO:-</b>1000{{ orderitems.order.id }} -->
                  </h5>
                </td>
                <td colspan="4" style="text-align: right; padding-right: 8px">
                  <span><b> Invoice Number:-</b> <br>
                    <b>Order NO:-</b>1000{{ orderitems.order.id }}
                  </span>
                </td>
              </tr>
              <tr>

                <td colspan="4">
                  <!-- <b>Order Date: {{order.order_date}}</b> -->
                  <b>Order Date: {{ orderitems.order.order_date }}</b>
                </td>
                <td style="font-size: 14px; " colspan="2">
                  <b>Bill To</b> <br>
                  <b><strong>Name:- </strong>{{ orderitems.order.name }}</b> <br>
                  <strong>Email Id:- </strong> {{ orderitems.order.email }} <br>

                  <strong>Address:
                  </strong>{{ orderitems.order.house_and_street_no }},{{ orderitems.order.town_city }},{{ orderitems.order.state }},{{ orderitems.order.pincode }},{{ orderitems.order.locality }},{{ orderitems.order.landmark }},
                  <br> <b>Contact Number :-</b> {{ orderitems.order.phone_no }}.
                </td>
                <td style="font-size: 14px; " colspan="2">
                  <b>Ship To</b> <br>
                  <b><strong>Name:- </strong>{{ orderitems.order.name }}</b><br>
                  <strong>Email Id:- </strong>{{ orderitems.order.email }}<br>
                  <strong>Address:
                  </strong>{{ orderitems.order.house_and_street_no }},{{ orderitems.order.town_city }},{{ orderitems.order.state }},{{ orderitems.order.pincode }},{{ orderitems.order.locality }},{{ orderitems.order.landmark }},
                  <br> <b>Contact Number :-</b> {{ orderitems.order.phone_no }}.
                </td>

              </tr>
              <tr>
                <td style="font-size: 13px" colspan="2">
                  <b>GSTIN -</b> 00000000000000
                </td>


              </tr>
              <tr>
                <th style="     width: 36px;
                    border-top: 1px solid #000; 
                    border-bottom: 1px solid #000;
                  ">
                  #
                </th>
                <th colspan="3" style="
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                  ">
                  Item
                </th>
                <th style="
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                  ">
                  Qty
                </th>

                <th style="
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                  ">
                  Size
                </th>
                <th style="
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                  ">
                  Taxable Value
                </th>

                <th style="
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                  ">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="service" v-for="(obj, index) in orderitems.order_item_list" :key="index">
                <td class="quantity" align="left">{{ index + 1 }}</td>

                <td colspan="3" class="description" align="left">{{ obj.product_name }}</td>
                <td class="price" align="left">{{ obj.quantity }}</td>
                <!-- <td class="price" align="left">{{obj.quantity}} </td> -->
                <td class="price" align="left">{{ obj.size_name }}</td>
                <td class="price" align="left"> {{ parseFloat((obj.offer_price * obj.quantity)).toFixed(2) }}</td>
                <td class="price" align="left">{{ parseFloat(obj.quantity * obj.offer_price).toFixed(2) }}</td>
              </tr>
              <!-- <tr> 
                <td>No data found</td>
              </tr> -->
              <!-- <tr>
                <td
                  style="
                    text-align: right;
                    border-top: 1px solid #000;
                    padding: 0;
                    margin: 0;
                  "
                  colspan="7"
                >
                  <h3 style="padding: 0; margin: 0;     font-size: 16px; " >Subtotal</h3>
                </td>
                <td
                  colspan="3"
                  style="
                    text-align: right;
                    border-top: 1px solid #000;
                    padding: 0;
                    margin: 0;
                  "
                >
                  <h3 style="padding: 0; margin: 0; 
    font-size: 19px;">
                      INR  {{parseFloat(orderitems.order.total_offer_price).toFixed(2)}}
                      
                      </h3>
                </td>
              </tr> -->
              <!-- <tr>
                <td
                  style="
                    text-align: right;
                    border-top: 1px solid #000;
                    padding: 0;
                    margin: 0;
                  "
                  colspan="4"
                >
                  <h4 style="padding: 0; margin: 0">Shipping Charges:(COD)</h4>
                </td>
                <td
                  colspan="4"
                  style="text-align: right;border-top: 1px solid #000text-align: right;border-top: 1px solid #000;padding:0;margin: 0"
                >
                
                  <h4>INR {{parseFloat(order.shipping_charge).toFixed(2)}}</h4>
                </td>
              </tr> -->
              <!-- <tr>
                <td
                  style="
                    text-align: right;
                    border-top: 1px solid #000;
                    padding: 0;
                    margin: 0;
                  "
                  colspan="4"
                >
                  <h4 style="padding: 0; margin: 0">Coupon Amount:</h4>
                </td>
                <td
                  colspan="4"
                  style="text-align: right;border-top: 1px solid #000text-align: right;border-top: 1px solid #000;padding:0;margin: 0"
                >
                  <h4>INR {{parseFloat(order.coupon_amount).toFixed(2)}}</h4>
                </td>
              </tr>pare -->
              <tr v-if="orderitems.order.coupon_amount !== 0">
                <td colspan="7"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0;margin:0">
                  <h3 style="margin: 0;     font-size: 16px;">Discount coupon({{ orderitems.order.coupon_code }})</h3>
                </td>
                <td colspan="3"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0 text-align: right;border-top: 1px solid #000;padding:0">
                  <h3 style="padding:0;margin: 0; 
    font-size: 19px;"> -INR
                    <!-- {{parseFloat((parseInt(order.total_price) + parseInt(order.shipping_charge)) - parseInt(order.coupon_amount)).toFixed(2)}} -->
                    {{ parseFloat(orderitems.order.coupon_amount).toFixed(2) }}
                  </h3>
                </td>
              </tr>
              <tr v-if="orderitems.order.shipping !== 0">
                <td colspan="7"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0;margin:0">
                  <h3 style="margin: 0;     font-size: 16px;">Shipping</h3>
                </td>
                <td colspan="3"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0 text-align: right;border-top: 1px solid #000;padding:0">
                  <h3 style="padding:0;margin: 0; 
    font-size: 19px;"> INR
                    <!-- {{parseFloat((parseInt(order.total_price) + parseInt(order.shipping_charge)) - parseInt(order.coupon_amount)).toFixed(2)}} -->
                    {{ parseFloat(orderitems.order.shipping).toFixed(2) }}
                  </h3>
                </td>
              </tr>
              <tr>
                <td colspan="7"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0;margin:0">
                  <h3 style="margin: 0;     font-size: 16px;">Grand Total</h3>
                </td>
                <td colspan="3"
                  style="text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;padding:0 text-align: right;border-top: 1px solid #000;padding:0">
                  <h3 style="padding:0;margin: 0; 
    font-size: 19px;"> INR
                    <!-- {{parseFloat((parseInt(order.total_price) + parseInt(order.shipping_charge)) - parseInt(order.coupon_amount)).toFixed(2)}} -->
                    {{ parseFloat(orderitems.order.grand_total).toFixed(2) }}
                  </h3>
                </td>
              </tr>

              <tr>
                <td colspan="6">Kabir Collection</td>
                <td colspan="2" style="text-align: center"></td>
              </tr>
              <tr>
                <!-- <td colspan="6">
                  <img src="/images/logo.png" alt="app-logo" style="width: 40px" />
                </td> -->
              </tr>
              <tr>
                <td colspan="6"></td>
                <td colspan="2" style="text-align: center">
                  <i> Authorized Signatory </i>
                </td>
              </tr>
              <tr>
                <td colspan="8" style="border-bottom: 1px dashed #777">
                  CIN N0: "" | Contact Kabir Collection +91-9971010391, 9871371375 ||
                  Contact Kabir Collection
                  <p>
                    *Keep this invoice and manufacturer box for warranty
                    purposes
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="centered">Thanks for your purchase!</p>
          
        </div>
      </div>
    </div>
    <button style="margin-left: 47%; background-color: greenyellow; border-radius: 5px; padding: 10px; font-size: 20px;" @click="printContent">Print Page</button>
  </div>
</template>

<script>


import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  data() {
    return {
      orderitems: "",

    };
  },
  methods: {
    printContent() {
          const printableContent = document.getElementById('printable-content')
          const printWindow = window.open('', '', 'height=1000,width=1000')
          printWindow.document.write(printableContent.innerHTML)
          printWindow.print()
        }

    //   async getAddr(userInfoid) {
    //     await axios
    //       .get("/api/user-orders/" + userInfoid, {})
    //       .then((res) => {
    //         this.orders = res.data.data;
    //        // console.log(this.orders);
    //       })
    //       .catch((err) => {
    //         console.log(err.response);
    //       });
    //   },
    // },
    // async created() {
    //   var token = sessionStorage.getItem("token");
    //   await axios
    //     .get("/api/getDatauser", {
    //       headers: { Authorization: "Bearer " + token },
    //     })
    //     .then((res) => {
    //       this.user_info = res.data;
    //       //console.log(this.user_info);

    //       this.getAddr(this.user_info.id);
    //     })
    //     .catch((err) => {
    //       this.$router.push("/login");
    //     console.log(err.response);
    //     });
  },
  mounted() {
    //  console.log(this.$route.query.oredr_id);
    //   this.orderID = this.$route.params.oredr_id;
    this.orderID = this.$route.query.oredr_id;
    // console.log(this.orderID);

    axios.get("/api/orders-items/" + this.orderID, {})
      .then((res) => {
        console.log(res);
        this.orderitems = res.data.data;
      })


  },

};
</script>

<!-- <script>
    new Vue({
      el: '#app',
      methods: {
        printContent() {
          const printableContent = document.getElementById('printable-content')
          const printWindow = window.open('', '', 'height=1000,width=1000')
          printWindow.document.write(printableContent.innerHTML)
          printWindow.print()
        }
      }
    });
</script> -->
