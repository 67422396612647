<template>
  <div>
    <header>
      <div class="top-bar">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6 col-xl-6">
              <div class="offer-text">
                <span>Shipping Free on Orders above 2999/-</span>
              </div>
            </div>
            <div class="col-lg-4 col-xl-6">
              <div class="account-login" v-if="user_info">
                <b-dropdown id="dropdown-1" text="My Account" class="m-md-2">
                  <b-dropdown-item v-if="user_info.email">Hello,
                    {{ user_info.email.split("@")[0] }} </b-dropdown-item>
                  <b-dropdown-item>
                    <router-link to="/Dashboard">Dashboard</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link to="/Dashboard/profile">Profile</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link to="/Dashboard/Orderdetails">My Orders</router-link></b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="logout()"> Logout</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="account-login" v-else>
                <router-link to="/login"><i class="pe7-icon pe-7s-user"></i> login</router-link>
                <!-- <router-link to="/registration">Register</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-mid ">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-3">
              <div class="logo-section">
                <router-link to="/">
                  <img src="/images/logo.png" alt="Kabir-Collection" />
                </router-link>
              </div>
            </div>
            <div class="col-md-6 big-navi">
              <nav class="navbar  navbar-expand-lg" role="navigation">
                <div class="collapse navbar-collapse">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/AboutUs"> About </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="/Contactus"> Contact </a>
                    </li>
                    <!-- <li class="nav-item">
                      <a class="nav-link" href="/"> Sale </a>
                    </li> -->



                    <!-- Level 1 -->

                    <!-- {{categories}} -->


                    <li class="dropdown nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link" data-toggle="dropdown">Products<b
                          class="caret"></b></a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">


                        <div class="row" >
                          <div class="col-md-2" v-for="category in categories" :key="category.id">
                            <span class="nav-hd">{{ category.title }}</span>
                            <ul class="nav flex-column" v-if="category.subcategory.length">
                              <div v-for="subCat in category.subcategory" :key="subCat.id">
                              <li v-if="subCat.subchild.length == 0">
                                <!-- <span class="mdi mdi-chevron-right"></span> -->
                                <a href="javascript:void(0)" @click="
                              goProductSubcat(category.slug, subCat.slug)
                              ">{{ subCat.title }}</a>
                              </li>
                              </div>
                             
                            </ul>
                          </div>
                        </div>
                     </div>
                    </li>


<!--------------------------------------------NAVBAR MENU-------------------------------------------------------------------------------- -->



                    <!-- <li class="dropdown nav-item" v-for="category in categories" :key="category.id">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link" @click="goProductCat(category.slug)"
                        data-toggle="dropdown">{{ category.title }} <b class="caret"></b></a>
                      <ul class="dropdown-menu multi-level" v-if="category.subcategory.length"> -->


                        
                        <!-- <li  v-for="subCat in category.subcategory"
                            :key="subCat.id">

                              <a href="#"  
                             @click="
                                goProductSubcat(category.slug, subCat.slug)
                              ">{{ subCat.title }}</a></li>
                              -->

                        <!-- <div v-for="subCat in category.subcategory" :key="subCat.id">
                          <li v-if="subCat.subchild.length == 0">
                            <a href="javascript:void(0)" @click="
                              goProductSubcat(category.slug, subCat.slug)
                              ">{{ subCat.title }}</a>
                          </li> -->



                          <!-- <li class="dropdown-submenu" v-if="subCat.subchild.length !== 0">
                            <a href="#" class="dropdown-toggle" @click="
                              goProductSubcat(category.slug, subCat.slug)
                              ">{{ subCat.title }}</a> -->



                            <!-- <ul class="dropdown-menu">
                              <li v-for="subsubCat in subCat.subchild" :key="subsubCat.id">
                                <a href="javascript:void(0)" @click="
                                  goProductSubcatt(
                                    category.slug,
                                    subCat.slug,
                                    subsubCat.slug
                                  )
                                  ">{{ subsubCat.title }}</a>
                              </li>
                            </ul> -->
                          <!-- </li>
                        </div>
                      </ul>
                    </li> -->

                    <!--------------------------------------------NAVBAR MENU-------------------------------------------------------------------------------- -->
                  </ul>
                </div>
                <!-- /.navbar-collapse -->

                <!-- /.container -->
              </nav>
            </div>

            <!-- <div class="col-md-4 big-navi">
              <nav class="navbar navbar-expand-lg">
                <div class="collapse navbar-collapse" id="navbarmain">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link" href="/">Home</a>
                    </li>

                    <li
                      class="nav-item dropdown"
                      v-for="category in categories"
                      :key="category.id"
                    >
                      <a
                        class="nav-link dropdown-toggle"
                        href="javascript:void(0)"
                        @click="goProductCat(category.slug)"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ category.title }}
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <div class="row" v-if="category.subcategory.length">
                          <div
                            class="col-md-2"
                            v-for="subCat in category.subcategory"
                            :key="subCat.id"
                          >
                            <a
                              href="javascript:void(0)"
                              class="nav-hd"
                              @click="
                                goProductSubcat(category.slug, subCat.slug)
                              "
                              >{{ subCat.title }}</a
                            >
                            <ul
                              class="nav flex-column"
                              v-if="subCat.subchild.length"
                            >
                              <li
                                v-for="subsubCat in subCat.subchild"
                                :key="subsubCat.id"
                              >
                                <a
                                  href="javascript:void(0)"
                                  @click="
                                    goProductSubcatt(
                                      category.slug,
                                      subCat.slug,
                                      subsubCat.slug
                                    )
                                  "
                                  >{{ subsubCat.title }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div> -->
            <div class="col-lg-3">
              <div class="header-option">
                <ul>
                  <li class="cart">
                    <a href="javascript:void(0)" title="cart" data-bs-toggle="modal" data-bs-target="#cart-mpdel"><i
                        class="nasa-icon cart-icon icon-nasa-cart-3"></i>

                      <!-- {{$store.state.getitems}} -->
                      <span class="count" v-if="$store.state.getitems.length > 0">{{ $store.state.getitems.length
                      }}</span>
                      <span class="count" v-else>{{
                        $store.state.cart.length
                      }}</span>
                    </a>
                  </li>

                  <li class="wish" title="cart">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#wish-mpdel">
                      <i class="nasa-icon wishlist-icon icon-nasa-like"></i>

                      <span class="count">{{
                        $store.state.getwishlist.length
                      }}</span>
                    </a>
                  </li>

                  <li class="search" title="search">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#searchModal">
                      <i class="nasa-icon nasa-search icon-nasa-search"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="mob-head">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-4">
            <div class="head-icons-mob">
              <button type="button" class="pe-7s-menu" data-bs-toggle="modal" data-bs-target="#model-left"></button>

              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#searchModal">
                <i class="nasa-icon nasa-search icon-nasa-search"></i>
              </a>
            </div>
          </div>
          <div class="col-4">
            <div class="head-mob-logo">
              <a href="javascript:void(0)">
                <router-link to="/">
                  <img src="/images/logo.png" alt="Kabir-Collection" />
                </router-link>
              </a>
            </div>
          </div>
          <div class="col-4">
            <div class="head-icons-mob justify-content-end">
              <div class="dropdown" v-if="user_info.email">
                <a href="javascript:void(0)" title="cart" class="" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="nasa-icon pe7-icon pe-7s-user"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><router-link to="/Dashboard">Dashboard</router-link></li>
                  <li><router-link to="/Orderdetails">My Orders</router-link></li>
                </ul>
              </div>
              <div class="dropdown" v-else>
                <a href="/login">
                  <i class="nasa-icon pe7-icon pe-7s-user"></i>
                </a>
              </div>
              <a href="#" title="cart" data-bs-toggle="modal" data-bs-target="#cart-mpdel">
                <i class="nasa-icon cart-icon icon-nasa-cart-3"></i>
                <!-- <span class="cart">{{ $store.state.getitems.length }}</span> -->
                <span class="cart" v-if="$store.state.getitems.length > 0">{{
                  $store.state.getitems.length
                }}</span>
                <span class="cart" v-else>{{ $store.state.cart.length }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mob-navigation">
      <div class="modal left fade" id="model-left" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        v-if="isShow">
        <div class="modal-dialog">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            <div class="modal-body">
              <!-- <carousel :items="1" :nav="false" :dots="false">
                <div class="item animate__fadeIn">
                  <img src="/images/main-slider/gi-sld.jpg" alt="" />

                </div>

                <div class="item animate__fadeIn">

                  <img src="/images/main-slider/gi-sld.jpg" alt="" />

                 
                </div>
              </carousel> -->

              <section>
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item" v-for="category in categories" :key="category.id">

                    <h2 class="accordion-header" :id="'flush-headingOne' + category.id">
                      <a href="javascript:void(0)" @click="goProductCat(category.slug)">{{ category.title }}</a>
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#flush-collapseOne' + category.id" aria-expanded="false"
                        aria-controls="flush-collapseOne"></button>
                    </h2>

                    <div :id="'flush-collapseOne' + category.id" class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-item" v-for="subCat in category.subcategory" :key="subCat.id">
                        <h2 class="accordion-header" :id="'flush-headingsub' + subCat.id">
                          <a href="javascript:void(0)" @click="goProductSubcat(category.slug, subCat.slug)">{{
                            subCat.title }}</a>
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            :data-bs-target="'#flush-headingsub' + subCat.id" aria-expanded="false"
                            aria-controls="flush-collapsesub" v-if="subCat.subchild.length !== 0"></button>
                        </h2>
                        <div :id="'flush-headingsub' + subCat.id" class="accordion-collapse collapse"
                          aria-labelledby="flush-headingsub" data-bs-parent="#accordionFlushExamplesub"
                          v-for="subsubCat in subCat.subchild" :key="subsubCat.id">
                          <h2>
                            <a href="javascript:void(0)"
                              @click="goProductSubcatt(category.slug, subCat.slug, subsubCat.slug)">{{ subsubCat.title
                              }}</a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Accordion Item #2
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> class. This is the second
                        item's accordion body. Let's imagine this being filled
                        with some actual content.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        Accordion Item #3
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the
                        <code>.accordion-flush</code> class. This is the third
                        item's accordion body. Nothing more exciting happening
                        here in terms of content, but just filling up the space
                        to make it look, at least at first glance, a bit more
                        representative of how this would look in a real-world
                        application.
                      </div>
                    </div>
                  </div> -->
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0)">Contact Us</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">FAQ's</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)
                    ">Legal</a>
                  </li>
                </ul>
              </section>

              <div class="footer">
                <div class="logo">
                  <img src="/images/logo.png" alt="Kabir-Collection" />
                </div>
                <p>Kabir-Collection</p>
                <p>Plot No. 22, UG Floor, Assotech Business Cresterra, Sec-135, Noida - 201304, India</p>
                <div class="button-act" v-if="user_info">
                  <a href="javascript:void(0)" @click="logout()">Sign Out</a>

                </div>
                <div class="button-act" v-else>
                  <a href="/login">Login</a>
                  <a href="/registration">Sign up</a>
                </div>
              </div>

              <!-- <ul id="accordionFlushExample">
                <li id="flush-headingOne">
                  <ul>
                    <li
                      :id="'flush-headingOne2' + category.id"
                      v-for="category in categories"
                      :key="category.id"
                    >
                      <a
                        href="javascript:void(0)"
                        class="accordion-plus collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#flush-collapseOne2' + category.id"
                        aria-expanded="false"
                        :aria-controls="'flush-collapseOne2' + category.id"
                      >{{ category.title }}</a
                      >
                         
                      <div
                        :id="'flush-collapseOne2' + category.id"
                        class="accordion-collapse collapse"
                        :aria-labelledby="'flush-headingOne2' + category.id"
                        :data-bs-parent="
                          '#accordionFlushExample2' + category.id
                        "
                      >
                        <div
                          class="accordion-body"
                          v-if="category.subcategory.length"
                        >
                        
                          <ul>
                            <li
                              v-for="subCat in category.subcategory"
                              :key="subCat.id"
                              id="closeModal"
                            >
                           
                              <a
                                href="javascript:void(0)"
                                class="accordion-plus collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="
                                  '#flush-collapseOne2' + subCat.id
                                "
                                aria-expanded="false"
                                :aria-controls="
                                  'flush-collapseOne2' + subCat.id
                                "  v-if="subCat.subchild.length !== 0"
                                >{{ subCat.title }}</a>

                                <a
                                href="javascript:void(0)" v-if="subCat.subchild.length == 0"
                                >{{ subCat.title }}</a>

                              <div
                                :id="'flush-collapseOne2' + subCat.id"
                                class="accordion-collapse collapse"
                                :aria-labelledby="
                                  'flush-headingOne2' + subCat.id
                                "
                                :data-bs-parent="
                                  '#accordionFlushExample2' + subCat.id
                                "
                              >
                                <div
                                  class="accordion-body"
                                  v-if="subCat.subchild.length !== 0"
                                >
                                  <ul>
                                    <li
                                      v-for="subsubCat in subCat.subchild"
                                      :key="subsubCat.id"
                                      id="closeModal"
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        @click="
                                          goProductSubt(
                                            category.slug,
                                            subCat.slug,
                                            subsubCat.slug
                                          )
                                        "
                                        >{{ subsubCat.title }}</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body"></div>
                  </div>
                </li>
              </ul>  -->

              <!-- <div class="dropdown">
    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Tutorials
    <span class="caret"></span></button>
    <ul class="dropdown-menu">
      <li><a tabindex="-1" href="#">HTML</a></li>
      <li><a tabindex="-1" href="#">CSS</a></li>
      <li class="dropdown-submenu">
        <a class="test" tabindex="-1" href="#">New dropdown <span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
          <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
          <li class="dropdown-submenu">
            <a class="test" href="#">Another dropdown <span class="caret"></span></a>
            <ul class="dropdown-menu">
              <li><a href="#">3rd level dropdown</a></li>
              <li><a href="#">3rd level dropdown</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div> -->
              <!-- <ul id="accordionFlushExample">
             
                <li id="flush-headingOne">
                  <a
                    href="#"
                    class="accordion-plus collapsed show"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    SHOP BY CATEGORY
                  </a>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ul>
                        <li
                          :id="'flush-headingOne2' + category.id"
                          v-for="category in categories"
                          :key="category.id"
                        >
                          <a
                            href="javascript:void(0)"
                            class="accordion-plus collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="
                              '#flush-collapseOne2' + category.id
                            "
                            aria-expanded="false"
                            :aria-controls="'flush-collapseOne2' + category.id"
                          >
                            {{ category.title }}</a
                          >
                          <div
                            :id="'flush-collapseOne2' + category.id"
                            class="accordion-collapse collapse"
                            :aria-labelledby="'flush-headingOne2' + category.id"
                            :data-bs-parent="
                              '#accordionFlushExample2' + category.id
                            "
                          >
                            <div
                              class="accordion-body"
                              v-if="category.subcategory.length"
                            >
                              <ul>
                                <li
                                  v-for="subCat in category.subcategory"
                                  :key="subCat.id"
                                  id="closeModal"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    @click="
                                      goProductSubt(category.slug, subCat.slug)
                                    "
                                    >{{ subCat.title }}</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade searchbar" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close nasa-sidebar-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="bar-cover">
            <input v-on:keyup.enter="onEnter" v-on:keyup="inputChanged" v-on:keyup.down="onArrow" v-on:keyup.up="onArrow"
              type="text" class="form-control" v-model="searchQuery" id="atcelement" placeholder="i am shoping for..." />
            <button @click="searchproduct(searchQuery)">
              <i class="nasa-icon nasa-search icon-nasa-search"></i>
            </button>
          </div>
          <div class="container">
            <div class="row mt-2">
              <div class="bar-list">
                <ul>
                  <li v-for="(product, index) in srhproductsss" :key="index" v-on:show="isOpen"><a
                      href="javascript:void(0)" @click="productdetail(product.slug)" class="autocomplete-results"
                      :class="{ 'is-active': index === arrowCounter }">{{ product.name }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.17/vue.js"></script>
<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    components: { carousel },
  props: ["categories", "srhproducts"],
  data() {
    return {
      isShow: true,
      isLogin: false,
      user_info: "",
      searchQuery: null,
      arrowCounter: -1,
      isOpen: false,
      srhproductsss:[],
      
    };
  },

  computed: {


    // searchproducts() {
    //   // console.log('erst'+ JSON.stringify(this.srhproductsss));
    //   return this.srhproductsss.filter((product) => {
    //     if (JSON.stringify(this.searchQuery).length > 5) {
    //       return product.name.toLowerCase().indexOf(this.searchQuery) != -1;
    //     }

    //     // return (
    //     //   product.name
    //     //     ?.toLowerCase()
    //     //     .indexOf(this.searchQuery.toLowerCase()) != -1
    //     // );
    //   });
    // },


  },
  created() {

     

    window.scrollTo(0, 0);
    if (localStorage.getItem("token") != "") {
      this.isLogin = true;
      var token = localStorage.getItem("token");
      axios
        .get("/api/auth/user", {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          this.user_info = res.data;
      
          this.getAddr(this.user_info.id);
         if(this.user_info){
           if(this.$route.path == '/login') {
           this.$router.push("/Dashboard");
          }
          else if(this.$route.path == '/Otp') {
          this.$router.push("/Dashboard");
          }

         }
          
          // console.log(this.user_info);
        })
        .catch((err) => {
          //  this.$router.push("/login");
          console.log(err.response);
        });
    }
  

    
    

   
  },
  methods: {
    productdetail(prodName) {
      this.$router.push("/Productdetail/" + prodName);
      window.location.reload();
    },

    searchproduct(string) {
      var searchdd=string.replace("%20", "");
      this.$router.push("/Search/" + searchdd);
      location.reload();
      },
  setResult(name) {
      this.searchQuery = name
      console.log('this.searchQuery',this.searchQuery);
    },

    onArrow(event) {
      console.log(event);

      if (this.srhproductsss.length > 0) {

        this.arrowCounter =event.code == "ArrowDown" ? ++this.arrowCounter : --this.arrowCounter;

        if (this.arrowCounter >= this.srhproductsss.length)
          this.arrowCounter = (this.arrowCounter) % this.srhproductsss.length;
          else if (this.arrowCounter < 0)
          this.arrowCounter = this.srhproductsss.length + this.arrowCounter;
          // console.log(this.arrowCounter);
          this.setResult(this.srhproductsss[this.arrowCounter].name);
          
          }
      },

  inputChanged(event) {

    if (event.code == "ArrowUp" || event.code == "ArrowDown")
        return;
        
        this.srhproductsss = [];
        if (event.code == "Enter")
        return;
        var filtered = this.srhproducts.filter((searchproduct) => {
        return searchproduct.name.toLowerCase().match(this.searchQuery.toLowerCase())
        });
        
        this.isOpen = true
        this.srhproductsss.push(...filtered)
      },


    onEnter: function() {
      var searchdd=this.searchQuery.replace("%20", "");
//  var searchdd=this.searchQuery.replace(/ /g, "-");
//        key=searchdd.replace(" ","_");
//console.log('searchdd',searchdd);
      this.$router.push("/Search/" + searchdd);
      location.reload();
      },
      getAddr(user_id) {
      this.$store.commit("getusercartitem", user_id);
      this.$store.commit("getuserwislist", user_id);
      this.$store.commit("savecoupondata", JSON.parse(localStorage.getItem("coupon")));
    },
    logout() {
      localStorage.setItem("token", "");
      this.$store.commit("getusercartitem", 0);
      this.$store.commit("getuserwislist", 0);
      this.$router.push("/");
      location.reload();
      //history.go(-1);
      //this.$router.push("/login");
      },

    goProductSubt(catName, subName) {
      $("body").removeClass("modal-open");
      $(".modal-backdrop").hide();
      $("body").css({ overflow: "", "padding-right": "" });
      this.isShow = false;
      this.$router.push("/Productlist/" + catName + "/" + subName);
    },
    goProductCat(catName) {
      this.$router.push("/Productlist/" + catName);
      location.reload();

    },
    goProductSubcat(catName, subName) {
      this.$router.push("/Productlist/" + catName + "/" + subName);
      location.reload();
    },
    goProductSubcatt(catName, subName, subsubName) {
      this.$router.push(
        "/Productlist/" + catName + "/" + subName + "/" + subsubName
      );
      location.reload();

    },
  },
};
$(window).scroll(function(){
  if ($(window).scrollTop() >= 300) {
        $('.header-mid').addClass('fixed-web-header');
        $('.header-mid').addClass('visible-title');
    }
    else {
        $('.header-mid').removeClass('fixed-web-header');
        $('.header-mid').removeClass('visible-title');
    }
});
</script>

<style>
/* adds some margin below the link sets  */
.big-navi {}

.big-navi .navbar {
  position: inherit;
}

.big-navi .navbar .navbar-nav .nav-item {
  padding: 0px 0px;
}

.big-navi .navbar .navbar-nav .nav-item .nav-link {
  color: #555555;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 14px 11px;
  position: relative;
}

.big-navi .navbar .navbar-nav .nav-item .nav-link::before {
  transition: all 350ms ease;
  content: "";
  width: 0;
  content: "";
  border-bottom: #000 2px solid;
  position: absolute;
  bottom: 0;
  left: 0;
}

.big-navi .navbar .navbar-nav .nav-item:hover .nav-link::before {
  transition: all 350ms ease;
  width: 100%;
}


.big-navi .navbar .dropdown-menu div[class*="col"] {
  margin-bottom: 1rem;
}

.big-navi .navbar .dropdown-menu {
  border: none;
  background-color: #ffffff !important;

}

.big-navi .navbar .dropdown-menu .nav-hd {
  color: #ffcc01;
  margin: 10px 0px;
  display: block;

}

.big-navi .navbar .dropdown-menu ul {}

.big-navi .navbar .dropdown-menu ul li {}

.big-navi .navbar .dropdown-menu ul li a {

  display: block;
  color: #000;
  font-size: 14px;
  padding: 3px 0px;
}

/* breakpoint and up - mega dropdown styles */
@media screen and (min-width: 992px) {

  /* remove the padding from the navbar so the dropdown hover state is not broken */
  .big-navi .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
  .big-navi .navbar .nav-item {
    padding: .5rem .5rem;
    margin: 0 .25rem;
  }

  /* makes the dropdown full width  */
  .big-navi .navbar .dropdown {
    position: static;
  }

  .big-navi .navbar .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
    /*  height of nav-item  */
    top: 120px;

    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;

  }




  /* shows the dropdown menu on hover */
  .big-navi .navbar .dropdown:hover .dropdown-menu,
  .big-navi .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
  }

  .big-navi .navbar .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, .15);
    background-color: #fff;
    margin: 0 auto;
    width: 85%;
    padding: 10px 18px;
  }

}
</style>

<!-- <style>
.big-navi {}

.big-navi .navbar-collapse {}

.big-navi .navbar {
  position: inherit;
}

.big-navi .navbar-nav {}

.big-navi .navbar .navbar-nav .nav-item {
  padding: 0px 0px;
}

.big-navi .navbar-nav .nav-item .nav-link {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  padding: 14px 11px;
  position: relative;
  letter-spacing: 1.5px;
}

.big-navi .navbar-nav .nav-item .nav-link::before {
  transition: all 350ms ease;
  content: "";
  width: 0;
  content: "";
  border-bottom: #000 2px solid;
  position: absolute;
  bottom: 0;
  left: 0;
}

.big-navi .navbar-nav .nav-item:hover .nav-link::before {
  transition: all 350ms ease;
  width: 100%;
}

.big-navi .navbar-nav .nav-item ul {
  border: none;
  background-color: #ffffff !important;
  width: 240px;
  padding: 20px 0px 20px 30px;
}

.big-navi .navbar-nav .nav-item ul li {}

.big-navi .navbar-nav .nav-item ul li a {
  color: #333333;
  display: block;
  transition: all 350ms ease;
  padding: 5px 0px;
  line-height: 25px;
  font-size: 14px;
}

.big-navi .navbar-nav .nav-item ul li a:hover {
  color: #fe4d01;
  padding-left: 3px;
}

.big-navi .dropdown-menu div[class*="col"] {
  margin-bottom: 1rem;
}

.big-navi .dropdown-menu {
  border: none;
  background-color: #ffffff !important;
}

.big-navi .dropdown-menu .nav-hd {
  color: #fe4d01;
  margin: 10px 0px;
  display: block;
}

.big-navi .dropdown-menu ul {}

.big-navi .dropdown-menu ul li {}

.big-navi .dropdown-menu ul li a {
  display: block;
  color: #000;
  font-size: 14px;
  padding: 3px 0px;
}

.big-navi .dropdown-submenu {
  position: relative;
}

.big-navi .dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.big-navi .dropdown:hover>.dropdown-menu {
  display: block;
}

.big-navi .dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.big-navi .dropdown-submenu>a:after {
  float: right;
  font-family: "FontAwesome";
  content: "\f105";
  opacity: 0.3;
  margin-right: 20px;
  border: none;
}

.big-navi .dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  /* height: 120px; */
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  background: red;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4AAE9B;
  color: white;
}

.is-active {
  background-color: #dedede;
}
</style> -->
