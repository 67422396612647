<template>
  <div class="Payusuccess">
    <Payusuccessmony />
  </div>
</template>
<script>
import Payusuccessmony from "../components/Dashboad/Payusuccessmony.vue";
export default {
  name: "Payusuccess",
  components: {
    Payusuccessmony,
  },
};
</script>