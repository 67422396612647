<template>
    <div>
        <div class="cart-section">
            <div class="container">
                <div class="cart-section-in">
                    <div class="row" id="checout-model" v-if="getitems.length > 0">

                        <div class="col-lg-8">
                            <div class="list-section">
                                <div class="available-offer">
                                    <h3>
                                        <i class="mdi mdi-brightness-percent"></i> Available Offers
                                    </h3>
                                    <p>
                                        15% Instant Discount on slice super card on a min spend of Rs
                                        2000. <router-link to="/Termandcondition">
                                            TCA
                                        </router-link>
                                    </p>
                                </div>
                                <div class="action-field">
                                    <div class="all-checked">
                                        <label class="common-check" v-if="this.$store.state.test == getitems.length">
                                            <input type="checkbox" data-class="first" @click="selectAllfalse()"
                                                :checked="this.$store.state.test == getitems.length ? 'true' : ''" />
                                            <span class="checkmark black"></span>
                                        </label>
                                        <label class="common-check minus-check"
                                            v-if="getitems.length > this.$store.state.test && this.$store.state.test !== 0">
                                            <input data-class="second" type="checkbox"
                                                :checked="getitems.length > this.$store.state.test ? 'true' : ''"
                                                @click="selectAllfalse()" />
                                            <span class="checkmark black"></span>
                                        </label>
                                        <label class="common-check" v-if="this.$store.state.test == '0'">

                                            <input data-class="third" type="checkbox" @click="selectAlltrue()" />
                                            <span class="checkmark black"></span>
                                        </label>

                                        <span class="checked-count">
                                            {{ this.$store.state.test }}/{{ getitems.length }}
                                            itemsSelected
                                        </span>
                                    </div>

                                    <div class="right-action">
                                        <a href="javascript:void(0)" @click="selectedProductemp()">Remove</a>
                                        <a href="javascript:void(0)" @click="selectedPromovwish()">Move to Wishlist</a>
                                    </div>
                                </div>
                                <div class="list-product">
                                    <ul>
                                        <li v-for="(obj, key) in getitems" :key="key">
                                            <!-- {{obj}}  -->

                                            <!-- {{obj.product_size}}  -->

                                            <div class="dlt-pro" @click="getitedeletFrocar(obj)">
                                                <i class="mdi mdi-close"></i>
                                            </div>
                                            <div class="img-bl">
                                                <label class="common-check">
                                                    <input type="checkbox" :checked="obj.status == 1 ? 'true' : ''"
                                                        @click="selectstatussin(obj)" />
                                                    <span class="checkmark black"> </span></label>
                                                <img :src="$baseURL +
                                                    '/public/images/product/original/' +
                                                    obj.image[0].product_image
                                                    " alt="" />
                                            </div>
                                            <div class="info-bl">
                                                <h4>Lushlly</h4>

                                                <h3>
                                                    <a href="javascript:void(0)" @click="productdetail(obj.product.slug)">{{
                                                        obj.product.name }}</a>
                                                </h3>

                                                <span class="size-qty" v-b-modal.size-pop @click="selectedSizepop(obj)">
                                                    Size: {{ obj.size_name }}
                                                    <i class="mdi mdi-menu-down"></i>
                                                </span>

                                                <span class="size-qty" v-b-modal.quantity-pop @click="addQuantitypop(obj)">
                                                    Qty: {{ obj.qty }} <i class="mdi mdi-menu-down"></i>
                                                </span>
                                                
                                                <!-- {{ obj.product_sizes[this.obj.size_id].offer_price }} -->
                                                <!-- {{ obj.product_sizes[obj.id].offer_price }} -->
                                                <div class="price">
                                                    <span class="real">
                                                        <i class="mdi mdi-currency-inr"></i>{{
                                                            obj.qty *( obj.offer_price.offer_price + parseInt(obj.variablevalue !=null ?obj.variablevalue :0)) }}
                                                    </span>
                                                    <span class="cut">
                                                        <i class="mdi mdi-currency-inr"></i>{{
                                                            obj.qty * obj.offer_price.actual_price }}
                                                    </span>
                                                    <span class="percent">{{
                                                        parseFloat(
                                                            ((obj.offer_price.actual_price -
                                                                obj.offer_price.offer_price) *
                                                                100) /
                                                            obj.offer_price.actual_price
                                                        ).toFixed()
                                                    }}%</span>
                                                </div>
                                                <!-- <div class="price">
                                                <span class="real" id="real">

                                                </span>
                                                <span class="cut" id="cut">

                                                </span>
                                                <span class="percent">{{
                                                      parseFloat(
                                                        ((obj.product.actual_price -
                                                            obj.product.offer_price) *
                                                            100) /
                                                            obj.product.actual_price
                                                        ).toFixed()
                                                    }}%</span>
                                            </div> -->
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <b-modal id="size-pop" title="Select Size" v-if="showModal">
                                        <div class="size-container" v-if="this.selected_size">
                                            <div v-for="(obj, key) in this.product_sizes.product_sizes" :key="key">
                                                <!-- {{ product_sizes.product_sizes}} -->
                                                <a href="javascript:void(0)" :class="[
                                                    obj.size_id == selected_size ? 'selected' : 'false',
                                                ]" @click="selectedSize(obj.size_id, obj.actual_price, obj.offer_price)">
                                                    <span>{{ obj.size_name }}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="submit-st">
                                            <button @click="addToCartlogupdatesize()">DONE</button>
                                        </div>
                                    </b-modal>

                                    <b-modal id="quantity-pop" title="Select Quantity" v-if="showModal">
                                        <div class="size-container">
                                            <div class="size-quantity" v-for="index in stock" :key="index">
                                                <a href="javascript:void(0)" :class="qtyative == index ? 'selected' : ''"
                                                    @click="addQuantity(index)" v-if="index <= 10">{{ index }}</a>
                                            </div>

                                        </div>
                                        <div class="submit-st">
                                            <button @click="addToCartlogupdate()">DONE</button>
                                        </div>
                                    </b-modal>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="cart-detail">
                                <div class="apply-coupon">
                                    <h3>Coupons</h3>
                                    <div class="coupon-details">
                                        <span class="coupon-name">
                                            <i class="mdi mdi-tag"></i> Apply Coupon</span>
                                        <button class="apply-button" @click="Couponmodel()">
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                <div class="cart-price-details">
                                    {{ index }}
                                    <h4>Price Details ({{ this.$store.state.test }} item)</h4>

                                    <ul>
                                        <li>
                                            <span class="info-name">Price</span>
                                            <span class="info-charge">₹{{ this.$store.state.price +parseInt(this.$store.state.variablevalue) }}
                                                <span></span>
                                            </span>
                                        </li>
                                        <!-- {{ this.$store.state.price }} -->
                                        <!-- <li v-if="this.$store.state.variablevalue !==null">
                                            <span class="info-name">Variable Price</span>
                                            <span class="info-charge">₹{{ this.$store.state.variablevalue }}
                                                <span></span>
                                            </span>
                                        </li>
                                        <li v-else>
                                            
                                        </li> -->
                                        <li>
                                            <span class="info-name">Discount coupon</span>
                                            <span class="info-charge"
                                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">-₹{{
                                                    this.$store.state.coupon.couponprice }}
                                                <span></span>
                                            </span>
                                            <span class="info-charge" v-else>₹0
                                                <span></span>
                                            </span>
                                        </li>
                                        <li>
                                            <span class="info-name">Tax</span>
                                            <span class="info-charge">₹0
                                                <span></span>
                                            </span>
                                        </li>
                                        <li class="top-shop">
                                            <span class="info-name">Subtotal</span>
                                            <!-- <span class="info-charge">₹ {{ getitemssubtotalp }}</span>  -->
                                            <span class="info-charge"
                                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">₹{{
                                                    parseInt(this.$store.state.total - this.$store.state.coupon.couponprice) }}</span>
                                            <span class="info-charge" v-else>₹{{ this.$store.state.total }}</span>
                                        </li>
                                        <li>
                                            <span class="info-name">Shipping</span>
                                            <span class="info-charge">₹{{ this.$store.state.delivery }}
                                                <span></span>
                                            </span>
                                        </li>
                                        <!-- <li class="ship-add">
                                        <span>Shipping to <strong>Delhi</strong> </span>
                                        <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            Change Delivery
                                        </a>
                                        <div class="collapse" id="collapseExample">
                                            <div class="form-group">
                                                <input type="text" class="form-control" />
                                                <button>Check</button>
                                            </div>
                                        </div>
                                    </li> -->

                                        <li class="total">

                                            <span class="total-span">Total</span>
                                            <span class="total-price"
                                                v-if="this.$store.state.coupon !== null && this.$store.state.total > this.$store.state.coupon.min_value">₹{{
                                                    parseInt(this.$store.state.total - this.$store.state.coupon.couponprice) }}</span>
                                            <span class="total-price" v-else>₹ {{ this.$store.state.total }}</span>
                                        </li>
                                        <li class="check-btn">
                                            <button :disabled="checkVal" @click="proceedtoCheckout()">
                                                Proceed to checkout
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="modal cart-size-pop fade" id="couponpop" tabindex="-1" role="dialog"
                            aria-labelledby="couponpopTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                            <i class="mdi mdi-close"></i>
                                        </button>
                                        <h3>Apply Coupon</h3>

                                        <div class="coupon-show">
                                            <div class="coupon-inp">
                                                <input class="form-control" type="text" name="" id=""
                                                    placeholder="Enter Coupon COde" />
                                                <button class="check-bt">Check</button>
                                            </div>
                                            <ul>
                                                <li v-for="(obj, index) in this.couponsdata" :key="index">
                                                    <div class="form-check">
                                                        <input type="hidden" :class="'coupon_value' + obj.id"
                                                            name="coupon_value" :value="obj.coupon_value" />
                                                        <input type="hidden" :class="'coupon_type' + obj.id"
                                                            name="coupon_type" :value="obj.coupon_type" />
                                                        <input type="hidden" :class="'min_value' + obj.id" name="min_value"
                                                            :value="obj.min_value" />
                                                        <input class="form-check-input" type="radio" name="coupon_id"
                                                            :value="obj.id" :id="'flexRadioDefault1' + index">
                                                        <label class="common-check" :for="'flexRadioDefault1' + index">
                                                            <span class="coup-code">{{ obj.code }}</span>
                                                            <p>
                                                                {{ obj.save_uptoset }}
                                                                <br />
                                                                {{ obj.min_valueset }} , {{ obj.expire_onset }}

                                                            </p>
                                                        </label>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="submit-st">
                                            <button @click="applycoupon()">DONE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="getitems.length == '0'">
                        <div class="cart-empty">
                            <img :src="$homeBaseURL + '/images/empty-cart.png'" />
                        </div>
                    </div>
                    <!-- end login cart -->
                </div>
            </div>
        </div>
    </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    data() {
        return {

            shippingCharge: 0,
            user_info: "",
            addtocartdata: "",
            showModal: false,
            qtyative: "",
            qtyproduct: "",
            selected_size: "",
            product_sizes: "",
            stock: "",
            couponsdata: "",
            // selected: [],
            // cart_item_Ids: [],
        };
    },
methods: {
applycoupon(){
  var coupon_id = document.querySelector(".form-check-input:checked").value;
  var coupon_value = document.querySelector('.coupon_value'+coupon_id).value;
  var coupon_type = document.querySelector('.coupon_type'+coupon_id).value;
  var min_value = document.querySelector('.min_value'+coupon_id).value;

  if(coupon_type === 'p'){
    let savecoupon = Math.floor(this.$store.state.total*coupon_value*0.01);

    const savedata = {
        couponprice:savecoupon,
        coupon_id:coupon_id,
        min_value:min_value
    }
    this.$store.commit("savecoupondata", savedata);
     $('#couponpop').modal('hide')
    
  } else if(coupon_type === 'f') {
        let savecoupon = coupon_value;
        const savedata = {
        couponprice:savecoupon,
        coupon_id:coupon_id,
        min_value:min_value
    }
    this.$store.commit("savecoupondata", savedata);
     $('#couponpop').modal('hide')
  }
      //  this.$store.commit("getusercartitem", this.product_sizes.user_id);
            
        },
        Couponmodel() {
            //  $('#couponpop').modal('show')
            axios.get("/api/get-coupons/" + this.user_info.id, {})
                .then((res) => {
                    this.couponsdata = res.data.data;
                    $('#couponpop').modal('show')
                })
                .catch((err) => {
                    console.log(err.response);
                });

        },
        selectedProductemp() {
            this.$fire({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                // type: "error",
                showCancelButton: true,
                confirmButtonColor: '#fe4d01',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, Keep it!',

                // timer: 5000,
            }).then((r) => {
                if (r.value) {
                    axios.get("/api/cart-empty/" + this.user_info.id, {}).then((res) => {
                            console.log(res);
                            this.$store.commit("getusercartitem", this.user_info.id);
                            //this.$store.commit("deleteFromcart", index);
                            this.$fire('Deleted!', 'Your Todo has been deleted', 'success')
                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                } else {
                    this.$fire('Canceled', 'Your Todo is still in place', 'warning')
                }

            });

        },
        selectedPromovwish() {
            this.$fire({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                // type: "error",
                showCancelButton: true,
                confirmButtonColor: '#fe4d01',
                confirmButtonText: 'Yes, !',
                cancelButtonText: 'No, Keep it!',

                // timer: 5000,
            }).then((r) => {
                if (r.value) {
                    const data_user_id = {
                        user_id: this.user_info.id,
                    }

                    axios.post("/api/add-to-wish-list-selected", data_user_id).then((res) => {
                            console.log(res);
                            this.$store.commit("getusercartitem", this.user_info.id);
                            this.$store.commit("getuserwislist", this.user_info.id);
                            //this.$store.commit("deleteFromcart", index);
                            this.$fire('Moved!', 'Product Removed Successfully', 'success')
                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                } else {
                    this.$fire('Canceled', 'Your Todo is still in place', 'warning')
                }

            });
        },
        selectAlltrue() {
            this.$store.state.getitems.forEach((doc) => {
                const addtocartdata = {
                    user_id: doc.user_id,
                    product_id: doc.product_id,
                    size_id: doc.size_id,
                    qty: doc.qty,
                    status: "1",
                };
                console.log(addtocartdata);
                axios
                    .post("/api/update-cart", addtocartdata)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("getusercartitem", doc.user_id);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            });
        },
        selectAllfalse() {
            this.$store.state.getitems.forEach((doc) => {
                const addtocartdata = {
                    user_id: doc.user_id,
                    product_id: doc.product_id,
                    size_id: doc.size_id,
                    qty: doc.qty,
                    status: "0",
                };
                console.log(addtocartdata);
                axios
                    .post("/api/update-cart", addtocartdata)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("getusercartitem", doc.user_id);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            });
        },
        selectstatussin(dataobj) {
            if (dataobj.status == 1) {
                const addtocartdata = {
                    user_id: dataobj.user_id,
                    product_id: dataobj.product_id,
                    size_id: dataobj.size_id,
                    qty: dataobj.qty,
                    status: "0",
                };
                console.log(addtocartdata);
                axios
                    .post("/api/update-cart", addtocartdata)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("getusercartitem", dataobj.user_id);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            } else {
                const addtocartdata = {
                    user_id: dataobj.user_id,
                    product_id: dataobj.product_id,
                    size_id: dataobj.size_id,
                    qty: dataobj.qty,
                    status: "1",
                };
                console.log(addtocartdata);
                axios
                    .post("/api/update-cart", addtocartdata)
                    .then((res) => {
                        console.log(res);
                        this.$store.commit("getusercartitem", dataobj.user_id);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            }
        },
        addQuantitypop(qtyproduct) {
            // console.log('stock',qtyproduct.product_sizes);
            qtyproduct.product_sizes.forEach((doc) => {
                if (doc.size_id == qtyproduct.size_id) {
                    // console.log('stock32532',doc.size_id === qtyproduct.size_id);
                    this.stock = doc.stock;
                    //console.log(doc.stock);
                }

            });

            //  if (qtyproduct.product_sizes.find(d => d.size_id === qtyproduct.size_id)){
            //        console.log(qtyproduct.product_sizes);
            //  }

            // console.log(qtyproduct.size_id);
            // console.log(qtyproduct.size_name);

            this.qtyative = qtyproduct.qty;
            this.qtyproduct = qtyproduct;
            this.showModal = true;
            //console.log("proceedToPay invailed user");
        },
        addQuantity(e) {
            this.qtyative = e;
        },
        selectedSizepop(data) {
            this.selected_size = data.size_id;
            this.product_sizes = data;
            this.showModal = true;
            console.log("proceedToPay invailed user");
        },
        selectedSize(e,actual,offer) {
            this.selected_size = e;
    //         document.getElementById("real").innerHTML = offer;
    //   document.getElementById("cut").innerHTML = actual;
        },
        productdetail(prodName) {
            this.$router.push("/Productdetail/" + prodName);
            window.location.reload();
        },
        // removeFromCart(item) {
        //   this.$store.commit("removeFromCart", item);
        //   this.flash("Product Quantity removed", "error", {
        //     timeout: 3000,
        //     beforeDestroy() {},
        //   });
        // },
        // addToCart(product) {
        //   console.log(this.products);
        //   this.$store.commit("addToCart", product);
        //   this.flash("Product Quantity Added", "success", {
        //     timeout: 3000,
        //     beforeDestroy() {
        //       //alert('oh no, not again!');
        //     },
        //   });
        // },
        deleteFromcart(index) {
            this.$store.commit("deleteFromcart", index);
        },
        getitedeletFrocar(product) {
            this.$fire({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                // type: "error",
                showCancelButton: true,
                confirmButtonColor: '#fe4d01',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, Keep it!',

                // timer: 5000,
            }).then((r) => {
                if (r.value) {
                    axios.get("/api/cart-item-delete/" + product.user_id + "/" + product.id, {}).then((res) => {
                            console.log(res);
                            this.$store.commit("getusercartitem", product.user_id);
                            //this.$store.commit("deleteFromcart", index);
                            this.$fire('Deleted!', 'Your Todo has been deleted', 'success')
                        })
                        .catch((err) => {
                            console.log(err.response);
                        });

                } else {
                    this.$fire('Canceled', 'Your Todo is still in place', 'Warning')
                }
            });

        },

        addToCartlogupdate() {
            this.showModal = false;
            const addtocartdata = {
                user_id: this.qtyproduct.user_id,
                product_id: this.qtyproduct.product_id,
                size_id: this.qtyproduct.size_id,
                qty: this.qtyative,
                status: this.qtyproduct.status,
            };
            console.log(addtocartdata);
            axios
                .post("/api/update-cart", addtocartdata)
                .then((res) => {
                    console.log(res);
                    this.$store.commit("getusercartitem", this.qtyproduct.user_id);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        addToCartlogupdatesize() {
            this.showModal = false;
            const addtocartdata = {
                user_id: this.product_sizes.user_id,
                product_id: this.product_sizes.product_id,
                size_id: this.product_sizes.size_id,
                new_size_id: this.selected_size,
                qty: this.product_sizes.qty,
                status: this.product_sizes.status,
            };
            console.log(addtocartdata);
            axios
                .post("/api/update-cart", addtocartdata)
                .then((res) => {
                    console.log(res);
                    this.$store.commit("getusercartitem", this.product_sizes.user_id);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        proceedtoCheckout() {
            if (this.user_info == null) {
                this.$router.push("/login");
            } else {
                this.$router.push("/Checkout");
            }
        },

        // getcouponscode(id) {
        //      axios.get("/api/get-coupons/" + id, {})
        //         .then((res) => {
        //             this.couponsdata = res.data.data;
        //              })
        //         .catch((err) => {
        //             console.log(err.response);
        //         });
        // }
    },
    created() {
        var token = localStorage.getItem("token");
    //    console.log(token);
        axios
            .get("/api/auth/user", {
                headers: {
                    Authorization: "Bearer " + token
                },
            })
            .then((res) => {
                this.user_info = res.data;
                 
                // this.getcouponscode(this.user_info.id);
                
                if (this.user_info == '') {
                    this.$router.push("/login");
                }
                
            })
            .catch((err) => {
                console.log(err.response);
                this.$router.push("/login");
            });

        // console.log('testre'+this.user_info);
        //  if (this.user_info == '') {
        //   this.$router.push("/login");
        // }

           
        // console.log('this.user_info', this.user_info);


        // if(this.user_info!== ''){
        //     console.log('hi');


        // }
        
        },

    computed: {
        getitems() {
            return this.$store.state.getitems;
        },
        checkVal: function () {
            return this.$store.state.test > 0 ? false : true;
        }
    },
};

</script>

<style>
#quantity-pop {}

#quantity-pop .modal-dialog .close {
    position: absolute;
    right: 14px;
    border: none;
    background: no-repeat;
    top: 0;
    font-size: 26px;
}

#quantity-pop .modal-dialog .modal-body {
    padding: 0;
}

#quantity-pop .modal-dialog {
    align-items: center;
    display: flex;
    height: 100vh;
}

#quantity-pop .modal-dialog h3 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    padding: 18px 18px;
}

#quantity-pop .modal-dialog .size-container {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 18px;
}

#quantity-pop .modal-dialog .size-container a {
    width: 40px;
    height: 40px;
    border: 1px solid #535766;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 12px;
    margin-top: 4px;
    color: #000;
}

#quantity-pop .modal-dialog .size-container a.selected {
    border: #ffc107 1px solid;
    color: #ffc107;
}

#quantity-pop .modal-dialog .size-container a.not {}

#quantity-pop .modal-dialog .submit-st {
    text-align: center;
    padding: 18px 18px;
}

#quantity-pop .modal-dialog .submit-st button {
    border: none;
    width: 100%;
    padding: 20px 0px;
    background-color: #ffc107;
    display: inline-block;
    line-height: 0;
}

#quantity-pop .modal-dialog .submit-st button:hover {
    opacity: 0.8;
}

#quantity-pop .modal-dialog .modal-footer {
    display: none;
}

#quantity-pop .modal-dialog .modal-header {
    border: none;
    padding: 13px 20px;
}

/* quantity */

/* size-pop */

#size-pop {}

#size-pop .modal-dialog .close {
    position: absolute;
    right: 9px;
    border: none;
    background: no-repeat;
    top: 0;
    font-size: 26px;
}

#size-pop .modal-dialog .modal-body {
    padding: 0;
}

#size-pop .modal-dialog {
    align-items: center;
    display: flex;
    height: 100%;
}

#size-pop .modal-dialog h3 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    padding: 18px 18px;
}

#size-pop .modal-dialog .size-container {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 18px;
}

#size-pop .modal-dialog .size-container a {
    width: auto;
    height: 40px;
    min-width: 40px;
    border: 1px solid #535766;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;

    margin-right: 12px;
    color: #000;
}

#size-pop .modal-dialog .size-container a.selected {
    border: #ffc107 1px solid;
    color: #ffc107;
}

#size-pop .modal-dialog .size-container a.not {}

#size-pop .modal-dialog .submit-st {
    text-align: center;
    padding: 18px 18px;
}

#size-pop .modal-dialog .submit-st button {
    border: none;
    width: 100%;
    padding: 20px 0px;
    background-color: #ffc107;
    display: inline-block;
    line-height: 0;
}

#size-pop .modal-dialog .submit-st button:hover {
    opacity: 0.8;
}

#size-pop .modal-dialog .modal-footer {
    display: none;
}

#size-pop .modal-dialog .modal-header {
    border: none;
    padding: 13px 20px;
}

.cart-size-pop {}

.cart-size-pop .modal-dialog .close {
    position: absolute;
    right: 0;
    border: none;
    background: no-repeat;
    top: 0;
    font-size: 26px;
}

.cart-size-pop .modal-dialog .modal-body {
    padding: 0;
}

.cart-size-pop .modal-dialog {}

.cart-size-pop .modal-dialog h3 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    padding: 18px 18px;
}

.cart-size-pop .modal-dialog .size-container {
    display: flex;
    flex-wrap: wrap;
    padding: 18px 18px;
}

.cart-size-pop .modal-dialog .size-container a {
    width: 40px;
    height: 40px;
    border: 1px solid #535766;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;

    margin-right: 12px;
    color: #000;
}

.cart-size-pop .modal-dialog .size-container a.selected {
    border: #ffc107 1px solid;
    color: #ffc107;
}

.cart-size-pop .modal-dialog .size-container a.not {}

.cart-size-pop .modal-dialog .submit-st {
    text-align: center;
    padding: 18px 18px;
}

.cart-size-pop .modal-dialog .submit-st button {
    border: none;
    width: 100%;
    padding: 20px 0px;
    background-color: #ffc107;
    display: inline-block;
    line-height: 0;
}

.cart-size-pop .modal-dialog .submit-st button:hover {
    opacity: 0.8;
}

.cart-size-pop .modal-dialog .coupon-show {
    overflow: auto;
    height: 300px;
    background-color: #f5f5f5;
}

.cart-size-pop .modal-dialog .coupon-show .coupon-inp {
    padding: 14px 18px;
    position: relative;
    background-color: #fff;
    margin-bottom: 16px;
}

.cart-size-pop .modal-dialog .coupon-show .coupon-inp .form-control {
    font-size: 14px;
    height: 45px;
}

.cart-size-pop .modal-dialog .coupon-show .coupon-inp .check-bt {
    position: absolute;
    right: 25px;
    top: 23px;
    border: none;
    background: no-repeat;
    color: #ffc107;
}

.cart-size-pop .modal-dialog .coupon-show ul {
    padding: 0;
}

.cart-size-pop .modal-dialog .coupon-show ul li {
    list-style: none;
    background-color: #fff;
    padding: 18px 18px;
    margin-bottom: 10px;
}

.cart-size-pop .modal-dialog .coupon-show ul li .common-check {
    margin: 0;
    display: block;
}

.cart-size-pop .modal-dialog .coupon-show ul li .common-check .checkmark {
    top: 6px;
    border-radius: 0;
}

.cart-size-pop .modal-dialog .coupon-show ul li .common-check .coup-code {
    display: inline-block;
    color: #000;
    border: #000000 1px dashed;
    padding: 6px 16px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    border-radius: 4px;
}

.cart-size-pop .modal-dialog .coupon-show ul li .common-check input:checked~.coup-code {
    color: #ffc107;
    border: #ffc107 1px dashed;
}

.cart-size-pop .modal-dialog .coupon-show ul li p {
    margin: 0;
    margin-top: 14px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
}</style>
