<template>
  <div>
    <section class="dash-front">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9" v-if="orderitems">
            <div class="row justify-content-between">
              <div class="col-md-4">
                <div class="profile-info">
                  <!-- {{ orderitems.order}} -->

                  <!-- <div class="pro-img">
                    <img src="images/user.jpg" alt="" />
                  </div> -->
                  <div class="pro-info">
                    <h3>{{ user_info.name }}</h3>
                    <h4>+91{{ user_info.phone }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="logout text-end">
                  <a href="javascript:void(0)" @click="logout()"
                    >Back<i class="mdi mdi-arrow-u-left-top"></i>
                  </a>
                </div>
              </div>
            </div>
            <hr />

            <div class="dash-edit-section">
              <h2>Orders Product</h2>
              <hr />
              <div class="order-show">
                <ul>
                  <!-- {{orderitems.order_item_list }} -->
                  <li
                    v-for="(items, index) in orderitems.order_item_list"
                    :key="index"
                  >
                    <!-- {{items.shipingststu.object}} -->
                    <div class="row">
                      <div class="col-md-2 col-lg-2 col-3">
                        <div class="order-img">
                          <img
                            :src="
                              $baseURL +
                              '/public/images/product/original/' +
                              items.product_image
                            "
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-md-10 col-9">
                        <div class="order-dlt">
                          <h3>
                            <a href="#">{{ items.product_name }}</a>
                          </h3>
                          <span class="other-dtl">
                            <strong>Price: </strong>
                            <i class="mdi mdi-currency-inr"></i
                            >{{ items.offer_price }}</span
                          >
                          <span
                            class="other-dtl text-primary"
                            v-if="items.order_item_status == 0"
                          >
                            <strong>Order Status:</strong>
                            Process
                          </span>
                          <span
                            class="other-dtl text-secondary"
                            v-if="items.order_item_status == 1"
                          >
                            <strong>Order Status:</strong>
                            Dispatched
                          </span>

                          <span
                            class="other-dtl"
                            v-if="items.order_item_status == 1"
                          >
                            <strong> track number: </strong>
                            <a href="https://ecomexpress.in" target="blank">
                              {{ items.awb_number }}</a
                            >
                          </span>

                          <span
                            class="other-dtl text-warning"
                            v-if="items.order_item_status == 2"
                          >
                            <strong>Order Status:</strong>
                            Refund
                          </span>
                          <span
                            class="other-dtl text-info"
                            v-if="items.order_item_status == 3"
                            ><strong>Order Status:</strong>
                            Exchange
                          </span>
                          <span
                            class="other-dtl text-danger"
                            v-if="items.order_item_status == 6"
                          >
                            <strong>Order Status:</strong>
                            Cancel
                          </span>
                          <span
                            class="other-dtl text-success"
                            v-if="items.order_item_status == 7"
                          >
                            <strong>Order Status:</strong>
                            Delivered
                          </span>
                          <span class="other-dtl"
                            ><strong>Size Name:</strong>
                            {{ items.size_name }}</span
                          >
                          <span class="other-dtl"
                            ><strong>Quantity :</strong>
                            {{ items.quantity }}</span
                          >

                          <div class="other-action">
                            <a
                              :href="'#cancel' + items.id"
                              class="cancel"
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="cancel"
                              v-if="items.order_item_status !== 7"
                              >Cancel</a
                            >
                            <a href="#" v-if="items.order_item_status == 7"
                              >Review</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="cancel-section collapse"
                      :id="'cancel' + items.id"
                    >
                      <hr />
                      <div class="form-group">
                        <label for="" class="d-block"
                          >Select cancel resion</label
                        >
                        <select
                          class="form-control"
                          aria-label="Default select example"
                          :id="'selectresion' + items.id"
                        >
                          <option selected>Open this select menu</option>
                          <option value="Bad quality products feedback">
                            Bad quality products feedback
                          </option>
                          <option
                            value="Bad customer care service or representative"
                          >
                            Bad customer care service or representative
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <button v-on:click="cancel_request(items.id)">
                        cancel Request
                      </button>
                    </div>
                  
              

                    <article class="card shipping-step" v-if="items.shipingststu.object!==undefined">
                      <header class="card-header">My Orders / Tracking</header>
                      <div class="card-body">
                        <h6>
                          <div class="col">
                            <strong>Tracking Number</strong>
                            {{ items.shipingststu.object.field[0] }}
                          </div>
                        </h6>

                        <article class="card">
                          <div class="card-body row">
                            <div class="col">
                              <strong>Delivery Date:</strong> <br />{{
                                items.shipingststu.object.field[21]
                              }}
                            </div>
                            <div class="col">
                              <strong>Order No</strong> <br />{{
                                items.shipingststu.object.field[1]
                              }}
                            </div>
                            <div class="col">
                              <strong>Status:</strong> <br />{{
                                items.shipingststu.object.field[11]
                              }}
                            </div>
                          </div>
                        </article>

                        <div class="track">
                          <div
                            :class="'step '+Soft_data_uploaded_status">
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>
                            <div v-if="Soft_data_uploaded.length > 0">
                              <h3>Order Confirmed</h3>
                              <p>
                                <span class="text">{{
                                  Soft_data_uploaded[0].field[6]
                                }}</span>
                                <span class="text"
                                  >Date :{{
                                    Soft_data_uploaded[0].field[0]
                                  }}</span
                                >
                              </p>
                            </div>
                          </div>
                          <div :class="'step '+order_shipped_status" >
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>

                            <div v-if="order_shipped.length > 0">
                              <h3>Pickup Completed</h3>
                              <p>
                                <span class="text">{{
                                  order_shipped[0].field[6]
                                }}</span>
                                <span class="text"
                                  >Date :{{ order_shipped[0].field[0] }}</span
                                >
                              </p>
                            </div>
                          </div>

                          <div
                            :class="'step '+in_transit_status"
                           
                          >
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>

                            <div  v-if="in_transit[in_transit.length - 1]">
                              <h3>In-Transit</h3>
                              <p>
                                <span class="text">{{
                                  in_transit[in_transit.length - 1].field[6]
                                }}</span>

                                <span class="text"
                                  >Date :{{ in_transit[in_transit.length - 1].field[0] }}</span
                                >
                              </p>
                            </div>
                          </div>

                          <div
                            :class="'step '+scan_at_dc_status">
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>

                            <div v-if="scan_at_dc[scan_at_dc.length - 1]">  
                              <h3>Shipment at Delivery Centre</h3>
                              <p>
                                <span class="text">{{
                                  scan_at_dc[scan_at_dc.length - 1].field[6]
                                }}</span>
                                <span class="text"
                                  >Date :{{
                                    scan_at_dc[scan_at_dc.length - 1].field[0]
                                  }}</span
                                >
                              </p>
                            </div>
                          </div>

                          <div
                            :class="'step '+shipment_out_for_delivery_status">
                          
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>
                            <div  v-if="shipment_out_for_delivery.length > 0">
                            <h3>Shipment out for delivery</h3>
                              <p>
                                <span class="text">{{
                                  shipment_out_for_delivery[0].field[6]
                                }}</span>
                                <span class="text"
                                  >Date :{{
                                    shipment_out_for_delivery[0].field[0]
                                  }}</span
                                >
                              </p>
                              </div>
                          </div>

                          <div :class="'step '+shipment_delivered_status" >
                            <span class="icon">
                              <i class="fa fa-check"></i
                            ></span>
                            <div v-if="shipment_delivered.length>0">
                              <h3>Shipment delivered</h3>
                               <p>
                                <span class="text">{{
                                  shipment_delivered[0].field[6]
                                }}</span>
                                <span class="text"
                                  >Date :{{
                                    shipment_delivered[0].field[0]
                                  }}</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article> 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  data() {
    return {
      orderitems: "",
      user_info: "",
      in_transit: [],
      scan_at_dc: [],
      Soft_data_uploaded: [],
      Soft_data_uploaded_status:"pending",
      shipment_out_for_delivery_status:"pending",
      shipment_delivered_status:"pending",
      order_shipped_status:"pending",
      scan_at_dc_status:"pending",
      in_transit_status:"pending",
      order_shipped: [],
      shipment_out_for_delivery: [],
      shipment_delivered: [],
      
    };
  },
  methods: {
    logout() {
      history.go(-1);
      // sessionStorage.setItem("token", "");
    },
    cancel_request(itemsid) {
      var reason = document.querySelector("#selectresion" + itemsid).value;
      const order_cancel = {
        reason: reason,
        item_id: itemsid,
      };
      // console.log(order_cancel);

      axios
        .post("/api/order-cancel", order_cancel)
        .then((res) => {
          if (res.data.status == "200") {
            this.$fire({
              title: res.data.message,
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
            this.$router.push("/Dashboard/Orderdetails");
          }
          if (res.data.status == "201") {
            this.$fire({
              title: res.data.message,
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    this.orderID = this.$route.params.oredr_id;
    if (!this.orderID) {
      history.go(-1);
    } else {
      axios
        .get("/api/orders-items/" + this.orderID, {})
        .then((res) => {
          this.orderitems = res.data.data;
       

          this.orderitems.order_item_list.forEach((items) => {
  //  console.log('items.shipingststu.object',items.shipingststu.object);
            items.shipingststu.object.field[
              items.shipingststu.object.field.length - 1
            ].object.forEach((item) => {
              if (item.field[3] == "001") {
                this.Soft_data_uploaded.push(item);
                this.Soft_data_uploaded_status='active';
              }

              if (item.field[3] == "006") {
                this.shipment_out_for_delivery.push(item);
                this.shipment_out_for_delivery_status='active';
              }

              if (item.field[3] == "999") {
                this.shipment_delivered.push(item);
                 this.shipment_delivered_status='active';
              }

              if (item.field[3] == "0011") {
                this.order_shipped.push(item);
                this.order_shipped_status='active';
                }
              if (item.field[3] == "005") {
                this.scan_at_dc.push(item);
                 this.scan_at_dc_status='active';
              }

              if (item.field[3] == "003") {
                this.in_transit.push(item);
                this.in_transit_status='active';
              }
            });

            //this.srhproducts.push(doc);
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  },
  async created() {
    var token = localStorage.getItem("token");
    await axios
      .get("/api/auth/user", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.user_info = res.data;
      })
      .catch((err) => {
        this.$router.push("/login");
        console.log(err.response);
      });
  },
};
</script>
<style>
.shipping-step {
}
.shipping-step .card-header {
}
.shipping-step .card-header .card {
}
.shipping-step .card-header .card .card-body {
  font-size: 13px;
}

.track {
  /* position: relative; */
  /* background-color: #ddd; */
  /* height: 7px; */
  display: -ms-flexbox;
  margin-bottom: 60px;
  margin-top: 50px;
  margin-left: 110px;
}
.track .text {
  display: block;
  margin-top: 7px;
}
.track .icon {
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 33px;
  position: relative;
  border-radius: 100%;
  background: #ddd;
  position: absolute;
  left: -20px;
  top: 0;
  margin: auto 0;
  bottom: 0;
  text-align: center;
  font-size: 12px;
}
.track .step {
  margin-top: -18px;
  /* text-align: center; */
  position: relative;
  height: 131px;
  border-left: #efefef 5px solid;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 53%;
  padding-left: 57px;
}

.track .step.active {
  border-left: #efefef 5px solid;
}
.track .step h3 {
  width: 100%;
  font-size: 15px;
  margin: 0;
  font-weight: 700;
  margin-bottom: 9px;
}
.track .step p {
  font-size: 12px;
  margin: 0;
}
.track .step p span {
  margin: 0;
  font-size: 13px;
}

.track .step.active .icon {
  background: #ee5435;
  color: #fff;
}
.track .step.active:before {
  background: #ff5722;
}
.track .step.active .text {
  font-weight: 400;
  color: #000;
}
</style>
