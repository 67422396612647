<template>
  <div class="thank">
      <Thankyoupage />
   
  </div>
</template>

<script>
 
 
import Thankyoupage from "../components/Thankyoupage.vue";
 
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  name: "Home",
  components: {
    Thankyoupage,
    },
   data() {
    return {
      
    };
  },
//    created() {
//     axios
//       .get("/api/homecategory", {})
//       .then((res) => {
//         this.categories = res.data.data;
//       })
//       .catch((err) => {
//         console.log(err.response);
//       });
//   },
};
</script>