<template>
  <div class="container-fluid">
    <div
      class="row thanks-order-cover align-items-center justify-content-center"
    >
      <div class="col-md-5">
        <div class="thanks-order">
          <h3>Thank you {{ orders.first_name }}</h3>

          <img src="images/tick.gif" alt="" />
          <p>we recieved your order and your order id is:#123{{ orders.id }}</p>

          <a href="javascript:void(0)" @click="Orderdetails()">Order details</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: this.$route.params.order_data,
    };
  },
  methods: {
    Orderdetails() {
      this.$router.push("/Dashboard/Orderdetails");
    },
  },
 created() {
    setTimeout(() => {
      this.$router.push("/Dashboard/Orderdetails");
    }, 3000)
 }

};
</script>

<style>
.thanks-order {
  text-align: center;
  background-color: #fdfdfd;
  border: #d0d0d0 1px solid;
  padding: 50px 0px;
}
.thanks-order h3 {
  font-size: 57px;
  font-weight: 700;
}
.thanks-order img {
  width: 20%;
}
.thanks-order-cover {
  height: 100vh;
}
.thanks-order-cover a {
  background-color: #ffcb00;
  color: #fff;
  padding: 10px 30px;
  margin-top: 8px;
  display: inline-block;
}
.thanks-order-cover a:hover {
  opacity: 0.8;
}
</style>