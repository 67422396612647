<template>
  <div class="myaccount-set">
    <div class="container">
      <form @submit.prevent="handleSubmitLogin">
      <div class="myaccount-set-in">
        <div class="row justify-content-center">
          <div class="col-lg-6 form-cover">
            <h3>Reset Password</h3>
            <div class="col-md-12">
                <div class="form-group">
                  <span class="ico mdi mdi-account-outline"></span>
                  <input
                    required
                    type="email"
                    class="form-control"
                    placeholder="Enter Email"
                    v-model="email"
                  />
                </div>
              </div>
            <div class="col-md-12">
              <div class="form-group">
                <span class="ico mdi mdi-key-variant"></span>
                <input
                    required
                  type="text"
                  class="form-control"
                  placeholder="Old Password"
                  v-model="oldpassword"
                  minlength="6"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <span class="ico mdi mdi-key-variant"></span>
                <input
                    required
                  type="text"
                  class="form-control"
                  placeholder="New Password"
                  v-model="newpassword"
                  minlength="6"
                />
              </div>
            </div>
          
            <div class="col-md-12">
              <div class="submit">
                <button type="submit">Reset</button>
              </div>

             
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {   
      email:"",   
      oldpassword: "",
      newpassword: "",
      message: "",
      error:"",
    };
  },
  methods: {
    async handleSubmitLogin() {
      
      if (!this.email) {
        this.flash("Please Enter valid email", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }
      if (!this.oldpassword) {
        this.flash("Please Enter Old Password", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }
      if (!this.newpassword) {
        this.flash("Please Enter New Password", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }
     

      const userData = {
        
        oldpassword: this.oldpassword,
        newpassword: this.newpassword,
      };

      
        await axios.post("/api/resetPassword", userData)
        .then((res) => {
           this.message = res.data.result.original.error;
          if (res.data.status == 1) {

            res.data.result.original.access_token

            sessionStorage.setItem("token",res.data.result.original.access_token);
    
            this.$router.push("/Dashboard");

          } else {
            this.$fire({
              title: "Bwitchedzone Login Failed !",
              text: this.message,
              type: "error",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>