<template>
  <div class="home">
    <loader v-if="isLoading" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <Header v-if="this.categories.length > 0" :categories="categories" :srhproducts="srhproducts"  />
    <Breadcrumbs />
    <Profilepage />
    <Footer v-if="this.categories.length > 0" :categories="categories"/>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Profilepage from "../components/Dashboad/Profilepage.vue";

import Footer from "../components/Footer.vue";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
export default {
  name: "Home",
  components: {
    Header,
    Breadcrumbs,
    Profilepage,

    Footer,
  },
   data() {
    return {
       isLoading:true,
      categories: "",
      srhproducts:[],
    };
  },
   created() {

    //   var token = sessionStorage.getItem("token");
    //  await axios
    //   .get("/api/getDatauser", {
    //     headers: { Authorization: "Bearer " + token },
    //   })
    //   .then((res) => {
    //     this.user_info = res.data;
    //     this.userlength=JSON.stringify(this.user_info).length;
    //     //console.log(JSON.stringify(this.user_info).length);
    //     })
    //   .catch((err) => {
    //     this.$router.push("/login");
    //     console.log(err.response);
    //   });

    axios
      .get("/api/homecategory", {})
      .then((res) => {
        this.categories = res.data.data;
      })
      .catch((err) => {
        console.log(err.response);
      });

       axios
      .get("/api/product-list", {})
      .then((res) => {
        this.searchproducts = res.data.data;
        this.searchproducts .forEach((doc) => {
        this.srhproducts.push(doc);
        this.isLoading = false;
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  },
};
</script>