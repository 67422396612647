<template>
  <div class="myaccount-set">
    <div class="container">
      <form @submit.prevent="handleSubmitLogin">
        <div class="myaccount-set-in">
          <div class="row justify-content-center">
            <div class="col-lg-6 form-cover">
              <h3>Login/Register</h3>
              <div class="col-md-12">
                <div class="form-group">
                  <span class="ico mdi mdi-account-outline"></span>
                  <!-- <input
                    required
                    type="text"
                    class="form-control"
                    placeholder="phone"
                    v-model="phone"
                    maxlength="10"
                    @keyup="onlyNumeric"
                  /> -->
                  <input
                    required
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    v-model="email"
                  />
                </div>
              </div>

            
              <div class="col-md-12">
                <div class="reminder justify-content-between">
                  <!-- <span class="frogot">
                    <router-link to="/Forgotpassword"
                      >Forgot password?</router-link
                    >
                  </span> -->
                  <!-- <span class="frogot">
                  <router-link to="/Resetpassword"
                    >Reset password?</router-link
                  >
                </span> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="submit">
                  <button type="submit" @click="loader()" :disabled="hidden">Sign in</button>
                </div>
<!-- 
                <span class="extra-action">
                  New User
                  <router-link to="/registration">Creat an account</router-link>
                </span> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div id="spinner" v-if="hidden"></div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      hidden: false,
      email: "",
      message: "",
      error: "",
     
    };
  },
  methods: {
    async handleSubmitLogin() {
      
      if (!this.email) {
        this.flash("Please Enter Your Email", "error", {
          timeout: 3000,
          beforeDestroy() {},
        });
        return false;
      }
      (this.hidden = true);
      const userData = {
        email: this.email,
        };
       
       
      await axios
      .post("/api/auth/get-otp", userData)
        .then((res) => {
          console.log('res,',res);
          sessionStorage.setItem("email", this.email);
          if (res.data.status == 1) {
             this.$router.push("/Otp");
             } else {
            this.$fire({
              title: "Kabir Collection email Invalid !",
              text: 'something went Wrong',
              type: "error",
              timer: 5000,
            }).then((r) => {
              
              console.log(r.value);
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    
    
//      onlyNumeric() {
//      this.phone = this.phone.replace(/[^0-9]/g, '');
// },
//     async handleSubmitLogin() {
//       if (!this.phone) {
//         this.flash("Please Enter Your Phone", "error", {
//           timeout: 3000,
//           beforeDestroy() {},
//         });
//         return false;
//       }
//       const userData = {
//         phone: this.phone,
//         };
       
//       await axios
//       .post("/api/auth/get-otp", userData)
//         .then((res) => {
//           sessionStorage.setItem("phone", this.phone);
//           if (res.data.status == 1) {
//              this.$router.push("/Otp");
//              } else {
//             this.$fire({
//               title: "Bwitchedzone phone Invalid !",
//               text: 'something went Wrong',
//               type: "error",
//               timer: 5000,
//             }).then((r) => {
//               console.log(r.value);
//             });
//           }
//         })
//         .catch((err) => {
//           console.log(err.response);
//         });
//     },
  //  loader(){

  //     (this.hidden = true);
  //   },
  },
};
</script>
<style>
#spinner:not([hidden]) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#spinner::after {
    content: "";
    width: 80px;
    height: 80px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #f25a41;
    border-radius: 100%;
    will-change: transform;
    animation: spin 1s infinite linear;
}
</style>